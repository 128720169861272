// React & Next
import { useMemo } from 'react';

// 3rd
import { Divider, Table, Tbody, Tr, Th, Td } from '@chakra-ui/react';

// App - Other
import { Text } from '@/components/atoms/typography';
import { Link } from '@/components/molecules/link';
import { Markdown } from '@/components/organisms/markdown';

type ConfluenceMarkdownFlavorProps = {
  children: string;
};

export const ConfluenceMarkdownFlavor = ({ children }: ConfluenceMarkdownFlavorProps) => {
  const processMarkdown = useMemo(() => {
    // This regex matches either `<code>` blocks or individual new lines outside those blocks.
    const regex = /(<code>[\s\S]*?<\/code>)|(\r\n|\r|\n)/g;

    return children.replace(regex, (match, codeBlock, newLine) => {
      if (codeBlock) {
        return `${codeBlock.replace('<code>', '```\n').replace('</code>', '\n```')}\n\n`;
      }

      if (newLine) {
        return `\r\r`;
      }

      return match; // Fallback, should not actually be needed.
    });
  }, [children]);

  return (
    <Markdown
      direction="column"
      alignSelf="stretch"
      gap="xxs"
      w="100%"
      sx={{
        '& .feedback-prefix': {
          display: 'flex',
          flexDirection: 'column',
        },

        '& .feedback-suffix': {
          display: 'flex',
          flexDirection: 'column',
          mt: '2xl',
        },
      }}
      overrides={{
        br: {
          component: () => <br />,
        },
        hr: {
          component: () => <Divider my="lg" />,
        },
        a: {
          component: ({ children, ...props }) => (
            <Text variant="detail">
              <Link {...props}>{children}</Link>
            </Text>
          ),
          props: {
            className: 'link',
            color: 'text.brand.secondary-high-tone',
          },
        },
        table: {
          component: Table,
          props: {
            sx: {
              mt: 'lg',
              w: '100%',
            },
          },
        },
        tbody: {
          component: Tbody,
        },
        th: {
          component: Th,
          props: {
            sx: {
              bg: '#f1f2f4',
              py: '6px',

              '&:first-of-type': {
                borderTopStartRadius: 'xs',
                borderBottomStartRadius: 'xs',
              },

              '&:last-of-type': {
                borderTopEndRadius: 'xs',
                borderBottomEndRadius: 'xs',
              },

              '& .strong': {
                whiteSpace: 'nowrap',
                textStyle: 'small',
                fontWeight: 600,
                color: 'text.primary',
              },
            },
          },
        },
        tr: {
          component: Tr,
        },
        td: {
          component: Td,
          props: {
            sx: {
              verticalAlign: 'top',
              textStyle: 'detail',
              color: 'text.high-tone',

              '&:has(.link)': {
                whiteSpace: 'nowrap',
              },

              '& .strong': {
                textStyle: 'detail-sb',
              },
            },
          },
        },
      }}
    >
      {processMarkdown}
    </Markdown>
  );
};
