// React & Next
import { createElement } from 'react';

// App - Types
import type { KnowledgeSource } from '@/types/integration/knowledge-source';
import type { IconProps } from '@/components/atoms/icon';

// App - Other
import {
  AzureDevOpsIcon,
  AzureDevOpsWikiIcon,
  AzureDevOpsWorkItemIcon,
  AzureDevOpsRepositoryIcon,
  ColorableConfluenceIcon,
  ColorableGoogleDriveIcon,
  ColorableJiraIcon,
  ConfluenceIcon,
  GithubIcon,
  GitlabIcon,
  GoogleDriveIcon,
  JiraIcon,
  LinearIcon,
  NotionIcon,
  OneDriveIcon,
  TrelloIcon,
  UnknownIcon,
} from '@/components/atoms/icon';
import { translateKnowledgeSource } from './knowledge-source';

type KnowledgeSourceIconProps = Partial<IconProps> & {
  source: KnowledgeSource;
  treatGoogleDriveAsOneDrive?: boolean;
};

export const KnowledgeSourceIcon = ({
  source,
  treatGoogleDriveAsOneDrive,
  ...iconProps
}: KnowledgeSourceIconProps) => {
  return createElement(translateKnowledgeSourceToIcon(source, treatGoogleDriveAsOneDrive), {
    'aria-label': translateKnowledgeSource(source, treatGoogleDriveAsOneDrive),
    ...iconProps,
  });
};

export const translateKnowledgeSourceToIcon = (
  source: KnowledgeSource,
  treatGoogleDriveAsOneDrive?: boolean
) => {
  if (!source) return UnknownIcon;

  if (source === 'confluence') return ConfluenceIcon;

  if (source === 'google_drive') {
    if (treatGoogleDriveAsOneDrive) {
      return OneDriveIcon;
    } else {
      return GoogleDriveIcon;
    }
  }

  if (source === 'jira') return JiraIcon;

  if (source === 'trello') return TrelloIcon;

  if (source === 'linear') return LinearIcon;

  if (source === 'github') return GithubIcon;

  if (source === 'azure_devops') return AzureDevOpsIcon;

  if (source === 'azure_devops_wiki_page') return AzureDevOpsWikiIcon;

  if (source === 'azure_devops_work_item') return AzureDevOpsWorkItemIcon;

  if (source === 'azure_devops_repository') return AzureDevOpsRepositoryIcon;

  if (source === 'one_drive') return OneDriveIcon;

  if (source === 'notion') return NotionIcon;

  if (source === 'gitlab') return GitlabIcon;

  return UnknownIcon;
};

export const translateKnowledgeSourceToColorableIcon = (source: KnowledgeSource) => {
  if (!source) return UnknownIcon;

  if (source === 'confluence') return ColorableConfluenceIcon;

  if (source === 'google_drive') return ColorableGoogleDriveIcon;

  if (source === 'jira') return ColorableJiraIcon;

  return UnknownIcon;
};
