// 3rd
import { delay, http } from 'msw';
import { DateTime } from 'luxon';

// App - Types
import type { CreateLinearIntegrationRequestPayloadDto } from '@/features/integrations';
import { ZodCreateLinearIntegrationRequestPayloadDto } from '@/features/integrations';

// App - Other
import { API_URL } from '@/config/constants';
import { uuid4 } from '@/utils/generators';
import { db } from '../../db';

export const insertLinearIntegrationHandler = http.post(
  `${API_URL}/Integration/InsertLinearIntegration`,
  async ({ request }) => {
    const payload = await request.json();
    const parsedPayload: CreateLinearIntegrationRequestPayloadDto =
      ZodCreateLinearIntegrationRequestPayloadDto.parse(payload);

    db.knowledgeSourceConfiguration.create({
      id: uuid4(),
      baseUrl: parsedPayload.userEmail,
      knowledgeSourceType: 'Linear',
      lastScraped: DateTime.now().toUTC().toString(),
      userEmail: parsedPayload.userEmail,
    });

    await delay(500);

    return new Response(null, {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
