// 3rd
import { delay, http } from 'msw';

// App - Types
import type { CollectionStatDto, GetCollectionsStatsResponseDto } from '@/features/collections';
import { ZodCollectionStatDto, ZodGetCollectionsStatsResponseDto } from '@/features/collections';

// App - Other
import { API_URL } from '@/config/constants';
import { randomInt } from '@/utils/generators';
import { db } from '../../db';

export const getListCollectionsStatsHandler = http.get(
  `${API_URL}/Collection/ListCollectionStats`,
  async () => {
    const collections = db.collection.getAll();
    const collectionsStats = collections.map((collection) => {
      const collectionsKItems = db.knowledgeItem.findMany({
        where: {
          collectionId: {
            equals: collection.collectionId,
          },
        },
      });
      const collectionKItemsPriorities = collectionsKItems.reduce(
        (priorities, kItem) => {
          if (!priorities[kItem.priority]) priorities[kItem.priority] = 0;

          priorities[kItem.priority] += 1;

          return priorities;
        },
        {} as Record<string, number>
      );
      const collectionKItemsTypesCount = collectionsKItems.reduce(
        (typesCount, kItem) => {
          if (!typesCount[kItem.documentType]) typesCount[kItem.documentType] = 0;

          typesCount[kItem.documentType] += 1;

          return typesCount;
        },
        {} as Record<string, number>
      );

      return {
        collectionId: collection.collectionId,
        collectionName: collection.collectionTitle,
        knowledgeSourceType: collection.knowledgeSourceType,
        documentPriorityToCount: collectionKItemsPriorities,
        documentTypeToCount: collectionKItemsTypesCount,
        prioritizedDocumentCount: collectionsKItems.length,
        totalDocumentCount: collectionsKItems.length + randomInt(1, 100),
      };
    });
    const parsedCollectionsStats: CollectionStatDto[] = collectionsStats.map((collectionStat) =>
      ZodCollectionStatDto.parse(collectionStat)
    );

    await delay(500);

    const res: GetCollectionsStatsResponseDto = { collectionsStats: parsedCollectionsStats };
    const parsedRes: GetCollectionsStatsResponseDto = ZodGetCollectionsStatsResponseDto.parse(res);

    return new Response(JSON.stringify(parsedRes), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
