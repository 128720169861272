// App - Types
import type { SecurityFrameworkSource } from '../source';

export const SecurityFrameworksSourcesDto = [
  'Aws',
  'Nist',
  'FedRamp',
  'Owasp',
  'Clover',
  'Cis',
  'Pci',
  'Custom',
  'Azure',
  'UNKNOWN',
] as const;

export type SecurityFrameworkSourceDto = (typeof SecurityFrameworksSourcesDto)[number];

export const castSecurityFrameworkSourceDtoToSecurityFrameworkSource = (
  dto: SecurityFrameworkSourceDto
): SecurityFrameworkSource => {
  if (dto === 'Azure') return 'azure';

  if (dto === 'FedRamp') return 'fed_ramp';

  if (dto === 'Aws') return 'aws';

  if (dto === 'Nist') return 'nist';

  if (dto === 'Owasp') return 'owasp';

  if (dto === 'Clover') return 'clover';

  if (dto === 'Cis') return 'cis';

  if (dto === 'Pci') return 'pci';

  if (dto === 'Custom') return 'custom';

  return 'UNKNOWN';
};

export const castSecurityFrameworkSourceToSecurityFrameworkSourceDto = (
  source: SecurityFrameworkSource
): SecurityFrameworkSourceDto => {
  if (source === 'azure') return 'Azure';

  if (source === 'fed_ramp') return 'FedRamp';

  if (source === 'aws') return 'Aws';

  if (source === 'nist') return 'Nist';

  if (source === 'owasp') return 'Owasp';

  if (source === 'clover') return 'Clover';

  if (source === 'cis') return 'Cis';

  if (source === 'pci') return 'Pci';

  if (source === 'custom') return 'Custom';

  return 'UNKNOWN';
};
