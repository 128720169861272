// App - Types
import type { KnowledgeSource } from '../integration/knowledge-source';

export const Types = [
  'azure_devops_wiki_page',
  'azure_devops_work_item',
  'azure_devops_repository',
  'confluence_page',
  'confluence_attachment',
  'google_drive',
  'jira_issue',
  'jira_attachment',
  'linear_issue',
  'linear_attachment',
  'trello_card',
  'github_issue',
  'github_pull_request',
  'UNKNOWN',
] as const;

export type Type = (typeof Types)[number];

export const castTypeToKnowledgeSource = (type: Type): KnowledgeSource => {
  if (type === 'azure_devops_wiki_page') return 'azure_devops_wiki_page';

  if (type === 'azure_devops_work_item') return 'azure_devops_work_item';

  if (type === 'azure_devops_repository') return 'azure_devops_repository';

  if (type === 'confluence_page' || type === 'confluence_attachment') return 'confluence';

  if (type === 'google_drive') return 'google_drive';

  if (type === 'jira_issue' || type === 'jira_attachment') return 'jira';

  if (type === 'linear_issue' || type === 'linear_attachment') return 'linear';

  if (type === 'trello_card') return 'trello';

  if (type === 'github_issue' || type === 'github_pull_request') return 'github';

  return 'UNKNOWN';
};

export const attemptToCastKnowledgeSourceToType = (knowledgeSource: KnowledgeSource): Type => {
  if (knowledgeSource === 'azure_devops_wiki_page') return 'azure_devops_wiki_page';

  if (knowledgeSource === 'azure_devops_work_item') return 'azure_devops_work_item';

  if (knowledgeSource === 'azure_devops_repository') return 'azure_devops_repository';

  if (knowledgeSource === 'confluence') return 'confluence_page';

  if (knowledgeSource === 'google_drive') return 'google_drive';

  if (knowledgeSource === 'jira') return 'jira_issue';

  if (knowledgeSource === 'linear') return 'linear_issue';

  if (knowledgeSource === 'trello') return 'trello_card';

  if (knowledgeSource === 'github') return 'github_issue';

  return 'UNKNOWN';
};
