// 3rd
import { z } from 'zod';

// App - Types
import type { Id } from '@/types/knowledge-item/id';
import type { Feedback } from '../../types/feedback';

// App - Other
import { normalizeDatesToISOString } from '@/utils/format';

export const ZodFeedbackDto = z.object({
  id: z.string(),
  content: z.string(),
  lastModified: z.string(),
});

export type FeedbackDto = z.infer<typeof ZodFeedbackDto>;

export const castFeedbackDtoToFeedback = (dto: FeedbackDto, knowledgeItemId: Id): Feedback => {
  return {
    knowledgeItemId,
    id: dto.id,
    content: dto.content,
    lastModified: normalizeDatesToISOString(dto.lastModified),
  };
};
