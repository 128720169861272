// 3rd
import { delay, http } from 'msw';

// App - Types
import { ZodGetFeedbackRequestPayloadDto } from '@/features/feedback';
import type {
  GetFeedbackRequestPayloadDto,
  GetFeedbackResponseDto,
  FeedbackDto,
} from '@/features/feedback';

// App - Other
import { API_URL } from '@/config/constants';
import { db } from '../../db';

export const getDocumentSecurityFeedbackHandler = http.post(
  `${API_URL}/SecurityFeedback/GetDocumentSecurityFeedbacks`,
  async ({ request }) => {
    const payload = (await request.json()) as GetFeedbackRequestPayloadDto;
    const parsedPayload = ZodGetFeedbackRequestPayloadDto.parse(payload);

    await delay(500);

    const feedback: FeedbackDto[] = db.securityFeedback.findMany({
      where: {
        knowledgeItemId: {
          equals: parsedPayload,
        },
      },
    });
    const res: GetFeedbackResponseDto = { securityFeedbacks: feedback };

    return new Response(JSON.stringify(res), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
