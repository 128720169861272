/* eslint-disable @typescript-eslint/no-unused-vars */
// 3rd
import { delay, http } from 'msw';

// App - Types
import type { CreateSlackOAuthIntegrationRequestPayloadDto } from '@/features/integrations';
import { ZodCreateSlackOAuthIntegrationRequestPayloadDto } from '@/features/integrations';

// App - Other
import { API_URL } from '@/config/constants';
import { uuid4 } from '@/utils/generators';
import { db } from '../../db';

export const insertSlackOAuthIntegrationHandler = http.post(
  `${API_URL}/Integration/InsertSlackOAuth2Integration`,
  async ({ request }) => {
    const payload = await request.json();
    const parsedPayload: CreateSlackOAuthIntegrationRequestPayloadDto =
      ZodCreateSlackOAuthIntegrationRequestPayloadDto.parse(payload);

    db.slackIntegration.create({
      id: uuid4(),
      integrationName: `liav@clover.security`,
      integrationType: 'Slack',
    });

    await delay(1000);

    return new Response(null, {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
