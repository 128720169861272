// 3rd
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

// App - Other
import {
  ENVIRONMENT,
  API_URL,
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  DATADOG_SESSION_SAMPLE_RATE,
  DATADOG_SESSION_REPLAY_SAMPLE_RATE,
  DATADOG_RELEASE_VERSION,
} from '@/config/constants';

export const initializeDatadog = () => {
  datadogRum.init({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'webapp',
    version: DATADOG_RELEASE_VERSION,
    env: ENVIRONMENT,
    allowedTracingUrls: [API_URL],
    enableExperimentalFeatures: ['feature_flags'],
    sessionSampleRate: DATADOG_SESSION_SAMPLE_RATE,
    sessionReplaySampleRate: DATADOG_SESSION_REPLAY_SAMPLE_RATE,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();

  datadogLogs.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'webapp',
    version: DATADOG_RELEASE_VERSION,
    env: ENVIRONMENT,
    forwardConsoleLogs: ['error'],
    forwardErrorsToLogs: true,
    sessionSampleRate: DATADOG_SESSION_SAMPLE_RATE,
  });
};

export const setUser = ({
  id,
  name,
  email,
  tenantId,
  tenantName,
}: {
  id: string;
  name: string;
  email: string;
  tenantId: string;
  tenantName: string;
}) => {
  datadogRum.setUser({
    id,
    name,
    email,
    tenantId,
    tenantName,
    tenant: {
      id: tenantId,
      name: tenantName,
    },
  });

  datadogLogs.setUser({
    id,
    name,
    email,
    tenantId,
    tenantName,
    tenant: {
      id: tenantId,
      name: tenantName,
    },
  });
};
