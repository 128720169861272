// 3rd
import { primaryKey } from '@mswjs/data';

export const collectionModels = {
  collection: {
    collectionId: primaryKey(String),
    collectionTitle: String,
    knowledgeSourceType: String,
  },
};
