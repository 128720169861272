// App - Types
import type { RequirementSeverity } from '../requirement-severity';

export const RequirementSeveritiesDto = ['High', 'Medium', 'Low', 'UNKNOWN'] as const;

export type RequirementSeverityDto = (typeof RequirementSeveritiesDto)[number];

export const castRequirementSeverityDtoToRequirementSeverity = (
  dto: RequirementSeverityDto
): RequirementSeverity => {
  if (dto === 'High') return 'high';

  if (dto === 'Medium') return 'medium';

  if (dto === 'Low') return 'low';

  return 'UNKNOWN';
};

export const castRequirementSeverityToRequirementSeverityDto = (
  severity: RequirementSeverity
): RequirementSeverityDto => {
  if (severity === 'high') return 'High';

  if (severity === 'medium') return 'Medium';

  if (severity === 'low') return 'Low';

  return 'UNKNOWN';
};
