export const applicationsMockData = [
  {
    id: 'b8a05f95-fa46-44c5-9511-84ece49c2172',
    name: 'GuardianKey',
    classification: 'Access Control',
    stories: 4,
    criticality: 'high',
    models: 3,
    noOfActivitiesInThePast7D: 103,
    lastActivityAt: '2024-02-10T20:46:00.000Z',
  },
  {
    id: 'b8a05f95-f146-44c5-9511-84ece49c2172',
    name: 'WebApp',
    classification: 'Web Application Interface',
    stories: 2,
    criticality: 'high',
    models: 14,
    noOfActivitiesInThePast7D: 5,
    lastActivityAt: '2024-01-10T20:46:00.000Z',
  },
  {
    id: 'b8a05f95-fa46-44c5-9511-84ece49f2172',
    name: 'AndroidApp',
    classification: 'Mobile Application Interface',
    stories: 1,
    criticality: 'medium',
    models: 1,
    noOfActivitiesInThePast7D: 1,
    lastActivityAt: '2023-11-10T20:46:00.000Z',
  },
  {
    id: 'b8a05f95-fa46-44c5-9512-84ece49c2172',
    name: 'IOSApp',
    classification: 'Mobile Application Interface',
    stories: 3,
    criticality: 'low',
    models: 23,
    noOfActivitiesInThePast7D: 13,
    lastActivityAt: '2024-01-12T20:46:00.000Z',
  },
];
