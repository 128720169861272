// 3rd
import { Divider } from '@chakra-ui/react';

// App - Other
import { Text } from '@/components/atoms/typography';
import { Markdown } from '@/components/organisms/markdown';

type JiraMarkdownFlavorProps = {
  children: string;
};

export const JiraMarkdownFlavor = ({ children }: JiraMarkdownFlavorProps) => {
  return (
    <Markdown
      direction="column"
      alignSelf="stretch"
      gap="xxs"
      w="100%"
      overrides={{
        br: {
          component: () => <br />,
        },
        hr: {
          component: () => <Divider mt="lg" />,
        },
        p: {
          component: Text,
          props: {
            display: 'inline-block',
            className: 'p',
            variant: 'detail',
            color: 'text.high-tone',

            sx: {
              '&:not(:first-child)': {
                mt: 'md',
              },
            },
          },
        },
        em: {
          component: Text,
          props: {
            display: 'inline-block',
            className: 'em',
            variant: 'detail-semibold',
            color: 'text.high-tone',
            mt: 'xs',
          },
        },
        strong: {
          component: Text,
          props: {
            display: 'inline-block',
            className: 'strong',
            variant: 'detail-semibold',
            color: 'text.high-tone',
            whiteSpace: 'break-spaces',
          },
        },
      }}
    >
      {children}
    </Markdown>
  );
};
