// React & Next
import type { ReactElement } from 'react';

// 3rd
import { createStore, useStore } from 'zustand';

// App - Other
import { uuid4 } from '@/utils/generators';

export type NotificationType = 'info' | 'warning' | 'success' | 'error';

export type Notification = {
  id: string;
  type: NotificationType;
  title: string;
  duration?: number;
  message?: string | ReactElement;
};

export type NotificationsStore = {
  notifications: Notification[];
  showNotification: (notification: Omit<Notification, 'id'>) => void;
  dismissNotification: (id: string) => void;
};

export const notificationsStore = createStore<NotificationsStore>((set, get) => ({
  notifications: [],

  showNotification: (notification) => {
    const id = uuid4();

    set((state) => ({
      notifications: [...state.notifications, { id, ...notification }],
    }));

    if (notification.duration) {
      setTimeout(() => {
        get().dismissNotification(id);
      }, notification.duration);
    }
  },

  dismissNotification: (id) => {
    set((state) => ({
      notifications: state.notifications.filter((notification) => notification.id !== id),
    }));
  },
}));

export const useNotifications = () => useStore(notificationsStore);
