// 3rd
import { delay, http } from 'msw';

// App - Types
import type {
  CollectionDto,
  GetCollectionRequestPayloadDto,
  GetCollectionResponseDto,
} from '@/features/collections';
import {
  ZodCollectionDto,
  ZodGetCollectionRequestPayloadDto,
  ZodGetCollectionResponseDto,
} from '@/features/collections';

// App - Other
import { API_URL } from '@/config/constants';
import { db } from '../../db';

export const getCollectionHandler = http.post(
  `${API_URL}/Collection/GetCollection`,
  async ({ request }) => {
    const payload = await request.json();
    const parsedPayload: GetCollectionRequestPayloadDto =
      ZodGetCollectionRequestPayloadDto.parse(payload);

    const collection = db.collection.findFirst({
      where: {
        collectionId: {
          equals: parsedPayload.collectionId,
        },
      },
    });

    await delay(500);

    if (!collection) {
      return new Response(null, {
        status: 404,
        statusText: 'Collection not found',
      });
    }

    const parsedCollection: CollectionDto = ZodCollectionDto.parse(collection);
    const res: GetCollectionResponseDto = { knowledgeCollection: parsedCollection };
    const parsedRes: GetCollectionResponseDto = ZodGetCollectionResponseDto.parse(res);

    return new Response(JSON.stringify(parsedRes), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
