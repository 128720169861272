// App - Types
import type { Type } from '../type';

export const TypesDto = [
  'AzureDevOpsWikiPage',
  'AzureDevOpsWorkItem',
  'AzureDevOpsRepository',
  'ConfluencePage',
  'ConfluenceAttachment',
  'GoogleDrive',
  'JiraIssue',
  'JiraAttachment',
  'LinearIssue',
  'LinearAttachment',
  'TrelloCard',
  'GithubIssue',
  'GithubPullRequest',
  'UNKNOWN',
] as const;

export type TypeDto = (typeof TypesDto)[number];

export const castTypeDtoToType = (dto: TypeDto): Type => {
  if (dto === 'AzureDevOpsWikiPage') return 'azure_devops_wiki_page';

  if (dto === 'AzureDevOpsWorkItem') return 'azure_devops_work_item';

  if (dto === 'AzureDevOpsRepository') return 'azure_devops_repository';

  if (dto === 'ConfluencePage') return 'confluence_page';

  if (dto === 'ConfluenceAttachment') return 'confluence_attachment';

  if (dto === 'GoogleDrive') return 'google_drive';

  if (dto === 'JiraIssue') return 'jira_issue';

  if (dto === 'JiraAttachment') return 'jira_attachment';

  if (dto === 'LinearIssue') return 'linear_issue';

  if (dto === 'LinearAttachment') return 'linear_attachment';

  if (dto === 'TrelloCard') return 'trello_card';

  if (dto === 'GithubIssue') return 'github_issue';

  if (dto === 'GithubPullRequest') return 'github_pull_request';

  return 'UNKNOWN';
};

export const castTypeToTypeDto = (type: Type): TypeDto => {
  if (type === 'azure_devops_wiki_page') return 'AzureDevOpsWikiPage';

  if (type === 'azure_devops_work_item') return 'AzureDevOpsWorkItem';

  if (type === 'azure_devops_repository') return 'AzureDevOpsRepository';

  if (type === 'confluence_page') return 'ConfluencePage';

  if (type === 'confluence_attachment') return 'ConfluenceAttachment';

  if (type === 'google_drive') return 'GoogleDrive';

  if (type === 'jira_issue') return 'JiraIssue';

  if (type === 'jira_attachment') return 'JiraAttachment';

  if (type === 'linear_issue') return 'LinearIssue';

  if (type === 'linear_attachment') return 'LinearAttachment';

  if (type === 'trello_card') return 'TrelloCard';

  if (type === 'github_issue') return 'GithubIssue';

  if (type === 'github_pull_request') return 'GithubPullRequest';

  return 'UNKNOWN';
};
