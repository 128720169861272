// App - Types
import type { IntegrationDto } from '@/features/integrations';

export const knowledgeSourceConfigurationsMockData: IntegrationDto[] = [
  {
    id: 'c2868d28-5a01-4924-840f-72daddd0b0ef',
    knowledgeSourceType: 'AzureDevOpsWikiPage',
    lastScraped: '2024-05-21T15:56:23.446289Z',
    displayName: 'https://dev.azure.com/cloversecurity',
    integrationStatus: 'Valid',
  },
  {
    id: '336a3e78-7db3-4de1-b0d1-a718f6112807',
    knowledgeSourceType: 'Confluence',
    lastScraped: '2024-08-25T16:02:32.135922Z',
    displayName: 'orchen@gmail.com',
    integrationStatus: 'Valid',
  },
  {
    id: '16d14324-38f6-43fb-88ea-8e652efd21af',
    knowledgeSourceType: 'Linear',
    lastScraped: '0001-01-01T00:00:00',
    displayName: 'liav@clover.security',
    integrationStatus: 'Valid',
  },
  {
    id: '8bd3147a-e4e7-4540-af10-2fbd3ab48a10',
    knowledgeSourceType: 'Jira',
    lastScraped: '2024-08-25T16:06:02.436504Z',
    displayName: 'eylon@clover.security',
    integrationStatus: 'Valid',
  },
  {
    id: '4b920260-e607-4620-86a8-444f329395e0',
    knowledgeSourceType: 'Jira',
    lastScraped: '2024-05-07T14:20:07.425713Z',
    displayName: 'orchen@gmail.com',
    integrationStatus: 'Valid',
  },
  {
    id: 'ad2d2b61-6267-482c-bbd9-49c263008ede',
    knowledgeSourceType: 'GoogleDrive',
    lastScraped: '2024-08-25T16:02:12.103564Z',
    displayName: 'eylon@clover.security',
    integrationStatus: 'Valid',
  },
  {
    id: '7bf60e99-7956-4316-b70d-a9b12b769984',
    knowledgeSourceType: 'Jira',
    lastScraped: '0001-01-01T00:00:00',
    displayName: 'broken@email.test',
    integrationStatus: 'Valid',
  },
  {
    id: '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security',
    knowledgeSourceType: 'Github',
    lastScraped: '2024-08-25T16:04:02.215744Z',
    displayName: 'https://github.com/clover-security',
    integrationStatus: 'Valid',
  },
  {
    id: 'd9cb1d92-c4a9-4932-8b23-fa90bad98c23',
    knowledgeSourceType: 'AzureDevOpsWorkItem',
    lastScraped: '2024-05-21T15:56:23.261564Z',
    displayName: 'https://dev.azure.com/cloversecurity',
    integrationStatus: 'Valid',
  },
  {
    id: 'ecabb9a5-4579-4a82-8e3f-bf41421200e7',
    knowledgeSourceType: 'GoogleDrive',
    lastScraped: '2024-08-25T16:03:32.222917Z',
    displayName: 'guy@clover.security',
    integrationStatus: 'Valid',
  },
  {
    id: '73fec2de-58a3-4cab-92f6-063b04ab4ef0',
    knowledgeSourceType: 'GoogleDrive',
    lastScraped: '2024-08-25T16:04:32.294356Z',
    displayName: 'liav@clover.security',
    integrationStatus: 'Valid',
  },
  {
    id: 'aef35e49-76a2-46d3-8d97-7168eac66f79',
    knowledgeSourceType: 'Jira',
    lastScraped: '2024-02-01T01:03:55.624Z',
    displayName: 'eylon@clover.security',
    integrationStatus: 'Valid',
  },
  {
    id: '042c7d6a-1a55-4e21-99a9-1105c2aca6df',
    knowledgeSourceType: 'GoogleDrive',
    lastScraped: '2024-08-25T16:02:54.339235Z',
    displayName: 'eylon@cloversec.io',
    integrationStatus: 'Valid',
  },
  {
    id: '773c39b8-baae-4aa2-91d9-9514693747ca',
    knowledgeSourceType: 'Trello',
    lastScraped: '2024-08-25T16:02:24.293409Z',
    displayName: 'or@clover.security',
    integrationStatus: 'Valid',
  },
];
