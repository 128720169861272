// App - Types
import type { Priority } from '../priority';

export const PrioritiesDto = ['High', 'Medium', 'Low', 'UNKNOWN'] as const;

export type PriorityDto = (typeof PrioritiesDto)[number];

export const castPriorityDtoToPriority = (dto: PriorityDto): Priority => {
  if (dto === 'High') return 'high';

  if (dto === 'Medium') return 'medium';

  if (dto === 'Low') return 'low';

  return 'UNKNOWN';
};

export const castPriorityToPriorityDto = (priority: Priority): PriorityDto => {
  if (priority === 'high') return 'High';

  if (priority === 'medium') return 'Medium';

  if (priority === 'low') return 'Low';

  return 'UNKNOWN';
};
