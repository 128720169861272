// 3rd
import { primaryKey } from '@mswjs/data';

// App - Other
import { uuid4 } from '@/utils/generators';

export const applicationsModels = {
  application: {
    id: primaryKey(uuid4),
    name: String,
    classification: String,
    stories: Number,
    criticality: String,
    models: Number,
    noOfActivitiesInThePast7D: Number,
    lastActivityAt: String,
  },
};
