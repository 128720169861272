// 3rd
import { delay, http } from 'msw';

// App - Types
import type {
  CreateGoogleDriveOAuthUrlRequestPayloadDto,
  CreateGoogleDriveOAuthUrlResponseDto,
} from '@/features/integrations';
import {
  ZodCreateGoogleDriveOAuthUrlRequestPayloadDto,
  ZodCreateGoogleDriveOAuthUrlResponseDto,
} from '@/features/integrations';

// App - Other
import { API_URL } from '@/config/constants';

export const getGoogleDriveOauthLoginUrlHandler = http.post(
  `${API_URL}/Integration/GetGoogleDriveOAuth2LoginUrl`,
  async ({ request }) => {
    const payload = await request.json();
    const parsedPayload: CreateGoogleDriveOAuthUrlRequestPayloadDto =
      ZodCreateGoogleDriveOAuthUrlRequestPayloadDto.parse(payload);

    await delay(500);

    const res: CreateGoogleDriveOAuthUrlResponseDto = {
      url: `${parsedPayload.redirectUri}${parsedPayload.redirectUriPath}?code=****&scope=****`,
    };
    const parsedRes: CreateGoogleDriveOAuthUrlResponseDto =
      ZodCreateGoogleDriveOAuthUrlResponseDto.parse(res);

    return new Response(JSON.stringify(parsedRes), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
