// App - Types
import type { AnalyzedKnowledgeItemDto } from '@/features/knowledge-items';

type EnrichedKnowledgeItemDto = AnalyzedKnowledgeItemDto & {
  id: string;
};

export const knowledgeItemsMockData: EnrichedKnowledgeItemDto[] = [
  {
    id: 'confluence#425986#15761412',
    author: 'Alon Kollmann',
    collectionId: '425986',
    creationTime: '2023-12-14T16:09:31.283Z',
    documentId: '15761412',
    documentScore: 4,
    documentSource: 'Confluence',
    documentType: 'Product Requirements Document',
    extractedLabels: {
      accessControl: {
        authorization: false,
        authentication: false,
        changeDetails:
          'Access control considerations involve securely storing customer payment information according to PCI DSS standards and ensuring clear subscription terms, modification/cancellation options, and notifications for auto-renewals.',
      },
      businessLogic:
        'The key business logic decisions involve implementing subscription services with options for frequency and quantity, securely storing payment information following PCI DSS standards, providing clear subscription terms, and easy modification/cancellation options.',
      dataChanges: {
        changeDetails:
          'New data storage needed for securely storing customer payment information for recurring billing in adherence to PCI DSS and other relevant data security standards.',
        typeOfChange: 'New data stored',
        typeOfData: 'PII/Sensitive information/User data',
      },
      sensitiveInformation:
        '- The document contains sensitive customer payment information which needs to be securely stored for recurring billing\n- Sensitive data includes payment details subject to PCI DSS and other relevant data security standards\n- Customers must be notified before each auto-renewal or payment deduction to comply with data privacy regulations',
    },
    lastModified: '2023-12-14T16:09:43.583Z',
    originUrl:
      'https://orchen.atlassian.net/wiki/spaces/BEP/pages/15761412/Subscription+Services+and+Auto-renewal+Features',
    priority: 'High',
    scoreBreakdown: {
      documentDepth: 8,
      riskScore: 7,
      significance: 9,
    },
    shortenedTitle: 'Subscription Services & Auto-renewal Features',
    summary:
      '### ByteBasket - Subscription Services and Auto-renewal Features Summary\n\nThe document outlines user story and product requirements for implementing a subscription service feature on the ecommerce platform. Users can subscribe to specific products for regular deliveries, set frequency and quantity, with secure payment information storage following PCI DSS standards. The platform must offer clear subscription terms, easy modification/cancellation options, and timely notifications before auto-renewals or payment deductions.',
    title: 'Subscription Services and Auto-renewal Features',
  },
  {
    id: 'google-drive#eylon@cloversec.io#1E2FK5ezmCPzkDrr4tfVM4QB5WpJfNHGz3lV6VndbGCQ',
    author: 'Eylon Saadon',
    collectionId: 'eylon@cloversec.io',
    creationTime: '2024-02-04T09:33:12.306Z',
    documentId: '1E2FK5ezmCPzkDrr4tfVM4QB5WpJfNHGz3lV6VndbGCQ',
    documentScore: 4,
    documentSource: 'GoogleDrive',
    documentType: 'Product Requirements Document',
    extractedLabels: {
      businessLogic:
        'Key business logic decision involves implementing continuous updates to the fraud detection system to adapt to new fraud tactics while maintaining a balance between security and user convenience.',
      dataChanges: {
        changeDetails:
          'The implementation of an AI-driven fraud detection system involves handling and processing sensitive transaction data for fraud detection purposes.',
        typeOfChange: 'New data source',
        typeOfData: 'Sensitive information',
      },
      // designDecisions:
      //   'Key design decision is to implement an AI-driven fraud detection system that analyzes real-time transaction data for patterns of fraudulent activity.',
    },
    lastModified: '2024-04-09T08:16:46.075Z',
    originUrl:
      'https://docs.google.com/document/d/1E2FK5ezmCPzkDrr4tfVM4QB5WpJfNHGz3lV6VndbGCQ/edit?usp=drivesdk',
    priority: 'High',
    scoreBreakdown: {
      documentDepth: 8,
      riskScore: 7,
      significance: 9,
    },
    shortenedTitle: 'AI-driven Fraud Detection System Implementation',
    summary:
      '### Summary:\nThe document outlines a user story for implementing an AI-based fraud detection system in an eCommerce platform to combat fraudulent activities. It emphasizes the need for real-time analysis of transaction data for detecting suspicious patterns and the importance of continuous updates to stay ahead of evolving fraud tactics while minimizing false positives for a seamless user experience.',
    title: 'AI-driven Fraud DP',
  },
  {
    id: 'google-drive#guy@clover.security#1E2FK5ezmCPzkDrr4tfVM4QB5WpJfNHGz3lV6VndbGCQ',
    author: 'eylon',
    collectionId: 'guy@clover.security',
    creationTime: '2024-02-04T09:33:12.306Z',
    documentId: '1E2FK5ezmCPzkDrr4tfVM4QB5WpJfNHGz3lV6VndbGCQ',
    documentScore: 4,
    documentSource: 'GoogleDrive',
    documentType: 'Product Requirements Document',
    extractedLabels: {
      businessLogic:
        'The document outlines the business logic decisions related to implementing an AI-driven fraud detection system to protect the eCommerce platform and customers by analyzing transaction data in real-time for fraudulent patterns and avoiding false positives.',
      dataChanges: {
        changeDetails:
          'The document introduces a new data source for analyzing transaction data in real-time to enhance fraud detection capabilities. The data involved may contain Personally Identifiable Information (PII) that requires protection.',
        typeOfChange: 'New data source',
        typeOfData: 'PII',
      },
      // designDecisions:
      //   'The document describes the decision to integrate an AI-driven fraud detection system that analyzes transaction data in real-time to identify patterns of fraudulent activity. It also emphasizes the importance of continuously updating the system to adapt to new fraud tactics while maintaining a balance between security and user convenience.',
    },
    lastModified: '2024-04-09T08:16:46.075Z',
    originUrl:
      'https://docs.google.com/document/d/1E2FK5ezmCPzkDrr4tfVM4QB5WpJfNHGz3lV6VndbGCQ/edit?usp=drivesdk',
    priority: 'High',
    scoreBreakdown: {
      documentDepth: 8,
      riskScore: 7,
      significance: 9,
    },
    shortenedTitle: 'AI-driven Fraud Detection System Implementation',
    summary:
      '**Summary:**\nThe document outlines the implementation of an AI-driven fraud detection system for an eCommerce platform. Key points include the need to analyze transaction data in real-time for detecting fraudulent patterns, updating the system to adapt to new fraud tactics, and balancing security with user convenience to avoid false positives impacting legitimate customers.',
    title: 'AI-driven Fraud DP',
  },
  {
    id: 'google-drive#guy@clover.security#1OVqCNI4fCKWZF_zJYEHtOQCt-0XjeEr9',
    author: 'Guy Lyuboshits',
    collectionId: 'guy@clover.security',
    creationTime: '2024-04-17T08:59:48.544Z',
    documentId: '1OVqCNI4fCKWZF_zJYEHtOQCt-0XjeEr9',
    documentScore: 4,
    documentSource: 'GoogleDrive',
    documentType: 'Product Requirements Document',
    extractedLabels: {
      businessLogic:
        'Balancing security with user convenience in fraud detection\nEnsuring the detection system is effective without impacting legitimate customers',
      dataChanges: {
        changeDetails:
          'Introduction of transaction data storage for fraud detection, potentially including PII and sensitive user information.',
        typeOfChange: 'New data stored',
        typeOfData: 'PII/Sensitive information/User data',
      },
      // designDecisions:
      //   '- Implementation of an AI-driven fraud detection system\n- Real-time analysis of transaction data\n- Continuous system updates for new fraud tactics\n- Balancing security with user convenience to avoid false positives',
    },
    lastModified: '2024-04-17T08:59:34Z',
    originUrl:
      'https://drive.google.com/file/d/1OVqCNI4fCKWZF_zJYEHtOQCt-0XjeEr9/view?usp=drivesdk',
    priority: 'High',
    scoreBreakdown: {
      documentDepth: 8,
      riskScore: 8,
      significance: 9,
    },
    shortenedTitle: 'AI Fraud Detection System Requirements',
    summary:
      '### Summary:\n\n**The document outlines the need for an AI-driven fraud detection system for an eCommerce platform to combat fraudulent activities effectively. The system is required to analyze transaction data in real-time, detect suspicious patterns, update regularly to address new fraud tactics, and strike a balance between security and user convenience to prevent false positives.**',
    title: 'AI-driven Fraud DP - PDF.pdf',
  },
  {
    id: 'confluence#425986#15859725',
    author: 'Alon Kollmann',
    collectionId: '425986',
    creationTime: '2023-12-14T16:11:06.176Z',
    documentId: '15859725',
    documentScore: 4,
    documentSource: 'Confluence',
    documentType: 'Feature',
    extractedLabels: {
      accessControl: {
        authorization: true,
        authentication: false,
        changeDetails:
          'The document highlights security requirements like encryption of data at rest and in-transit, ensuring secure storage of transaction data, and maintaining a balance between security and user convenience.',
      },
      businessLogic:
        'The document contains business logic decisions, including adapting the fraud detection system to new fraud tactics, balancing security with user convenience, and ensuring secure storage of transaction data and user information.',
      dataChanges: {
        changeDetails:
          'The document emphasizes data encryption at rest and in-transit to protect user information, flagged activities, and ensure compliance with General Data Protection requirements.',
        typeOfChange: 'Cryptography',
        typeOfData: 'PII/Sensitive information/User data',
      },
      // designDecisions:
      //   'The document includes design decisions related to implementing an AI-driven fraud detection system, such as real-time transaction data analysis, pattern detection, and automatic flagging of suspicious transactions.',
    },
    lastModified: '2024-04-01T09:23:14.542Z',
    originUrl:
      'https://orchen.atlassian.net/wiki/spaces/BEP/pages/15859725/Feature%3A+AI-driven+Fraud+Detection+and+Prevention',
    priority: 'High',
    scoreBreakdown: {
      documentDepth: 8,
      riskScore: 9,
      significance: 9,
    },
    shortenedTitle: 'AI Fraud Detection and Prevention Feature',
    summary:
      '\n### Summary:\nThe document focuses on implementing an AI-driven fraud detection system in an eCommerce platform to detect and prevent fraudulent activities in real-time. It highlights the need to analyze transaction data for patterns, automatically flag suspicious transactions, continuously update the system against new fraud tactics, and secure all transaction data. Additionally, it emphasizes testing the system against various fraud scenarios to ensure its accuracy and adaptability. **Security requirements include setting token expiration, encrypted data storage, and adhering to data protection regulations.**',
    title: 'Feature: AI-driven Fraud Detection and Prevention',
  },
  {
    id: 'confluence#425986#16187393',
    author: 'Alon Kollmann',
    collectionId: '425986',
    creationTime: '2023-12-14T16:08:15.855Z',
    documentId: '16187393',
    documentScore: 4,
    documentSource: 'Confluence',
    documentType: 'Product Requirements Document',
    extractedLabels: {
      accessControl: {
        authorization: true,
        authentication: false,
        changeDetails:
          'The document specifies the need for robust security protocols to protect sensitive inventory data, including access controls to prevent unauthorized access or data breaches. This involves authorization changes and the implementation of access controls for data protection.',
      },
      dataChanges: {
        changeDetails:
          'The document mentions the integration of inventory data from various warehouses and sales channels, introducing a new data source. This data may include sensitive inventory information that requires proper handling and protection.',
        typeOfChange: 'New data source',
        typeOfData: 'PII/Sensitive information/User data',
      },
      // designDecisions:
      //   'Key design decisions include integrating with existing databases, creating a dashboard for visualization of data, and implementing robust security protocols for data protection.',
      infrastructure: {
        changeDetails:
          'The document mentions the implementation of a real-time inventory management system, which involves changes in the system architecture by integrating with existing warehouse and sales channel databases. This integration is a major change and would require a security review.',
        changeType: 'New/Modified',
        isMajorChange: true,
        technology: '',
      },
      sensitiveInformation:
        '- The document contains sensitive inventory data, which is crucial for the eCommerce business.\n- Sensitive inventory data includes stock levels, pending orders, and sales forecasts, which if exposed or manipulated, could lead to potential financial losses or reputation damage.',
    },
    lastModified: '2023-12-14T16:09:10.496Z',
    originUrl:
      'https://orchen.atlassian.net/wiki/spaces/BEP/pages/16187393/Real-time+Inventory+Management',
    priority: 'High',
    scoreBreakdown: {
      documentDepth: 7,
      riskScore: 7,
      significance: 8,
    },
    shortenedTitle: 'Real-time Inventory Management',
    summary:
      '## Real-time Inventory Management\n\n**User Story:** As an eCommerce manager, have real-time visibility of inventory across warehouses and sales channels for efficient stock management to avoid overselling or stockouts.\n\n**Product Requirements:**\n- Implement real-time inventory management system integrating with warehouse and sales channel databases.\n- Provide dashboard view with stock levels, pending orders, and sales forecasts.\n- Ensure robust security with backups, access controls to protect sensitive inventory data.',
    title: 'Real-time Inventory Management',
  },
  {
    id: 'confluence#425986#295000',
    author: 'CloverBot',
    collectionId: '425986',
    creationTime: '2023-08-02T17:39:26.813Z',
    documentId: '295000',
    documentScore: 3,
    documentSource: 'Confluence',
    documentType: 'Feature',
    extractedLabels: {
      businessLogic:
        'The document outlines the business logic for the Customer Reviews Feature in an e-commerce platform.',
      dataChanges: {
        changeDetails:
          'The system now stores user-generated reviews and ratings for products to enhance user engagement and assist potential customers.',
        typeOfChange: 'New data stored',
        typeOfData: 'User data',
      },
      userFlows: {
        changeDetails:
          'The document describes the user flow for submitting, storing, and displaying product reviews in the e-commerce platform.',
        flowChangeType: 'User',
      },
      sensitiveInformation:
        '- The document contains sensitive user data in the form of customer reviews and ratings.\n- Sensitive as it might include personal opinions and ratings that could impact the reputation of the products and the business.\n- The data is used to enhance user engagement, improve product transparency, and assist potential customers in making informed decisions.',
    },
    lastModified: '2024-03-19T12:48:03.265Z',
    originUrl: 'https://orchen.atlassian.net/wiki/spaces/BEP/pages/295000/Customer+Reviews+Feature',
    priority: 'Medium',
    scoreBreakdown: {
      documentDepth: 8,
      riskScore: 4,
      significance: 9,
    },
    shortenedTitle: 'Customer Reviews Feature',
    summary:
      '### Customer Reviews Feature\n**Objective:** The Customer Review and Rating System aims to enable customers to provide reviews and ratings for purchased products, enhancing user engagement and assisting potential customers in making informed decisions.  \n**User Flow:** Users navigate to the product page, leave a review with text and rating, which gets stored in the database and displayed on the product page for others.  \n**Key Features:** Review Creation, Display, Management (edit/delete), and Sorting/Filtering options for users to provide and interact with product reviews effectively.',
    title: 'Customer Reviews Feature',
  },
  {
    id: 'confluence#425986#16023573',
    author: 'Alon Kollmann',
    collectionId: '425986',
    creationTime: '2023-12-14T16:10:35.058Z',
    documentId: '16023573',
    documentScore: 3,
    documentSource: 'Confluence',
    documentType: 'Product Requirements Document',
    extractedLabels: {
      accessControl: {
        authorization: true,
        authentication: false,
        changeDetails:
          '- Authorization changes need to be implemented to ensure strict access control for voice search and shopping functionalities. This includes defining and enforcing user permissions and privacy controls.',
      },
      dataChanges: {
        changeDetails:
          '- Introduction of voice recordings and personal data storage necessitates secure handling to protect Personally Identifiable Information (PII) of users.',
        typeOfChange: 'New data stored',
        typeOfData: 'PII',
      },
      // designDecisions:
      //   'Key design decisions include integrating a voice recognition system, ensuring compatibility with major voice assistants, and prioritizing user privacy by implementing strict data handling policies.',
      userFlows: {
        changeDetails:
          '- The input flow changes to accepting voice commands instead of typed queries.\n- The output flow changes to processing voice commands and generating appropriate search results or purchase actions.',
        flowChangeType: 'User',
      },
      sensitiveInformation:
        '* This document includes sensitive user data, focusing on privacy and data handling.\n* The document mentions implementing strict data handling and storage policies, especially for voice recordings and personal data.',
    },
    lastModified: '2023-12-14T16:10:45.679Z',
    originUrl:
      'https://orchen.atlassian.net/wiki/spaces/BEP/pages/16023573/Voice+Search+and+Voice-Activated+Shopping',
    priority: 'Medium',
    scoreBreakdown: {
      documentDepth: 8,
      riskScore: 4,
      significance: 9,
    },
    shortenedTitle: 'Voice Search and Voice-Activated Shopping Feature',
    summary:
      '### Summary:\nThe **"Voice Search and Voice-Activated Shopping"** document outlines the **user story** of enabling customers to search for products and make purchases using voice commands for added convenience. The **product requirements** include integrating a **voice recognition system**, ensuring compatibility with major voice assistants like Google Assistant and Amazon Alexa, and prioritizing user privacy through strict data handling policies for voice recordings and personal data.',
    title: 'Voice Search and Voice-Activated Shopping',
  },
  {
    id: 'confluence#425986#15859713',
    author: 'Alon Kollmann',
    collectionId: '425986',
    creationTime: '2023-12-14T15:57:31.439Z',
    documentId: '15859713',
    documentScore: 4,
    documentSource: 'Confluence',
    documentType: 'Product Requirements Document',
    extractedLabels: {
      accessControl: {
        authorization: true,
        authentication: false,
        changeDetails:
          'Access control decisions focus on strict access controls and encryption for user data to ensure data privacy and security.',
      },
      businessLogic:
        'Business logic decisions involve generating personalized product recommendations based on user data, updating recommendations in real-time.',
      dataChanges: {
        changeDetails:
          'The document introduces a new data source for user purchase history, browsing behavior, and search patterns to generate personalized recommendations. Data privacy measures are emphasized with strict access controls and encryption.',
        typeOfChange: 'New data source',
        typeOfData: 'Sensitive information',
      },
      // designDecisions:
      //   'Key design decisions include: analyzing user data for recommendations, ensuring data privacy with access controls and encryption, real-time update of recommendation engine.',
      sensitiveInformation:
        '- The document contains sensitive user data like purchase history, browsing behavior, and search patterns. \n- These details are sensitive as they can reveal personal preferences and behaviors of users, which need to be protected.',
    },
    lastModified: '2023-12-14T16:07:45.974Z',
    originUrl:
      'https://orchen.atlassian.net/wiki/spaces/BEP/pages/15859713/Personalized+Product+Recommendations',
    priority: 'High',
    scoreBreakdown: {
      documentDepth: 7,
      riskScore: 7,
      significance: 8,
    },
    shortenedTitle: 'Personalized Product Recommendations',
    summary:
      '## Document Title: Personalized Product Recommendations\n\nThe document outlines user stories and product requirements for implementing personalized product recommendations on an ecommerce platform. Key points include analyzing user data for tailored suggestions, prioritizing data privacy with access controls and encryption, and ensuring real-time updates to the recommendation engine based on user behavior and inventory levels.',
    title: 'Personalized Product Recommendations',
  },
  {
    id: 'confluence#425986#16285712',
    author: 'Alon Kollmann',
    collectionId: '425986',
    creationTime: '2023-12-14T16:34:27.395Z',
    documentId: '16285712',
    documentScore: 4,
    documentSource: 'Confluence',
    documentType: 'Product Requirements Document',
    extractedLabels: {
      businessLogic:
        'The document outlines key business logic decisions related to dynamic pricing strategies and pricing transparency.',
    },
    lastModified: '2023-12-14T16:34:38.241Z',
    originUrl:
      'https://orchen.atlassian.net/wiki/spaces/BEP/pages/16285712/Dynamic+Pricing+and+Discounts',
    priority: 'High',
    scoreBreakdown: {
      documentDepth: 7,
      riskScore: 7,
      significance: 8,
    },
    shortenedTitle: 'Dynamic Pricing and Discounts',
    summary:
      '# ByteBasket - Ecommerce Platform - PRDs & Features\n\n**Document Title: Dynamic Pricing and Discounts**\n\n**User Story**: As an eCommerce business owner, the goal is to implement dynamic pricing strategies using a pricing engine that adjusts prices based on market trends, inventory, and customer demand.\n\n**Product Requirements**:\n- Integrate dynamic pricing engine based on demand, competition, inventory, and purchasing behavior.\n- Maintain transparency in pricing changes for customer trust.\n- Implement safeguards to prevent pricing errors and ensure legal and ethical pricing standards adherence.',
    title: 'Dynamic Pricing and Discounts',
  },
  {
    id: 'confluence#425986#16056334',
    author: 'Alon Kollmann',
    collectionId: '425986',
    creationTime: '2023-12-14T16:10:50.731Z',
    documentId: '16056334',
    documentScore: 3,
    documentSource: 'Confluence',
    documentType: 'Feature',
    extractedLabels: {
      // designDecisions:
      //   '- Development of a feature for 3D rendering of products on product pages\n- Requirement for high-quality and realistic 3D models\n- Optimization for various devices and internet speeds',
    },
    lastModified: '2023-12-14T16:11:01.155Z',
    originUrl:
      'https://orchen.atlassian.net/wiki/spaces/BEP/pages/16056334/Feature%3A+Interactive+3D+Product+Models',
    priority: 'Medium',
    scoreBreakdown: {
      documentDepth: 8,
      riskScore: 4,
      significance: 9,
    },
    shortenedTitle: 'Interactive 3D Product Models Feature',
    summary:
      '# Feature: Interactive 3D Product Models\n\n**User Story**: Online shoppers want to explore products in 3D on the website to rotate, zoom, and interact for better understanding of features and quality.\n\n**Product Requirements**:\n- Develop a feature for 3D rendering on product pages with interactive options.\n- Ensure high-quality, realistic 3D models that accurately represent product details.\n- Optimize the 3D viewer for various devices and internet speeds to provide a smooth user experience with minimal loading delays.',
    title: 'Feature: Interactive 3D Product Models',
  },
  {
    id: 'confluence#425986#20742145',
    author: 'Or Chen',
    collectionId: '425986',
    creationTime: '2024-01-09T12:11:00.446Z',
    documentId: '20742145',
    documentScore: 4,
    documentSource: 'Confluence',
    documentType: 'Product Requirements Document',
    extractedLabels: {
      accessControl: {
        authorization: true,
        authentication: true,
        changeDetails:
          'New authentication and authorization mechanisms are being implemented to ensure data privacy and secure access control for user data and admin functionalities.',
      },
      api: {
        changeDetails:
          'The document outlines the development of API endpoints for submitting, retrieving, and updating missing item reports. It also includes the necessary authentication mechanisms for data privacy.',
        external: 'Internal',
        specificApis: [
          {
            apiName: 'Missing Item Reporting API',
            changeType: 'New',
            type: 'Internal',
          },
        ],
      },
      architecture:
        "The feature involves the creation of a 'MissingItems' table in the database schema to store missing item reports, API endpoint development for data operations, and frontend development for user interaction. It also includes setting up authentication, authorization, and a background job system for notifications.",
      businessLogic:
        'The key business logic involves allowing users to report missing items, providing admins access to reported data, enabling customer support to handle missing item reports, and ensuring secure data encryption.',
      dataChanges: {
        changeDetails:
          "New data will be stored in the 'MissingItems' table including user_id, order_id, item_id, description, status, and timestamp. Proper encryption and data protection measures will need to be implemented for PII.",
        typeOfChange: 'New data stored',
        typeOfData: 'PII',
      },
      // designDecisions:
      //   'Key design decisions include implementing role-based access control in the admin panel, integrating real-time chat for user support, and utilizing data visualization tools for trend monitoring.',
      userFlows: {
        changeDetails:
          'New input flow introduced for users to report missing items, with associated output flows for notifications and admin actions.',
        flowChangeType: 'User',
      },
    },
    lastModified: '2024-04-02T12:25:08.221Z',
    originUrl:
      'https://orchen.atlassian.net/wiki/spaces/BEP/pages/20742145/Missing+Item+Reporting+Feature',
    priority: 'High',
    scoreBreakdown: {
      documentDepth: 8,
      riskScore: 5,
      significance: 9,
    },
    shortenedTitle: 'Missing Item Reporting Feature PRD',
    summary:
      '\n## **Missing Item Reporting Feature Summary:**\nThe Missing Item Reporting feature aims to enhance user experience by allowing customers to report missing items from orders, with admins accessing reported data for analysis. Functional requirements include a user interface with attachment options, email notifications, admin panel for managing reports, and customer support integration. Non-functional requirements cover security, performance, scalability, usability, and compliance aspects. The R&D design involves creating a database schema, backend API development, frontend interface design, admin panel setup, testing procedures, deployment stages, and monitoring/maintenance planning for a comprehensive implementation.',
    title: 'Missing Item Reporting Feature',
  },
  {
    id: 'google-drive#eylon@cloversec.io#1F_-M5QTQ9iEAZzy_DJM7_8jtUEdNu8NKfrtk1ktaUI4',
    author: 'guy',
    collectionId: 'eylon@cloversec.io',
    creationTime: '2024-05-02T13:18:52.027Z',
    documentId: '1F_-M5QTQ9iEAZzy_DJM7_8jtUEdNu8NKfrtk1ktaUI4',
    documentScore: 4,
    documentSource: 'GoogleDrive',
    documentType: 'Feature',
    extractedLabels: {
      accessControl: {
        authorization: true,
        authentication: false,
        changeDetails:
          'The document mentions the need for users to have pre-stored payment and shipping information for one-click checkout. This requires authorization controls to ensure that only authorized users can access and use the one-click feature.',
      },
      architecture:
        'The document introduces a new One-Click Checkout feature that requires architectural changes. Key insights include the development of a one-click checkout button, integration with user profiles, and updating of payment and shipping information systems.',
      businessLogic:
        'Key business logic decisions include streamlining the checkout process for faster purchases, offering immediate order confirmations post-purchase, and allowing users a brief window to modify or cancel orders.',
      dataChanges: {
        changeDetails:
          'Introduction of storing payment and shipping information in user profiles requires handling of Personally Identifiable Information (PII) data securely to prevent unauthorized access or misuse.',
        typeOfChange: 'New data stored',
        typeOfData: 'PII',
      },
      // designDecisions:
      //   'Significant design decisions include automatic retrieval of payment and shipping information from user profiles, immediate order confirmation emails, and allowing users a 5-minute window for order modifications.',
      infrastructure: {
        changeDetails:
          'The introduction of the One-Click Checkout feature requires the development of new infrastructure components to support the one-click checkout button, user profile integration, and updating of payment and shipping systems. This is a major change that necessitates a security review.',
        changeType: 'New',
        isMajorChange: true,
        technology: '',
      },
    },
    lastModified: '2024-05-02T13:21:45.528Z',
    originUrl:
      'https://docs.google.com/document/d/1F_-M5QTQ9iEAZzy_DJM7_8jtUEdNu8NKfrtk1ktaUI4/edit?usp=drivesdk',
    priority: 'High',
    scoreBreakdown: {
      documentDepth: 8,
      riskScore: 7,
      significance: 9,
    },
    shortenedTitle: 'Introduction of One-Click Checkout Feature',
    summary:
      '\n## One-Click Checkout Summary\n\n**Objective:** Introducing a One-Click Checkout feature to streamline purchases, reduce cart abandonment, and enhance user satisfaction by simplifying the checkout process.  \n**Scope:** Development of the one-click checkout button, user profile integration, and updating payment/shipping systems.  \n**Requirements:** Includes user profile integration, immediate order confirmation, order modification window, and strict performance and scalability benchmarks.  \n**Security Considerations:** Emphasize device security and user session control to mitigate risks of unauthorized purchases.  \n**Timeline:** Phased implementation over 4 months: Design & Prototyping, Development & Testing, Deployment & Monitoring.  \n**Risks:** Technical challenges in database integration, and potential customer security concerns with the one-click feature.  \n**Metrics:** Monitoring checkout time reduction and conversion rate increase using real-time analytics.',
    title: 'One Click Checkout',
  },
];
