export const cleanMarkdown = (markdownText: string, dynamicPatterns: string[]): string => {
  // Escape special regex characters in all dynamic patterns
  const escapedPatterns = dynamicPatterns.map((p) => p.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
  const patternKeywords = escapedPatterns.join('|');

  // Create a regex pattern that matches the specified keywords potentially wrapped in markdown formatting (bold, italic, headers)
  // and may include additional text up to a colon, focusing only at the beginning of the text
  const pattern = new RegExp(
    `^\\s*(?:#{1,5}\\s*)?(?:\\*\\*|__)?(?:${patternKeywords})(?:\\*\\*|__)?(?:.*?:)?\\s*`,
    'i'
  );

  // Apply the regex to remove the matched text at the beginning of the markdown
  const cleanedText = markdownText.replace(pattern, '').trim();

  return cleanedText;
};
