// 3rd
import { manyOf, primaryKey } from '@mswjs/data';

export const frameworksModels = {
  framework: {
    id: primaryKey(String),
    name: String,
    frameworkDescription: String,
    activityStatus: String,
    securityControls: manyOf('frameworkControl'),
  },

  frameworkControl: {
    id: primaryKey(String),
    controlGroup: String,
    controlName: String,
    description: String,
    importanceScore: String,
    securityRequirements: manyOf('requirement'),
  },

  requirement: {
    id: primaryKey(String),
    categoryName: String,
    content: String,
    description: String,
    requirementIdNumber: String,
    severity: String,
    activityStatus: String,
    countermeasures: manyOf('countermeasure'),
  },

  countermeasure: {
    id: primaryKey(String),
    content: String,
    title: String,
    countermeasureSource: String,
    countermeasureSnippetType: String,
    securityRequirementsIds: Array,
  },
};
