// App - Other
import { accessibilityHandlers } from './accessibility';
import { integrationsHandlers } from './integrations';
import { collectionsHandlers } from './collections';
import { frameworksHandlers } from './frameworks';
//
import { applicationsHandlers } from './applications';
import { feedbackHandlers } from './feedback';
// import { storiesHandlers } from './stories';
// import { knowledgeItemsHandlers } from './knowledge-items';

export const handlers = [
  ...accessibilityHandlers,
  ...integrationsHandlers,
  ...collectionsHandlers,
  ...frameworksHandlers,
  //
  ...applicationsHandlers,
  ...feedbackHandlers,
  //
  // ...knowledgeItemsHandlers,
  // ...storiesHandlers,
];
