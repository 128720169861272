'use client';

// React & Next
import type { ReactNode } from 'react';

// 3rd
import { ChakraProvider as ChakraProviderInternal, GlobalStyle } from '@chakra-ui/react';
import { CacheProvider } from '@chakra-ui/next-js';

// App - Other
import { theme } from '@/config/theme';

type ChakraProviderProps = {
  children: ReactNode;
};

export const ChakraProvider = ({ children }: ChakraProviderProps) => {
  return (
    <CacheProvider>
      <ChakraProviderInternal theme={theme}>
        <GlobalStyle />
        {children}
      </ChakraProviderInternal>
    </CacheProvider>
  );
};
