// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { CreateTicketFeedback } from '../types/feedback';

// App - Other
import { apiClient } from '@/config/lib/api-client';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  applicationId: z.string(),
  securityStoryId: z.string(),
  securityRequirementIds: z.string().array(),
  securityStoryThreatIds: z.string().array(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodCreateTicketRequestPayloadDto };
export type { RequestPayloadDto as CreateTicketRequestPayloadDto };

// #######
// Request
// #######

export const createTicket = async (
  applicationId: string,
  storyId: string,
  ticketPayload: CreateTicketFeedback
): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      applicationId,
      securityStoryId: storyId,
      securityRequirementIds: ticketPayload.requirementIds || [],
      securityStoryThreatIds: ticketPayload.threatIds || [],
    });

    return await apiClient.post(`/Application/CreateJiraIssue`, payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCreateTicket = {
  applicationId: string;
  storyId: string;
  onCreateStart?: () => void;
  onCreateSuccess?: () => void;
  onCreateFailed?: (error: Error) => void;
};

export const useCreateTicket = ({
  applicationId,
  storyId,
  onCreateStart,
  onCreateSuccess,
  onCreateFailed,
}: UseCreateTicket) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (payload: CreateTicketFeedback) =>
      await createTicket(applicationId, storyId, payload),
    onMutate: () => onCreateStart?.(),
    onSuccess: () => onCreateSuccess?.(),
    onError: (error) => onCreateFailed?.(error),
  });

  return {
    createTicket: mutate,
    isCreatingTicket: isPending,
    didCreateTicketErrored: isError,
  };
};
