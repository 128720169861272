'use client';

// React & Next
import type { ReactNode } from 'react';

// 3rd
import { ErrorBoundary } from 'react-error-boundary';

// App - Other
import Error from '../app/error';

type ErrorBoundaryProviderProps = {
  children: ReactNode;
};

export const ErrorBoundaryProvider = ({ children }: ErrorBoundaryProviderProps) => {
  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => (
        <Error error={error} reset={resetErrorBoundary} />
      )}
      onError={console.error}
    >
      {children}
    </ErrorBoundary>
  );
};
