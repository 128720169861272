// App - Types
import type { KnowledgeSource } from '@/types/integration/knowledge-source';

export const translateKnowledgeSourceToColor = (
  source: KnowledgeSource,
  treatGoogleDriveAsOneDrive?: boolean
): string => {
  if (source === 'confluence') return '#0055CC';

  if (source === 'google_drive') {
    if (treatGoogleDriveAsOneDrive) {
      return '#28A8EA'; // OneDrive Blue
    } else {
      return '#34A853'; // Google Drive Green
    }
  }

  if (source === 'jira') return '#388BFF';

  if (source === 'trello') return '#298FCA';

  if (source === 'linear') return '#5E6AD2';

  if (source === 'github') return '#24292E';

  if (source === 'one_drive') return '#28A8EA';

  if (source === 'notion') return '#000000';

  if (source === 'gitlab') return '#FCA121';

  if (source === 'azure_devops') return '#1F88DC';

  if (source === 'azure_devops_wiki_page') return '#1F88DC';

  if (source === 'azure_devops_work_item') return '#00947D';

  if (source === 'azure_devops_repository') return '#E15815';

  return 'transparent';
};
