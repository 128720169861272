// 3rd
import { factory } from '@mswjs/data';

// App - Other
import {
  integrationsModels,
  knowledgeSourceConfigurationsMockData,
  slackIntegrationsMockData,
} from './integrations';
import { collectionModels, collectionMockData } from './collections';
import { applicationsModels, applicationsMockData } from './applications';
import { knowledgeItemsModels, knowledgeItemsMockData } from './knowledge-items';
import { feedbackModels, securityFeedbackMockData } from './feedback';
import { frameworksModels, frameworksMockData } from './frameworks';
import {
  storiesModels,
  // relatedKnowledgeItemsMockData,
  // securityStoriesFrameworkRequirementsAssessmentsMockData,
  // securityStoriesMockData,
  // securityStoriesSecurityAssessmentsMockData,
} from './stories';

const models = {
  ...integrationsModels,
  ...collectionModels,
  ...knowledgeItemsModels,
  ...feedbackModels,
  ...frameworksModels,
  ...storiesModels,
  ...applicationsModels,
};

export const db = factory(models);

export const seedDb = () => {
  const collectionCount = db.collection.count();

  if (collectionCount > 0) return;

  // ############
  // Integrations
  // ############
  knowledgeSourceConfigurationsMockData.forEach((integration) =>
    db.knowledgeSourceConfiguration.create(integration)
  );

  slackIntegrationsMockData.forEach((integration) => db.slackIntegration.create(integration));

  // ############
  // Applications
  // ############
  applicationsMockData.forEach((application) => db.application.create(application));

  // ##########
  // Frameworks
  // ##########
  frameworksMockData.forEach((framework) => {
    const controls = framework.securityControls.map((control) => {
      const requirements = control.securityRequirements.map((requirement) => {
        const countermeasures = (requirement.countermeasures || []).map((countermeasure) =>
          db.countermeasure.create(countermeasure)
        );

        return db.requirement.create({
          ...requirement,
          countermeasures,
        });
      });

      return db.frameworkControl.create({
        ...control,
        securityRequirements: requirements,
      });
    });

    db.framework.create({
      ...framework,
      securityControls: controls,
    });
  });

  // #######
  // Stories
  // #######
  // securityStoriesMockData.forEach((story) => db.securityStory.create(story));
  // relatedKnowledgeItemsMockData.forEach((relatedKnowledgeItem) =>
  //   db.relatedKnowledgeItem.create(relatedKnowledgeItem)
  // );
  // securityStoriesSecurityAssessmentsMockData.forEach((assessment) =>
  //   db.securityStorySecurityAssessment.create(assessment)
  // );
  // securityStoriesFrameworkRequirementsAssessmentsMockData.forEach((requirements) =>
  //   db.securityStoryFrameworkRequirementsAssessment.create(requirements)
  // );

  // ###########
  // Collections
  // ###########
  collectionMockData.forEach((collection) => db.collection.create(collection));

  // ##############
  // KnowledgeItems
  // ##############
  knowledgeItemsMockData.forEach((knowledgeItem) => db.knowledgeItem.create(knowledgeItem));

  // #################
  // Security Feedback
  // #################
  securityFeedbackMockData.forEach((feedback) => db.securityFeedback.create(feedback));
};
