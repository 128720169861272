// App - Types
import type { Feedback } from '../../types/feedback';

// App - Other
import { Markdown } from '@/components/organisms/markdown';
import {
  AzureDevOpsWikiPageMarkdownFlavor,
  AzureDevOpsWorkItemMarkdownFlavor,
  ConfluenceMarkdownFlavor,
  JiraMarkdownFlavor,
  GoogleDriveMarkdownFlavor,
} from './flavors';

type FeedbackMarkdownProps = {
  feedback: Feedback;
};

export const FeedbackMarkdown = ({ feedback }: FeedbackMarkdownProps) => {
  if (feedback.knowledgeItemId.source === 'azure_devops_wiki_page') {
    return (
      <AzureDevOpsWikiPageMarkdownFlavor>{feedback.content}</AzureDevOpsWikiPageMarkdownFlavor>
    );
  }

  if (feedback.knowledgeItemId.source === 'azure_devops_work_item') {
    return (
      <AzureDevOpsWorkItemMarkdownFlavor>{feedback.content}</AzureDevOpsWorkItemMarkdownFlavor>
    );
  }

  if (feedback.knowledgeItemId.source === 'confluence') {
    return <ConfluenceMarkdownFlavor>{feedback.content}</ConfluenceMarkdownFlavor>;
  }

  if (feedback.knowledgeItemId.source === 'jira') {
    return <JiraMarkdownFlavor>{feedback.content}</JiraMarkdownFlavor>;
  }

  if (feedback.knowledgeItemId.source === 'google_drive') {
    return <GoogleDriveMarkdownFlavor>{feedback.content}</GoogleDriveMarkdownFlavor>;
  }

  return (
    <Markdown direction="column" alignSelf="stretch" gap="xxs" w="100%">
      {feedback.content}
    </Markdown>
  );
};
