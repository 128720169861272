// 3rd
import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

export const paragraph = defineStyle({
  fontSize: 'lg',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 'short',

  '& .chakra-icon': {
    w: '12px',
    h: '12px',
  },
});

export const label = defineStyle({
  fontSize: 'md',
  fontStyle: 'normal',
  fontWeight: 'medium',
  lineHeight: 'short',

  '& .chakra-icon': {
    w: '10px',
    h: '10px',
  },
});

export const labelSB = defineStyle({
  fontSize: 'md',
  fontStyle: 'normal',
  fontWeight: 'semibold',
  lineHeight: 'short',

  '& .chakra-icon': {
    w: '10px',
    h: '10px',
  },
});

export const body = defineStyle({
  fontSize: 'md',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 'short',

  '& .chakra-icon': {
    w: '10px',
    h: '10px',
  },
});

export const detail = defineStyle({
  fontSize: 'sm',
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: 'shorter',

  '& .chakra-icon': {
    w: '10px',
    h: '10px',
  },
});

export const detailSB = defineStyle({
  fontSize: 'sm',
  fontStyle: 'normal',
  fontWeight: 'semibold',
  lineHeight: 'shorter',

  '& .chakra-icon': {
    w: '10px',
    h: '10px',
  },
});

export const small = defineStyle({
  fontSize: 'xs',
  fontStyle: 'normal',
  fontWeight: 'medium',
  lineHeight: 'minimal',

  '& .chakra-icon': {
    w: '8px',
    h: '8px',
  },
});

export const tiny = defineStyle({
  fontSize: 'xxs',
  fontStyle: 'normal',
  fontWeight: 'medium',
  lineHeight: 'minimal',

  '& .chakra-icon': {
    w: '6px',
    h: '6px',
  },
});

export const textTheme = defineStyleConfig({
  variants: {
    paragraph,
    p: paragraph,
    label,
    'label-sb': labelSB,
    'label-semibold': labelSB,
    body,
    detail,
    'detail-sb': detailSB,
    'detail-semibold': detailSB,
    small,
    tiny,
  },
  defaultProps: {
    variant: 'body',
  },
});

export const Text = textTheme;
