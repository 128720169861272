// 3rd
import { delay, http } from 'msw';
import { DateTime } from 'luxon';

// App - Types
import type { CreateAzureDevOpsIntegrationRequestPayloadDto } from '@/features/integrations';
import { ZodCreateAzureDevOpsIntegrationRequestPayloadDto } from '@/features/integrations';

// App - Other
import { API_URL } from '@/config/constants';
import { uuid4 } from '@/utils/generators';
import { db } from '../../db';

export const insertAzureDevOpsIntegrationHandler = http.post(
  `${API_URL}/Integration/InsertAzureDevOpsIntegration`,
  async ({ request }) => {
    const payload = await request.json();
    const parsedPayload: CreateAzureDevOpsIntegrationRequestPayloadDto =
      ZodCreateAzureDevOpsIntegrationRequestPayloadDto.parse(payload);

    db.knowledgeSourceConfiguration.create({
      id: uuid4(),
      baseUrl: parsedPayload.baseUrl,
      knowledgeSourceType: 'AzureDevOpsWikiPage',
      lastScraped: DateTime.now().toUTC().toString(),
      userEmail: 'liav@clover.security',
    });

    db.knowledgeSourceConfiguration.create({
      id: uuid4(),
      baseUrl: parsedPayload.baseUrl,
      knowledgeSourceType: 'AzureDevOpsWorkItem',
      lastScraped: DateTime.now().toUTC().toString(),
      userEmail: 'liav@clover.security',
    });

    await delay(500);

    return new Response(null, {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
