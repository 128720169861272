'use client';

// React & Next
import { useEffect, useMemo } from 'react';
import type { ReactNode } from 'react';

// 3rd
import { useAuth } from '@frontegg/nextjs';

// App - Other
import { apiClient } from '@/config/lib/api-client';

type AxiosProviderProps = {
  children: ReactNode;
};

export const AxiosProvider = ({ children }: AxiosProviderProps) => {
  const { user } = useAuth();

  const requestInterceptor = useMemo(() => {
    return apiClient.interceptors.request.use(
      (config) => {
        if (!config.headers['Authorization'] && user && user.accessToken) {
          config.headers['Authorization'] = `Bearer ${user.accessToken}`;
        }

        return config;
      },
      (error) => Promise.reject(error)
    );
  }, [user]);

  const responseInterceptor = useMemo(() => {
    return apiClient.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;

        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          prevRequest.headers['Authorization'] = `Bearer ${user?.accessToken}`;

          return apiClient(prevRequest);
        }

        return Promise.reject(error);
      }
    );
  }, [user]);

  useEffect(() => {
    return () => {
      apiClient.interceptors.request.eject(requestInterceptor);
      apiClient.interceptors.response.eject(responseInterceptor);
    };
  }, [requestInterceptor, responseInterceptor]);

  return <>{children}</>;
};
