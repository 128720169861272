// 3rd
import { primaryKey } from '@mswjs/data';

export const storiesModels = {
  securityStory: {
    creationTime: String,
    id: primaryKey(String),
    lastModified: String,
    relatedKnowledgeItemIds: Array,
    seedItemId: Object,
    status: String,
    storyName: String,
  },
  relatedKnowledgeItem: {
    storyId: String,
    id: primaryKey(String),
    distance: Number,
    knowledgeItemId: Object,
    knowledgeItemTitle: String,
  },
  securityStorySecurityAssessment: {
    storyId: String,
    id: primaryKey(String),
    creationTime: String,
    securityAssessment: Object,
  },
  securityStoryFrameworkRequirementsAssessment: {
    storyId: String,
    id: primaryKey(String),
    creationTime: String,
    securityFrameworkRequirements: Array,
  },
};
