'use client';

// React & Next
import type { ReactNode } from 'react';

// 3rd
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// App - Other
import { queryClient } from '@/config/lib/react-query';
import { IS_DEVELOPMENT } from '@/config/constants';

type ReactQueryProviderProps = {
  children: ReactNode;
};

export const ReactQueryProvider = ({ children }: ReactQueryProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      {IS_DEVELOPMENT && <ReactQueryDevtools initialIsOpen={false} />}

      {children}
    </QueryClientProvider>
  );
};
