import { useRef, useEffect } from 'react';

export const usePreviousProps = <T>(value: T) => {
  const ref = useRef<T | object>({});

  useEffect(() => {
    ref.current = value;
  });

  return ref.current as Partial<T>;
};
