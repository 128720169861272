/* eslint-disable @typescript-eslint/no-unused-vars */
// 3rd
import { delay, http } from 'msw';

// App - Types
import type {
  RequirementDto,
  UpdateRequirementRequestPayloadDto,
} from '@/features/security-frameworks';
import {
  ZodRequirementDto,
  ZodUpdateRequirementRequestPayloadDto,
} from '@/features/security-frameworks';

// App - Other
import { API_URL } from '@/config/constants';
import { db } from '../../db';

export const updateRequirementHandler = http.post(
  `${API_URL}/SecurityFramework/UpdateSecurityRequirement`,
  async ({ request }) => {
    const payload = await request.json();
    const parsedPayload: UpdateRequirementRequestPayloadDto =
      ZodUpdateRequirementRequestPayloadDto.parse(payload);

    const requirement = db.requirement.findFirst({
      where: {
        id: {
          equals: parsedPayload.requirementId,
        },
      },
    });
    const parsedRequirement: RequirementDto = ZodRequirementDto.parse(requirement);

    if (!parsedRequirement) {
      return new Response(null, {
        status: 404,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }

    const updatedRequirement = db.requirement.update({
      where: {
        id: {
          equals: parsedPayload.requirementId,
        },
      },
      data: {
        activityStatus: parsedPayload.activityStatus,
      },
    });
    const parsedUpdatedRequirement: RequirementDto = ZodRequirementDto.parse(updatedRequirement);

    await delay(1000);

    return new Response(null, {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
