// 3rd
import { delay, http } from 'msw';

// App - Types
import { ZodSendCommentFeedbackRequestPayloadDto } from '@/features/feedback';
import type {
  SendCommentFeedbackRequestPayloadDto,
  SendCommentFeedbackResponseDto,
} from '@/features/feedback';

// App - Other
import { API_URL } from '@/config/constants';
import { uuid4 } from '@/utils/generators';
import { db } from '../../db';

export const createSecurityFeedbackHandler = http.post(
  `${API_URL}/SecurityFeedback/CreateSecurityFeedback`,
  async ({ request }) => {
    const payload = (await request.json()) as SendCommentFeedbackRequestPayloadDto;
    const parsedPayload = ZodSendCommentFeedbackRequestPayloadDto.parse(payload);

    await delay(500);

    const newFeedback = db.securityFeedback.create({
      id: uuid4(),
      knowledgeItemId: {
        knowledgeItemType: parsedPayload.knowledgeItemType,
        collectionId: parsedPayload.collectionId,
        documentId: parsedPayload.documentId,
      },
      content: '',
      lastModified: new Date().toISOString(),
    });

    const res: SendCommentFeedbackResponseDto = { securityFeedbackId: newFeedback.id };

    return new Response(JSON.stringify(res), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
