// 3rd
import { primaryKey } from '@mswjs/data';

export const integrationsModels = {
  knowledgeSourceConfiguration: {
    id: primaryKey(String),
    baseUrl: String,
    knowledgeSourceType: String,
    lastScraped: String,
    userEmail: String,
  },
  slackIntegration: {
    id: primaryKey(String),
    integrationName: String,
    integrationType: String,
  },
};
