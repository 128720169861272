'use client';

// React & Next
import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';

// 3rd
import { useAuth, useTenantsState } from '@frontegg/nextjs';

// App - Other
import { ENVIRONMENT } from '@/config/constants';

type DatadogProviderProps = {
  children: ReactNode;
};

export const DatadogProvider = ({ children }: DatadogProviderProps) => {
  const { tenants } = useTenantsState();
  const { user } = useAuth();
  const [isInitialized, setIsInitialized] = useState<boolean>();

  useEffect(() => {
    if (!isInitialized && ENVIRONMENT !== 'local') {
      const { initializeDatadog } = require('@/config/lib/datadog');

      initializeDatadog();
      setIsInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInitialized && user && tenants && tenants.length > 0) {
      const { setUser } = require('@/config/lib/datadog');
      const currentTenant = tenants.find((tenant) => tenant.tenantId === user.tenantId);

      setUser({
        id: user.id,
        name: user.name,
        email: user.email,
        tenantId: user.tenantId,
        tenantName: currentTenant?.name || '_UNKNOWN_',
      });
    }
  }, [isInitialized, tenants, user]);

  return <>{children}</>;
};
