// 3rd
import { delay, http } from 'msw';
import { DateTime } from 'luxon';

// App - Types
import type { CreateGoogleDriveServiceAccountIntegrationRequestPayloadDto } from '@/features/integrations';
import { ZodCreateGoogleDriveServiceAccountIntegrationRequestPayloadDto } from '@/features/integrations';

// App - Other
import { API_URL } from '@/config/constants';
import { randomInt, uuid4 } from '@/utils/generators';
import { db } from '../../db';

export const insertGoogleDriveServiceAccountIntegrationHandler = http.post(
  `${API_URL}/Integration/InsertGoogleDriveServiceAccountIntegration`,
  async ({ request }) => {
    const payload = await request.json();
    const parsedPayload: CreateGoogleDriveServiceAccountIntegrationRequestPayloadDto =
      ZodCreateGoogleDriveServiceAccountIntegrationRequestPayloadDto.parse(payload);

    db.knowledgeSourceConfiguration.create({
      id: uuid4(),
      baseUrl: `cloverserviceaccount@cloverproject-${randomInt(100000, 999999)}.iam.gserviceaccount.com`,
      knowledgeSourceType: 'GoogleDrive',
      lastScraped: DateTime.now().toUTC().toString(),
      userEmail: parsedPayload.displayName,
    });

    await delay(500);

    return new Response(null, {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
