// App - Types
import type { AntiPatternType } from '../anti-pattern-type';

export const AntiPatternTypesDto = [
  'Architecture',
  'Code',
  'Configuration',
  'Documentation',
  'UNKNOWN',
] as const;

export type AntiPatternTypeDto = (typeof AntiPatternTypesDto)[number];

export const castAntiPatternTypeDtoToAntiPatternType = (
  dto: AntiPatternTypeDto
): AntiPatternType => {
  if (dto === 'Architecture') return 'architecture';

  if (dto === 'Code') return 'code';

  if (dto === 'Configuration') return 'configuration';

  if (dto === 'Documentation') return 'documentation';

  return 'UNKNOWN';
};

export const castAntiPatternTypeToAntiPatternTypeDto = (
  antiPatternType: AntiPatternType
): AntiPatternTypeDto => {
  if (antiPatternType === 'architecture') return 'Architecture';

  if (antiPatternType === 'code') return 'Code';

  if (antiPatternType === 'configuration') return 'Configuration';

  if (antiPatternType === 'documentation') return 'Documentation';

  return 'UNKNOWN';
};
