// 3rd
import { delay, http } from 'msw';

// App - Types
import type { GetIntegrationsResponseDto, IntegrationDto } from '@/features/integrations';
import { ZodGetIntegrationsResponseDto, ZodIntegrationDto } from '@/features/integrations';

// App - Other
import { API_URL } from '@/config/constants';
import { db } from '../../db';

export const getListIntegrationsHandler = http.get(
  `${API_URL}/Integration/ListIntegrations`,
  async () => {
    const integrations = db.knowledgeSourceConfiguration.getAll();
    const parsedIntegrations: IntegrationDto[] = integrations.map((integration) =>
      ZodIntegrationDto.parse(integration)
    );

    await delay(500);

    const res: GetIntegrationsResponseDto = { integrations: parsedIntegrations };
    const parsedRes: GetIntegrationsResponseDto = ZodGetIntegrationsResponseDto.parse(res);

    return new Response(JSON.stringify(parsedRes), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
