// App - Types
import type { Priority } from '@/types/knowledge-item/priority';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators['knowledge-item'].priority;

export const translatePriority = (priority: Priority): string => {
  if (priority === 'high') return locale['High'];

  if (priority === 'medium') return locale['Medium'];

  if (priority === 'low') return locale['Low'];

  return locale['Unknown'];
};

export const translatePriorityToColor = (priority: Priority): string => {
  if (priority === 'high') return '#EC8C7E';

  if (priority === 'medium') return '#FFCA45';

  if (priority === 'low') return '#82B8FE';

  return '#D7E1EE';
};

export const translatePriorityToBgColor = (priority: Priority): string => {
  if (priority === 'high') return '#FDE2E2';

  if (priority === 'medium') return '#FDFAE2';

  if (priority === 'low') return '#E2F0FD';

  return '#D7E1EE';
};
