// App - Types
import type { CollectionDto } from '@/features/collections';

export const collectionMockData: CollectionDto[] = [
  {
    collectionId: 'guy@clover.security',
    collectionTitle: 'guy@clover.security',
    id: '042c7d6a-1a55-4e21-99a9-1105c2aca6df-guy@clover.security',
    knowledgeSourceConfigurationId: '042c7d6a-1a55-4e21-99a9-1105c2aca6df',
    knowledgeSourceType: 'GoogleDrive',
  },
  {
    collectionId: '10001',
    collectionTitle: 'Engineering Backlog',
    id: '7bf60e99-7956-4316-b70d-a9b12b769984-10001',
    knowledgeSourceConfigurationId: '7bf60e99-7956-4316-b70d-a9b12b769984',
    knowledgeSourceType: 'Jira',
  },
  {
    collectionId: '66393654393da94c6e84fd4a',
    collectionTitle: 'Tar Wolfson New Hire',
    id: '773c39b8-baae-4aa2-91d9-9514693747ca-66393654393da94c6e84fd4a',
    knowledgeSourceConfigurationId: '773c39b8-baae-4aa2-91d9-9514693747ca',
    knowledgeSourceType: 'Trello',
  },
  {
    collectionId: '6639366e67944fb144d1afce',
    collectionTitle: 'Tar Wolfson Onboarding',
    id: '773c39b8-baae-4aa2-91d9-9514693747ca-6639366e67944fb144d1afce',
    knowledgeSourceConfigurationId: '773c39b8-baae-4aa2-91d9-9514693747ca',
    knowledgeSourceType: 'Trello',
  },
  {
    collectionId: '425986',
    collectionTitle: 'ByteBasket - Ecommerce Platform',
    id: '336a3e78-7db3-4de1-b0d1-a718f6112807-425986',
    knowledgeSourceConfigurationId: '336a3e78-7db3-4de1-b0d1-a718f6112807',
    knowledgeSourceType: 'Confluence',
  },
  {
    collectionId: 'eylon@cloversec.io',
    collectionTitle: 'eylon@cloversec.io',
    id: '042c7d6a-1a55-4e21-99a9-1105c2aca6df-eylon@cloversec.io',
    knowledgeSourceConfigurationId: '042c7d6a-1a55-4e21-99a9-1105c2aca6df',
    knowledgeSourceType: 'GoogleDrive',
  },
  {
    collectionId: '10013',
    collectionTitle: 'Playground',
    id: '7bf60e99-7956-4316-b70d-a9b12b769984-10013',
    knowledgeSourceConfigurationId: '7bf60e99-7956-4316-b70d-a9b12b769984',
    knowledgeSourceType: 'Jira',
  },
  {
    collectionId: '0AE0ATcHcFUYCUk9PVA',
    collectionTitle: 'Hiring',
    id: '042c7d6a-1a55-4e21-99a9-1105c2aca6df-0AE0ATcHcFUYCUk9PVA',
    knowledgeSourceConfigurationId: '042c7d6a-1a55-4e21-99a9-1105c2aca6df',
    knowledgeSourceType: 'GoogleDrive',
  },
  {
    collectionId: '0AFyuHRDXD0O9Uk9PVA',
    collectionTitle: 'Customers',
    id: '042c7d6a-1a55-4e21-99a9-1105c2aca6df-0AFyuHRDXD0O9Uk9PVA',
    knowledgeSourceConfigurationId: '042c7d6a-1a55-4e21-99a9-1105c2aca6df',
    knowledgeSourceType: 'GoogleDrive',
  },
  {
    collectionId: '0AMPYNilDPzxsUk9PVA',
    collectionTitle: 'Marketing',
    id: '042c7d6a-1a55-4e21-99a9-1105c2aca6df-0AMPYNilDPzxsUk9PVA',
    knowledgeSourceConfigurationId: '042c7d6a-1a55-4e21-99a9-1105c2aca6df',
    knowledgeSourceType: 'GoogleDrive',
  },
  {
    collectionId: '9830404',
    collectionTitle: 'Greenfield - Talent Management Platform',
    id: '336a3e78-7db3-4de1-b0d1-a718f6112807-9830404',
    knowledgeSourceConfigurationId: '336a3e78-7db3-4de1-b0d1-a718f6112807',
    knowledgeSourceType: 'Confluence',
  },
  {
    collectionId: '10014',
    collectionTitle: 'Migration',
    id: '7bf60e99-7956-4316-b70d-a9b12b769984-10014',
    knowledgeSourceConfigurationId: '7bf60e99-7956-4316-b70d-a9b12b769984',
    knowledgeSourceType: 'Jira',
  },
  {
    collectionId: '0AOT4ef4sCoZYUk9PVA',
    collectionTitle: 'Product',
    id: '042c7d6a-1a55-4e21-99a9-1105c2aca6df-0AOT4ef4sCoZYUk9PVA',
    knowledgeSourceConfigurationId: '042c7d6a-1a55-4e21-99a9-1105c2aca6df',
    knowledgeSourceType: 'GoogleDrive',
  },
  {
    collectionId: '0AP0blhw4Wb7DUk9PVA',
    collectionTitle: 'Clover',
    id: '042c7d6a-1a55-4e21-99a9-1105c2aca6df-0AP0blhw4Wb7DUk9PVA',
    knowledgeSourceConfigurationId: '042c7d6a-1a55-4e21-99a9-1105c2aca6df',
    knowledgeSourceType: 'GoogleDrive',
  },
  {
    collectionId: '10020',
    collectionTitle: 'MCU-Driver-HAL',
    id: '7bf60e99-7956-4316-b70d-a9b12b769984-10020',
    knowledgeSourceConfigurationId: '7bf60e99-7956-4316-b70d-a9b12b769984',
    knowledgeSourceType: 'Jira',
  },
  {
    collectionId: '65393654393da94c6e84fd4a',
    collectionTitle: 'Tar Wolfson New Hire',
    id: '0e266f99-3433-4ae0-b03d-dcdfe6b3b74f-65393654393da94c6e84fd4a',
    knowledgeSourceConfigurationId: '0e266f99-3433-4ae0-b03d-dcdfe6b3b74f',
    knowledgeSourceType: 'Trello',
  },
  {
    collectionId: '10048',
    collectionTitle: 'Upstream Maintainership',
    id: '7bf60e99-7956-4316-b70d-a9b12b769984-10048',
    knowledgeSourceConfigurationId: '7bf60e99-7956-4316-b70d-a9b12b769984',
    knowledgeSourceType: 'Jira',
  },
  {
    collectionId: '10005',
    collectionTitle: 'R&D Hiring',
    id: '7bf60e99-7956-4316-b70d-a9b12b769984-10005',
    knowledgeSourceConfigurationId: '7bf60e99-7956-4316-b70d-a9b12b769984',
    knowledgeSourceType: 'Jira',
  },
  {
    collectionId: '10017',
    collectionTitle: 'WebApp Backlog',
    id: '7bf60e99-7956-4316-b70d-a9b12b769984-10017',
    knowledgeSourceConfigurationId: '7bf60e99-7956-4316-b70d-a9b12b769984',
    knowledgeSourceType: 'Jira',
  },
  {
    collectionId: '65b14aea928dcb50c198c8b3',
    collectionTitle: 'Operations',
    id: '773c39b8-baae-4aa2-91d9-9514693747ca-65b14aea928dcb50c198c8b3',
    knowledgeSourceConfigurationId: '773c39b8-baae-4aa2-91d9-9514693747ca',
    knowledgeSourceType: 'Trello',
  },
  {
    collectionId: '6628d2498a3413c94b4e0a46',
    collectionTitle: 'Sapir Gabizon New Hire',
    id: '773c39b8-baae-4aa2-91d9-9514693747ca-6628d2498a3413c94b4e0a46',
    knowledgeSourceConfigurationId: '773c39b8-baae-4aa2-91d9-9514693747ca',
    knowledgeSourceType: 'Trello',
  },
  {
    collectionId: '5b543ed9d8215c19168f698a',
    collectionTitle: 'Personal Board',
    id: '773c39b8-baae-4aa2-91d9-9514693747ca-5b543ed9d8215c19168f698a',
    knowledgeSourceConfigurationId: '773c39b8-baae-4aa2-91d9-9514693747ca',
    knowledgeSourceType: 'Trello',
  },
  {
    collectionId: '10007',
    collectionTitle: 'Clover MVP',
    id: '7bf60e99-7956-4316-b70d-a9b12b769984-10007',
    knowledgeSourceConfigurationId: '7bf60e99-7956-4316-b70d-a9b12b769984',
    knowledgeSourceType: 'Jira',
  },
  {
    collectionId: '10015',
    collectionTitle: 'Product Designer Hiring',
    id: '7bf60e99-7956-4316-b70d-a9b12b769984-10015',
    knowledgeSourceConfigurationId: '7bf60e99-7956-4316-b70d-a9b12b769984',
    knowledgeSourceType: 'Jira',
  },
  {
    collectionId: '757927268',
    collectionTitle: 'Leaf',
    id: '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security-757927268',
    knowledgeSourceConfigurationId:
      '599382b4-29a4-478a-b9cb-fe31c0541262-Github-https://github.com/clover-security',
    knowledgeSourceType: 'Github',
  },
  {
    collectionId: '10052',
    collectionTitle: 'Arm LLVM Toolchain Enablement and CI',
    id: '7bf60e99-7956-4316-b70d-a9b12b769984-10052',
    knowledgeSourceConfigurationId: '7bf60e99-7956-4316-b70d-a9b12b769984',
    knowledgeSourceType: 'Jira',
  },
  {
    collectionId: '10093',
    collectionTitle: 'Heterogeneous Platform',
    id: '7bf60e99-7956-4316-b70d-a9b12b769984-10093',
    knowledgeSourceConfigurationId: '7bf60e99-7956-4316-b70d-a9b12b769984',
    knowledgeSourceType: 'Jira',
  },
  {
    collectionId: '5b643ed9d8215c19168f698a',
    collectionTitle: 'Personal Board',
    id: '0e266f99-3433-4ae0-b03d-dcdfe6b3b74f-5b643ed9d8215c19168f698a',
    knowledgeSourceConfigurationId: '0e266f99-3433-4ae0-b03d-dcdfe6b3b74f',
    knowledgeSourceType: 'Trello',
  },
  {
    collectionId: '65b257d5aa6cbed8171df84b',
    collectionTitle: 'Accounts Payable Tracker',
    id: '0e266f99-3433-4ae0-b03d-dcdfe6b3b74f-65b257d5aa6cbed8171df84b',
    knowledgeSourceConfigurationId: '0e266f99-3433-4ae0-b03d-dcdfe6b3b74f',
    knowledgeSourceType: 'Trello',
  },
  {
    collectionId: '65eec0b577c318cfd1dbcf7d',
    collectionTitle: 'Yuval Rosenthal New Hire',
    id: '773c39b8-baae-4aa2-91d9-9514693747ca-65eec0b577c318cfd1dbcf7d',
    knowledgeSourceConfigurationId: '773c39b8-baae-4aa2-91d9-9514693747ca',
    knowledgeSourceType: 'Trello',
  },
  {
    collectionId: '65f71f8f1dbe0433ddf5bda6',
    collectionTitle: 'Yuval Rosenthal Onboarding',
    id: '773c39b8-baae-4aa2-91d9-9514693747ca-65f71f8f1dbe0433ddf5bda6',
    knowledgeSourceConfigurationId: '773c39b8-baae-4aa2-91d9-9514693747ca',
    knowledgeSourceType: 'Trello',
  },
  {
    collectionId: '6628d2344fcf74cb69e9074d',
    collectionTitle: 'Sapir Gabizon Onboarding',
    id: '773c39b8-baae-4aa2-91d9-9514693747ca-6628d2344fcf74cb69e9074d',
    knowledgeSourceConfigurationId: '773c39b8-baae-4aa2-91d9-9514693747ca',
    knowledgeSourceType: 'Trello',
  },
  {
    collectionId: '10071',
    collectionTitle: 'Trusted Substrate',
    id: '7bf60e99-7956-4316-b70d-a9b12b769984-10071',
    knowledgeSourceConfigurationId: '7bf60e99-7956-4316-b70d-a9b12b769984',
    knowledgeSourceType: 'Jira',
  },
  {
    collectionId: '10006',
    collectionTitle: 'Onboarding R&D',
    id: '7bf60e99-7956-4316-b70d-a9b12b769984-10006',
    knowledgeSourceConfigurationId: '7bf60e99-7956-4316-b70d-a9b12b769984',
    knowledgeSourceType: 'Jira',
  },
  {
    collectionId: '10040',
    collectionTitle: 'Arm GNU Toolchain Enablement and CI',
    id: '7bf60e99-7956-4316-b70d-a9b12b769984-10040',
    knowledgeSourceConfigurationId: '7bf60e99-7956-4316-b70d-a9b12b769984',
    knowledgeSourceType: 'Jira',
  },
  {
    collectionId: '66028fdfcbe4c3e6a6ae2591',
    collectionTitle: 'New Office',
    id: '0e266f99-3433-4ae0-b03d-dcdfe6b3b74f-66028fdfcbe4c3e6a6ae2591',
    knowledgeSourceConfigurationId: '0e266f99-3433-4ae0-b03d-dcdfe6b3b74f',
    knowledgeSourceType: 'Trello',
  },
];
