// App - Types
import type { CountermeasureSource } from '../countermeasure-source';

export const CountermeasureSourcesDto = [
  'CloverBuiltin',
  'DetectedPavelRoad',
  'Manual',
  'UNKNOWN',
] as const;

export type CountermeasureSourceDto = (typeof CountermeasureSourcesDto)[number];

export const castCountermeasureSourceDtoToCountermeasureSource = (
  dto: CountermeasureSourceDto
): CountermeasureSource => {
  if (dto === 'CloverBuiltin') return 'clover_builtin';

  if (dto === 'DetectedPavelRoad') return 'detected_pavel_road';

  if (dto === 'Manual') return 'manual';

  return 'UNKNOWN';
};

export const castCountermeasureSourceToCountermeasureSourceDto = (
  source: CountermeasureSource
): CountermeasureSourceDto => {
  if (source === 'clover_builtin') return 'CloverBuiltin';

  if (source === 'detected_pavel_road') return 'DetectedPavelRoad';

  if (source === 'manual') return 'Manual';

  return 'UNKNOWN';
};
