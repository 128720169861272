// 3rd
import { delay, http } from 'msw';

// App - Types
import type {
  CreateSlackOAuthUrlRequestPayloadDto,
  CreateSlackOAuthUrlResponseDto,
} from '@/features/integrations';
import {
  ZodCreateSlackOAuthUrlRequestPayloadDto,
  ZodCreateSlackOAuthUrlResponseDto,
} from '@/features/integrations';

// App - Other
import { API_URL } from '@/config/constants';

export const getSlackOauthLoginUrlHandler = http.post(
  `${API_URL}/Integration/GetSlackOAuth2LoginUrl`,
  async ({ request }) => {
    const payload = await request.json();
    const parsedPayload: CreateSlackOAuthUrlRequestPayloadDto =
      ZodCreateSlackOAuthUrlRequestPayloadDto.parse(payload);

    await delay(500);

    const res: CreateSlackOAuthUrlResponseDto = {
      url: `${parsedPayload.redirectUri}${parsedPayload.redirectUriPath}?code=****`,
    };
    const parsedRes: CreateSlackOAuthUrlResponseDto = ZodCreateSlackOAuthUrlResponseDto.parse(res);

    return new Response(JSON.stringify(parsedRes), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
