// App - Types
import type { Id } from '@/types/knowledge-item/id';

export const FEEDBACK_QUERY_KEYS = {
  all: ['feedback'] as const,
  feedback: () => [...FEEDBACK_QUERY_KEYS.all] as const,
  knowledgeItemFeedback: (knowledgeItemId: Id) =>
    [...FEEDBACK_QUERY_KEYS.feedback(), knowledgeItemId] as const,
  slackUsers: (workspaceId: string) =>
    [...FEEDBACK_QUERY_KEYS.feedback(), 'slack-workspace-users', workspaceId] as const,
  slackUsersCrossWorkspaces: (workspaceIds: string[]) =>
    [...FEEDBACK_QUERY_KEYS.feedback(), 'slack-workspaces-users', workspaceIds] as const,
};
