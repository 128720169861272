// 3rd
import { primaryKey } from '@mswjs/data';

export const knowledgeItemsModels = {
  knowledgeItem: {
    id: primaryKey(String),
    author: String,
    collectionId: String,
    creationTime: String,
    documentId: String,
    documentScore: Number,
    documentSource: String,
    documentType: String,
    labels: Object,
    lastModified: String,
    originUrl: String,
    priority: String,
    scoreBreakdown: Object,
    shortenedTitle: String,
    summary: String,
    title: String,
  },
};
