// React & Next
import type { ReactNode } from 'react';

// 3rd
import { Box, forwardRef } from '@chakra-ui/react';
import type { BoxProps } from '@chakra-ui/react';

// App - Other
import { useBadge } from './useBadge';

export type BadgeProps = Partial<BoxProps> & {
  badgePosition?: {
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'right';
  };
  badgeContent?: ReactNode;
  invisible?: boolean;
  max?: number; // Max count to show
  overlap?: 'rectangular' | 'circular';
  showZero?: boolean;
  size?: 'small' | 'large';
  variant?: 'dot' | 'standard';
  children?: ReactNode;
};

function isSmall(size?: BadgeProps['size'], variant?: BadgeProps['variant']): boolean {
  if (size) {
    // size takes precedence
    return size === 'small';
  }

  return variant === 'dot';
}

export const Badge = forwardRef(
  (
    {
      badgePosition: positionProp = { vertical: 'top', horizontal: 'right' },
      badgeContent: badgeContentProp,
      invisible: invisibleProp = false,
      max: maxProp = 99,
      overlap = 'rectangular',
      showZero = false,
      size: sizeProp = 'large',
      variant: variantProp = 'standard',
      children,
      ...boxProps
    }: BadgeProps,
    ref
  ) => {
    const {
      badgeContent,
      invisible: invisibleFromHook,
      displayValue: displayValueFromHook,
    } = useBadge({
      max: maxProp,
      invisible: invisibleProp,
      badgeContent: badgeContentProp,
      showZero,
    });
    const invisible =
      invisibleFromHook || (badgeContent == null && !isSmall(sizeProp, variantProp));
    const displayValue = !isSmall(sizeProp, variantProp) ? displayValueFromHook : undefined;
    const verticalPositionProperty = positionProp.vertical === 'top' ? 'bottom' : 'top';
    const horizontalPositionProperty = positionProp.horizontal === 'left' ? 'right' : 'left';
    const badgeSize = sizeProp === 'small' ? '6px' : '16px';
    const badgeInset =
      overlap === 'circular'
        ? `calc(${badgeSize} / 2 + 14%)`
        : sizeProp === 'small'
          ? '6px'
          : '12px';
    const badgePaddingX = sizeProp === 'small' ? '0px' : '4px';

    const badgeBadgeStyles: BoxProps = {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      boxSizing: 'border-box',
      fontSize: '11px',
      lineHeight: `16.5px`,
      letterSpacing: '0.7px',
      minWidth: badgeSize,
      height: badgeSize,
      paddingRight: `calc(${badgePaddingX} - 0.7px)`,
      paddingLeft: badgePaddingX,
      borderRadius: '100px',
      [verticalPositionProperty]: `calc(100% - ${badgeInset})`,
      [horizontalPositionProperty]: `calc(100% - ${badgeInset})`,
      transform: 'scale(1)',
      transformOrigin: `calc(${badgeSize} / 2)`,
      zIndex: 1, // Render the badge on top of potential ripples.
      transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      ...(invisible
        ? {
            transform: 'scale(0)',
            transition: 'transform 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          }
        : {}),
      ...boxProps,
    };

    return (
      <Box
        position="relative"
        display="inline-flex"
        // For correct alignment with the text.
        verticalAlign="middle"
        flexShrink={0}
        ref={ref}
      >
        {children}

        <Box {...badgeBadgeStyles}>{displayValue}</Box>
      </Box>
    );
  }
);
