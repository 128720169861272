export const SecurityRequirementStatuses = [
  'covered_manually_by_appsec',
  'covered_manually_by_developer',
  'covered_by_clover',
  'sent_to_developers',
  'suggested_by_clover',
  'ignored_by_appsec',
  'irrelevant_by_appsec',
  'irrelevant_suggested_by_dev_approved_by_appsec',
  'irrelevant_suggested_by_dev',
  'UNKNOWN',
] as const;

export type SecurityRequirementStatus = (typeof SecurityRequirementStatuses)[number];

export const AppSecAllowedStatuses: SecurityRequirementStatus[] = [
  'covered_manually_by_appsec',
  // 'sent_to_developers',
  'irrelevant_by_appsec',
];

export const SecurityRequirementStatusGroupKeys = [
  'open',
  'sent_to_dev',
  'dev_reviewed',
  'covered',
  'irrelevant',
] as const;

export type SecurityRequirementStatusGroupKey = (typeof SecurityRequirementStatusGroupKeys)[number];

export const SecurityRequirementStatusGroups: Record<
  SecurityRequirementStatusGroupKey,
  SecurityRequirementStatus[]
> = {
  open: ['suggested_by_clover'],
  sent_to_dev: ['sent_to_developers'],
  dev_reviewed: ['irrelevant_suggested_by_dev'],
  covered: ['covered_manually_by_appsec', 'covered_manually_by_developer', 'covered_by_clover'],
  irrelevant: [
    'irrelevant_suggested_by_dev_approved_by_appsec',
    'irrelevant_by_appsec',
    'ignored_by_appsec',
  ],
};

export const SecurityRequirementStatusGroupsEntries = Object.entries(
  SecurityRequirementStatusGroups
);

export const isSecurityRequirementCovered = (status: SecurityRequirementStatus) => {
  return SecurityRequirementStatusGroups.covered.includes(status);
};

export const isSecurityRequirementIrrelevant = (status: SecurityRequirementStatus) => {
  return SecurityRequirementStatusGroups.irrelevant.includes(status);
};

export const getSecurityRequirementStatusGroupForStatus = (
  status: SecurityRequirementStatus
): SecurityRequirementStatusGroupKey => {
  const statusGroup = SecurityRequirementStatusGroupsEntries.find(([, statuses]) =>
    statuses.includes(status)
  );

  return statusGroup ? (statusGroup[0] as SecurityRequirementStatusGroupKey) : 'open';
};
