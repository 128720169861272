/* eslint-disable @typescript-eslint/no-unused-vars */
// 3rd
import { delay, http } from 'msw';
import { DateTime } from 'luxon';

// App - Types
import type { CreateGoogleDriveOAuthIntegrationRequestPayloadDto } from '@/features/integrations';
import { ZodCreateGoogleDriveOAuthIntegrationRequestPayloadDto } from '@/features/integrations';

// App - Other
import { API_URL } from '@/config/constants';
import { uuid4 } from '@/utils/generators';
import { db } from '../../db';

export const insertGoogleDriveOAuthIntegrationHandler = http.post(
  `${API_URL}/Integration/InsertGoogleDriveOAuth2Integration`,
  async ({ request }) => {
    const payload = await request.json();
    const parsedPayload: CreateGoogleDriveOAuthIntegrationRequestPayloadDto =
      ZodCreateGoogleDriveOAuthIntegrationRequestPayloadDto.parse(payload);

    db.knowledgeSourceConfiguration.create({
      id: uuid4(),
      baseUrl: `liav@clover.security`,
      knowledgeSourceType: 'GoogleDrive',
      lastScraped: DateTime.now().toUTC().toString(),
      userEmail: 'liav@clover.security',
    });

    await delay(1000);

    return new Response(null, {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
