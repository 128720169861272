// App - Types
import type { CountermeasureSnippetType } from '../countermeasure-snippet-type';

export const CountermeasureSnippetTypesDto = [
  'Architecture',
  'Code',
  'Configuration',
  'Documentation',
  'Other',
  'UNKNOWN',
] as const;

export type CountermeasureSnippetTypeDto = (typeof CountermeasureSnippetTypesDto)[number];

export const castCountermeasureSnippetTypeDtoToCountermeasureSnippetType = (
  dto: CountermeasureSnippetTypeDto
): CountermeasureSnippetType => {
  if (dto === 'Architecture') return 'architecture';

  if (dto === 'Code') return 'code';

  if (dto === 'Configuration') return 'configuration';

  if (dto === 'Documentation') return 'documentation';

  if (dto === 'Other') return 'other';

  return 'UNKNOWN';
};

export const castCountermeasureSnippetTypeToCountermeasureSnippetTypeDto = (
  snippetType: CountermeasureSnippetType
): CountermeasureSnippetTypeDto => {
  if (snippetType === 'architecture') return 'Architecture';

  if (snippetType === 'code') return 'Code';

  if (snippetType === 'configuration') return 'Configuration';

  if (snippetType === 'documentation') return 'Documentation';

  if (snippetType === 'other') return 'Other';

  return 'UNKNOWN';
};
