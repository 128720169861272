'use client';

// React & Next
import type { ReactNode } from 'react';

// 3rd
// import { MSWDevTools } from 'msw-devtools';

// App - Other
// import { IS_DEVELOPMENT } from '@/config/constants';
// import { db, handlers } from '@/testing/mocks';

export type MSWProviderProps = {
  children: ReactNode;
};

export const MSWProvider = ({ children }: MSWProviderProps) => {
  require('@/testing/mocks/initialize');

  return (
    <>
      {/*{IS_DEVELOPMENT && <MSWDevTools db={db} handlers={handlers} />}*/}

      {children}
    </>
  );
};
