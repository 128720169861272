// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import { castIdToIdDto, ZodIdDto } from '@/types/knowledge-item/dtos/id';
import { SendCommentFeedback, SendMissingInformationCommentFeedback } from '../types/feedback';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { FEEDBACK_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z
  .object({
    content: z.string(),
    securityStoryId: z.string(),
  })
  .merge(ZodIdDto);

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  securityFeedbackId: z.string(),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

// #######
// Request
// #######

export const sendMissingInformationCommentFeedback = async (
  storyId: string,
  model: SendMissingInformationCommentFeedback
): Promise<string> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      ...castIdToIdDto(model.recipientKnowledgeItemId),
      securityStoryId: storyId,
      content: model.comment,
    });
    const res = await apiClient.post(`/SecurityFeedback/CreateMissingInformationFeedback`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.securityFeedbackId;
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseSendMissingInformationCommentFeedback = {
  storyId: string;
  onCreateStart?: () => void;
  onCreateSuccess?: (payload: SendCommentFeedback) => void;
  onCreateFailed?: (error: Error) => void;
};

export const useSendMissingInformationCommentFeedback = ({
  storyId,
  onCreateStart,
  onCreateSuccess,
  onCreateFailed,
}: UseSendMissingInformationCommentFeedback) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (sendCommentFeedbackPayload: SendMissingInformationCommentFeedback) => {
      await sendMissingInformationCommentFeedback(storyId, sendCommentFeedbackPayload);

      await queryClient.invalidateQueries({
        queryKey: FEEDBACK_QUERY_KEYS.knowledgeItemFeedback(
          sendCommentFeedbackPayload.recipientKnowledgeItemId
        ),
      });

      return sendCommentFeedbackPayload;
    },
    onMutate: () => onCreateStart?.(),
    onSuccess: (payload) => onCreateSuccess?.(payload),
    onError: (error) => onCreateFailed?.(error),
  });

  return {
    sendCommentFeedback: mutate,
    isSendingComment: isPending,
    didSendCommentErrored: isError,
  };
};
