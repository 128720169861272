// 3rd
import { delay, http } from 'msw';

// App - Types
import type { GetSlackIntegrationsResponseDto, SlackIntegrationDto } from '@/features/integrations';
import {
  ZodSlackIntegrationDto,
  ZodGetSlackIntegrationsResponseDto,
} from '@/features/integrations';

// App - Other
import { API_URL } from '@/config/constants';
import { db } from '../../db';

export const getListSlackIntegrationsHandler = http.get(
  `${API_URL}/Integration/ListSlackIntegrations`,
  async () => {
    const slackIntegrations = db.slackIntegration.getAll();
    const parsedSlackIntegrations: SlackIntegrationDto[] = slackIntegrations.map((integration) =>
      ZodSlackIntegrationDto.parse(integration)
    );

    await delay(500);

    const res: GetSlackIntegrationsResponseDto = { integrations: parsedSlackIntegrations };
    const parsedRes: GetSlackIntegrationsResponseDto =
      ZodGetSlackIntegrationsResponseDto.parse(res);

    return new Response(JSON.stringify(parsedRes), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
);
