// 3rd
import { delay, http } from 'msw';

// App - Other
import { API_URL } from '@/config/constants';
import { db } from '../../db';

export const getApplicationsHandler = http.get(`${API_URL}/applications`, async () => {
  const applications = db.application.getAll();

  await delay(500);

  return new Response(JSON.stringify(applications), {
    status: 200,
    headers: {
      'Content-Type': 'application/json',
    },
  });
});
