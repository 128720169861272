// App - Other
// import { getGithubOauthLoginUrlHandler } from './get-github-oauth-login-url';
import { getGoogleDriveOauthLoginUrlHandler } from './get-google-drive-oauth-login-url';
import { getListIntegrationsHandler } from './get-list-integrations';
import { getListSlackIntegrationsHandler } from './get-list-slack-integrations';
import { getSlackOauthLoginUrlHandler } from './get-slack-oauth-login-url';
import { insertAzureDevOpsIntegrationHandler } from './insert-azure-devops-integration';
import { insertConfluenceIntegrationHandler } from './insert-confluence-integration';
// import { insertGithubOAuthIntegrationHandler } from './insert-github-oauth-integration';
import { insertGoogleDriveOAuthIntegrationHandler } from './insert-google-drive-oauth-integration';
import { insertGoogleDriveServiceAccountIntegrationHandler } from './insert-google-drive-service-account-integration';
import { insertJiraIntegrationHandler } from './insert-jira-integration';
import { insertLinearIntegrationHandler } from './insert-linear-integration';
import { insertSlackOAuthIntegrationHandler } from './insert-slack-oauth-integration';
import { insertTrelloIntegrationHandler } from './insert-trello-integration';

// export * from './get-github-oauth-login-url';
export * from './get-google-drive-oauth-login-url';
export * from './get-list-integrations';
export * from './get-list-slack-integrations';
export * from './get-slack-oauth-login-url';
export * from './insert-azure-devops-integration';
export * from './insert-confluence-integration';
// export * from './insert-github-oauth-integration';
export * from './insert-google-drive-oauth-integration';
export * from './insert-google-drive-service-account-integration';
export * from './insert-jira-integration';
export * from './insert-linear-integration';
export * from './insert-slack-oauth-integration';
export * from './insert-trello-integration';

export const integrationsHandlers = [
  // getGithubOauthLoginUrlHandler,
  getGoogleDriveOauthLoginUrlHandler,
  getListIntegrationsHandler,
  getListSlackIntegrationsHandler,
  getSlackOauthLoginUrlHandler,
  insertAzureDevOpsIntegrationHandler,
  insertConfluenceIntegrationHandler,
  // insertGithubOAuthIntegrationHandler,
  insertGoogleDriveOAuthIntegrationHandler,
  insertGoogleDriveServiceAccountIntegrationHandler,
  insertJiraIntegrationHandler,
  insertLinearIntegrationHandler,
  insertSlackOAuthIntegrationHandler,
  insertTrelloIntegrationHandler,
];
