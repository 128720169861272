// React & Next
import NextLink from 'next/link';

// 3rd
import { Link as ChakraLink } from '@chakra-ui/react';
import type { LinkProps as ChakraLinkProps, StyleProps } from '@chakra-ui/react';

// App - Other
import { ExternalLinkIcon } from '@/components/atoms/icon';

type LinkProps = StyleProps &
  Pick<ChakraLinkProps, 'children' | 'href' | 'isExternal' | '_hover'> & {
    excludeIcon?: boolean;
  };

export const Link = ({ href, isExternal, excludeIcon, children, ...styleProps }: LinkProps) => {
  const asNextLink = isExternal ? { target: '_blank' } : { as: NextLink };

  return (
    <ChakraLink href={href || ''} {...styleProps} {...asNextLink}>
      {children}

      {isExternal && !excludeIcon ? (
        <ExternalLinkIcon aria-label={`External link to ${href}`} ms="xs" h="15px" w="15px" />
      ) : null}
    </ChakraLink>
  );
};
