// App - Types
import type { SecurityFrameworkDto } from '@/features/security-frameworks';

export const frameworksMockData: SecurityFrameworkDto[] = [
  {
    activityStatus: 'Active',
    id: '7a0df87b-a583-4d72-b551-a9778a0aa5f8',
    isDefault: true,
    labels: [],
    name: 'ASVS',
    securityControls: [
      {
        controlName: '13 API and Web Service',
        description:
          'Ensure that a verified application that uses trusted service layer APIs (commonly using JSON or XML or GraphQL) has: \n * Adequate authentication, session management and authorization of all web services \n * Input validation of all parameters that transit from a lower to higher trust level. \n * Effective security controls for all API types, including cloud and Serverless API',
        id: '0241dc7e-84f8-4c21-b108-36f815750b00',
        importanceScore: 'Medium',
        securityRequirements: [
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '13.4 GraphQL',
            cwes: [],
            content:
              'Verify that GraphQL or other data layer authorization logic should be implemented at the business logic layer instead of the GraphQL layer.',
            id: '04b2f0de-dcb1-4648-aaa0-db3cbe5bd868',
            countermeasures: [],
            requirementIdNumber: '13.4.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Inactive',
            antiPatterns: [],
            categoryName: '13.1 Generic Web Service Security',
            cwes: [],
            content:
              'Verify that all application components use the same encodings and parsers to avoid parsing attacks that exploit different URI or file parsing behavior that could be used in SSRF and RFI attacks.',
            id: '2129e4e7-e5f7-4b05-91ff-153ff9dbef90',
            countermeasures: [],
            requirementIdNumber: '13.1.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '13.3 SOAP Web Service',
            cwes: [],
            content:
              'Verify that the message payload is signed using WS-Security to ensure reliable transport between client and service.',
            id: '2286c3f0-3612-498d-806d-a9bff1f03428',
            countermeasures: [],
            requirementIdNumber: '13.3.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Inactive',
            antiPatterns: [],
            categoryName: '13.1 Generic Web Service Security',
            cwes: [],
            content:
              'Verify that requests containing unexpected or missing content types are rejected with appropriate headers (HTTP response status 406 Unacceptable or 415 Unsupported Media Type).',
            id: '2abb218e-360f-41a4-aae3-9ffc6613ff9b',
            countermeasures: [],
            requirementIdNumber: '13.1.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '13.2 RESTful Web Service',
            cwes: [],
            content:
              'Verify that the message headers and payload are trustworthy and not modified in transit. Requiring strong encryption for transport (TLS only) may be sufficient in many cases as it provides both confidentiality and integrity protection. Per-message digital signatures can provide additional assurance on top of the transport protections for high-security applications but bring with them additional complexity and risks to weigh against the benefits.',
            id: '44569a10-37cf-45ec-9b4b-558129d41ff6',
            countermeasures: [],
            requirementIdNumber: '13.2.6',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '13.2 RESTful Web Service',
            cwes: [],
            content:
              'Verify that enabled RESTful HTTP methods are a valid choice for the user or action, such as preventing normal users using DELETE or PUT on protected API or resources.',
            id: '5585cb4f-17e6-4d86-9370-83b8affa2fd3',
            countermeasures: [],
            requirementIdNumber: '13.2.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '13.4 GraphQL',
            cwes: [],
            content:
              'Verify that a query allow list or a combination of depth limiting and amount limiting is used to prevent GraphQL or data layer expression Denial of Service (DoS) as a result of expensive, nested queries. For more advanced scenarios, query cost analysis should be used.',
            id: '5956bb88-71ce-4b88-aae7-2d7a995b8ca0',
            countermeasures: [],
            requirementIdNumber: '13.4.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '13.2 RESTful Web Service',
            cwes: [],
            content:
              'Verify that REST services explicitly check the incoming Content-Type to be the expected one, such as application/xml or application/json.',
            id: '5ed34186-5291-483f-bddf-2ed9eb5f13a5',
            countermeasures: [],
            requirementIdNumber: '13.2.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '13.1 Generic Web Service Security',
            cwes: [],
            content:
              'Verify that authorization decisions are made at both the URI, enforced by programmatic or declarative security at the controller or router, and at the resource level, enforced by model-based permissions.',
            id: '79a5428f-1e0d-4436-ac9a-4e23b204189e',
            countermeasures: [],
            requirementIdNumber: '13.1.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '13.2 RESTful Web Service',
            cwes: [],
            content:
              'Verify that RESTful web services that utilize cookies are protected from Cross-Site Request Forgery via the use of at least one or more of the following: double submit cookie pattern, CSRF nonces, or Origin request header checks.',
            id: '79dfeb3c-823b-454e-b3be-cdeb3d51063d',
            countermeasures: [],
            requirementIdNumber: '13.2.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '13.3 SOAP Web Service',
            cwes: [],
            content:
              'Verify that XSD schema validation takes place to ensure a properly formed XML document, followed by validation of each input field before any processing of that data takes place.',
            id: '7a348c3c-74fb-4190-bb08-eefb7c613912',
            countermeasures: [],
            requirementIdNumber: '13.3.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '13.2 RESTful Web Service',
            cwes: [],
            content:
              'Verify that JSON schema validation is in place and verified before accepting input.',
            id: '80252f67-c273-492f-bb2d-1770b8abae45',
            countermeasures: [],
            requirementIdNumber: '13.2.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '13.1 Generic Web Service Security',
            cwes: [],
            content:
              'Verify API URLs do not expose sensitive information, such as the API key, session tokens etc.',
            id: 'e942e583-a5b5-476a-bae1-6f7eb90f8738',
            countermeasures: [],
            requirementIdNumber: '13.1.3',
            severity: 'Medium',
          },
        ],
      },
      {
        controlName: '6 Stored Cryptography',
        description:
          'Ensure that a verified application satisfies the following high level requirements',
        id: '45527a47-7565-4920-8bfc-09d408820a15',
        importanceScore: 'Medium',
        securityRequirements: [
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '6.1 Data Classification',
            cwes: [],
            content:
              'Verify that regulated health data is stored encrypted while at rest, such as medical records, medical device details, or de-anonymized research records.',
            id: '0cfd109b-c54a-4e05-a72d-a2372263cf71',
            countermeasures: [],
            requirementIdNumber: '6.1.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '6.2 Algorithms',
            cwes: [],
            content:
              'Verify that all cryptographic modules fail securely, and errors are handled in a way that does not enable Padding Oracle attacks.',
            id: '28f067e5-c0b2-482a-87fc-caab6049e36e',
            countermeasures: [],
            requirementIdNumber: '6.2.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '6.3 Random Values',
            cwes: [],
            content:
              'Verify that random GUIDs are created using the GUID 4 algorithm, and a Cryptographically-secure Pseudo-random Number Generator (CSPRNG). GUIDs created using other pseudo-random number generators may be predictable.',
            id: '3f49da84-bbde-47b1-b9f1-982e9b3d0e95',
            countermeasures: [],
            requirementIdNumber: '6.3.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '6.1 Data Classification',
            cwes: [],
            content:
              "Verify that regulated private data is stored encrypted while at rest, such as Personally Identifiable Information (PII), sensitive personal information, or data assessed likely to be subject to EU's GDPR.",
            id: '3ffa1b73-02e4-4a5b-b13c-fe3d1054ce32',
            countermeasures: [],
            requirementIdNumber: '6.1.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '6.2 Algorithms',
            cwes: [],
            content:
              'Verify that industry proven or government approved cryptographic algorithms, modes, and libraries are used, instead of custom coded cryptography. ([C8](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '5251d7df-fc64-42d8-9879-a5d2047d8201',
            countermeasures: [],
            requirementIdNumber: '6.2.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '6.2 Algorithms',
            cwes: [],
            content:
              'Verify that nonces, initialization vectors, and other single use numbers must not be used more than once with a given encryption key. The method of generation must be appropriate for the algorithm being used.',
            id: '5c5fbc99-6487-4d7f-9264-cae9d92b6e88',
            countermeasures: [],
            requirementIdNumber: '6.2.6',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '6.2 Algorithms',
            cwes: [],
            content:
              "Verify that all cryptographic operations are constant-time, with no 'short-circuit' operations in comparisons, calculations, or returns, to avoid leaking information.",
            id: '60ca870a-e442-4021-bf2a-9e5afb559229',
            countermeasures: [],
            requirementIdNumber: '6.2.8',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '6.1 Data Classification',
            cwes: [],
            content:
              'Verify that regulated financial data is stored encrypted while at rest, such as financial accounts, defaults or credit history, tax records, pay history, beneficiaries, or de-anonymized market or research records.',
            id: '64934eaa-8c02-4f02-a35d-b4d35ca62aac',
            countermeasures: [],
            requirementIdNumber: '6.1.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '6.2 Algorithms',
            cwes: [],
            content:
              'Verify that known insecure block modes (i.e. ECB, etc.), padding modes (i.e. PKCS#1 v1.5, etc.), ciphers with small block sizes (i.e. Triple-DES, Blowfish, etc.), and weak hashing algorithms (i.e. MD5, SHA1, etc.) are not used unless required for backwards compatibility.',
            id: '8977806a-760e-4d34-910e-5e57c1c6da3b',
            countermeasures: [],
            requirementIdNumber: '6.2.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '6.3 Random Values',
            cwes: [],
            content:
              'Verify that random numbers are created with proper entropy even when the application is under heavy load, or that the application degrades gracefully in such circumstances.',
            id: 'b7d4f937-51a3-4354-9ac4-3d9c3b6bc73e',
            countermeasures: [],
            requirementIdNumber: '6.3.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '6.2 Algorithms',
            cwes: [],
            content:
              'Verify that encryption initialization vector, cipher configuration, and block modes are configured securely using the latest advice.',
            id: 'c64e8467-a8c0-44b0-9c9c-e73bb2c20d47',
            countermeasures: [],
            requirementIdNumber: '6.2.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '6.4 Secret Management',
            cwes: [],
            content:
              'Verify that a secrets management solution such as a key vault is used to securely create, store, control access to and destroy secrets. ([C8](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: 'da99259d-363e-457d-8a94-4f6621aa7d96',
            countermeasures: [],
            requirementIdNumber: '6.4.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '6.2 Algorithms',
            cwes: [],
            content:
              'Verify that random number, encryption or hashing algorithms, key lengths, rounds, ciphers or modes, can be reconfigured, upgraded, or swapped at any time, to protect against cryptographic breaks. ([C8](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: 'e7eb0ee9-e707-4cc7-a43a-1b629518ca17',
            countermeasures: [],
            requirementIdNumber: '6.2.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '6.4 Secret Management',
            cwes: [],
            content:
              'Verify that key material is not exposed to the application but instead uses an isolated security module like a vault for cryptographic operations. ([C8](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: 'e8286869-3d3e-43e7-88ae-6372999cb8ac',
            countermeasures: [],
            requirementIdNumber: '6.4.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '6.2 Algorithms',
            cwes: [],
            content:
              'Verify that encrypted data is authenticated via signatures, authenticated cipher modes, or HMAC to ensure that ciphertext is not altered by an unauthorized party.',
            id: 'f8c5f9bc-f018-46be-9dbb-04ccd810d897',
            countermeasures: [],
            requirementIdNumber: '6.2.7',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '6.3 Random Values',
            cwes: [],
            content:
              "Verify that all random numbers, random file names, random GUIDs, and random strings are generated using the cryptographic module's approved cryptographically secure random number generator when these random values are intended to be not guessable by an attacker.",
            id: 'fcf33c3f-1745-43af-9dce-3cae67b3126c',
            countermeasures: [],
            requirementIdNumber: '6.3.1',
            severity: 'Medium',
          },
        ],
      },
      {
        controlName: '14 Session Management',
        description:
          'Ensure that a verified application has: \n * A secure, repeatable, automatable build environment. \n * Hardened third party library, dependency and configuration management such that out of date or insecure components are not included by the application. \nConfiguration of the application out of the box should be safe to be on the Internet, which means a safe out of the box configuration.',
        id: '5990a76f-1a62-49f1-8609-e76761ac7d97',
        importanceScore: 'Medium',
        securityRequirements: [
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.4 HTTP Security Headers',
            cwes: [],
            content:
              'Verify that the content of a web application cannot be embedded in a third-party site by default and that embedding of the exact resources is only allowed where necessary by using suitable Content-Security-Policy: frame-ancestors and X-Frame-Options response headers.',
            id: '005f0f68-e474-4df9-80a2-244fb79e3dd7',
            countermeasures: [],
            requirementIdNumber: '14.4.7',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.4 HTTP Security Headers',
            cwes: [],
            content:
              'Verify that every HTTP response contains a Content-Type header. Also specify a safe character set (e.g., UTF-8, ISO-8859-1) if the content types are text/*, /+xml and application/xml. Content must match with the provided Content-Type header.',
            id: '04f79d39-87f3-4a4d-9497-98560c44c594',
            countermeasures: [],
            requirementIdNumber: '14.4.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.2 Dependency',
            cwes: [],
            content:
              'Verify that third party components come from pre-defined, trusted and continually maintained repositories. ([C2](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '06831e7e-ca47-47b4-b12a-88a59f68369d',
            countermeasures: [],
            requirementIdNumber: '14.2.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.2 Dependency',
            cwes: [],
            content:
              'Verify that a Software Bill of Materials (SBOM) is maintained of all third party libraries in use. ([C2](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '0c1c6e5b-3ca1-4add-8b17-f1a2c982970a',
            countermeasures: [],
            requirementIdNumber: '14.2.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.1 Build and Deploy',
            cwes: [],
            content:
              'Verify that authorized administrators can verify the integrity of all security-relevant configurations to detect tampering.',
            id: '0d4204e7-f113-4a44-b7a7-55ef90cdc4ae',
            countermeasures: [],
            requirementIdNumber: '14.1.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.3 Unintended Security Disclosure',
            cwes: [],
            content:
              'Verify that web or application server and application framework debug modes are disabled in production to eliminate debug features, developer consoles, and unintended security disclosures.',
            id: '105658f8-a586-4dd1-930f-551174d8f137',
            countermeasures: [],
            requirementIdNumber: '14.3.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.5 HTTP Request Header Validation',
            cwes: [],
            content:
              'Verify that the application server only accepts the HTTP methods in use by the application/API, including pre-flight OPTIONS, and logs/alerts on any requests that are not valid for the application context.',
            id: '1e1fc767-05d5-499c-8929-ebb810e5cc87',
            countermeasures: [],
            requirementIdNumber: '14.5.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.4 HTTP Security Headers',
            cwes: [],
            content:
              'Verify that a suitable Referrer-Policy header is included to avoid exposing sensitive information in the URL through the Referer header to untrusted parties.',
            id: '26931fb3-cae7-4104-88f2-8a8101a284a0',
            countermeasures: [],
            requirementIdNumber: '14.4.6',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.3 Unintended Security Disclosure',
            cwes: [],
            content:
              'Verify that the HTTP headers or any part of the HTTP response do not expose detailed version information of system components.',
            id: '2e9a6074-686d-4537-b7a7-acd7ac48a737',
            countermeasures: [],
            requirementIdNumber: '14.3.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.1 Build and Deploy',
            cwes: [],
            content:
              'Verify that compiler flags are configured to enable all available buffer overflow protections and warnings, including stack randomization, data execution prevention, and to break the build if an unsafe pointer, memory, format string, integer, or string operations are found.',
            id: '407d22f9-b58c-44f5-ac81-0ce5bc6e2fd1',
            countermeasures: [],
            requirementIdNumber: '14.1.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.4 HTTP Security Headers',
            cwes: [],
            content:
              'Verify that all API responses contain a Content-Disposition: attachment; filename="api.json" header (or other appropriate filename for the content type).',
            id: '7a394d05-4e82-4550-a38e-925703c4a692',
            countermeasures: [],
            requirementIdNumber: '14.4.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.4 HTTP Security Headers',
            cwes: [],
            content: 'Verify that all responses contain a X-Content-Type-Options: nosniff header.',
            id: '8284913c-cd63-4f6f-a594-99f0a5a11cf4',
            countermeasures: [],
            requirementIdNumber: '14.4.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.4 HTTP Security Headers',
            cwes: [],
            content:
              'Verify that a Strict-Transport-Security header is included on all responses and for all subdomains, such as Strict-Transport-Security: max-age=15724800; includeSubdomains.',
            id: '8a291b95-7cef-4570-a0a2-c2eda4d6e682',
            countermeasures: [],
            requirementIdNumber: '14.4.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.2 Dependency',
            cwes: [],
            content:
              'Verify that if application assets, such as JavaScript libraries, CSS or web fonts, are hosted externally on a Content Delivery Network (CDN) or external provider, Subresource Integrity (SRI) is used to validate the integrity of the asset.',
            id: '96069139-68a1-4f9a-a7aa-019b480bfdd4',
            countermeasures: [],
            requirementIdNumber: '14.2.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.1 Build and Deploy',
            cwes: [],
            content:
              'Verify that the application build and deployment processes are performed in a secure and repeatable way, such as CI / CD automation, automated configuration management, and automated deployment scripts.',
            id: '974a8a89-141b-4a6e-adc6-21d4bb274e32',
            countermeasures: [],
            requirementIdNumber: '14.1.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.2 Dependency',
            cwes: [],
            content:
              'Verify that all components are up to date, preferably using a dependency checker during build or compile time. ([C2](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: 'a7c90328-7d45-4a08-a5c2-7d9c0d49c8c5',
            countermeasures: [],
            requirementIdNumber: '14.2.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.4 HTTP Security Headers',
            cwes: [],
            content:
              'Verify that a Content Security Policy (CSP) response header is in place that helps mitigate impact for XSS attacks like HTML, DOM, JSON, and JavaScript injection vulnerabilities.',
            id: 'c17049e4-636c-449b-9b35-4f450d9776a7',
            countermeasures: [],
            requirementIdNumber: '14.4.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.1 Build and Deploy',
            cwes: [],
            content:
              'Verify that server configuration is hardened as per the recommendations of the application server and frameworks in use.',
            id: 'c1dc83e5-c5e6-4fff-a3ac-3dd1456284da',
            countermeasures: [],
            requirementIdNumber: '14.1.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.5 HTTP Request Header Validation',
            cwes: [],
            content:
              'Verify that the supplied Origin header is not used for authentication or access control decisions, as the Origin header can easily be changed by an attacker.',
            id: 'c88a810e-b28f-4fae-bc68-25ce8a5f8554',
            countermeasures: [],
            requirementIdNumber: '14.5.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.1 Build and Deploy',
            cwes: [],
            content:
              'Verify that the application, configuration, and all dependencies can be re-deployed using automated deployment scripts, built from a documented and tested runbook in a reasonable time, or restored from backups in a timely fashion.',
            id: 'd041cb97-4209-451c-ac2c-637fa26d8a83',
            countermeasures: [],
            requirementIdNumber: '14.1.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.5 HTTP Request Header Validation',
            cwes: [],
            content:
              'Verify that HTTP headers added by a trusted proxy or SSO devices, such as a bearer token, are authenticated by the application.',
            id: 'dee85608-44a0-4ad3-b4cb-77f06064526f',
            countermeasures: [],
            requirementIdNumber: '14.5.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.2 Dependency',
            cwes: [],
            content:
              'Verify that all unneeded features, documentation, sample applications and configurations are removed.',
            id: 'df44b625-8ee8-4a67-9d16-cfb6127505a6',
            countermeasures: [],
            requirementIdNumber: '14.2.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.2 Dependency',
            cwes: [],
            content:
              'Verify that the attack surface is reduced by sandboxing or encapsulating third party libraries to expose only the required behaviour into the application. ([C2](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: 'f1f69219-cb33-445d-b2c7-2119e019c7dd',
            countermeasures: [],
            requirementIdNumber: '14.2.6',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '14.5 HTTP Request Header Validation',
            cwes: [],
            content:
              'Verify that the Cross-Origin Resource Sharing (CORS) Access-Control-Allow-Origin header uses a strict allow list of trusted domains and subdomains to match against and does not support the "null" origin.',
            id: 'ffb98ce8-c172-4462-b0e8-39a7cf0004e5',
            countermeasures: [],
            requirementIdNumber: '14.5.3',
            severity: 'Medium',
          },
        ],
      },
      {
        controlName: '4 Access Control',
        description:
          'Authorization is the concept of allowing access to resources only to those permitted to use them. Ensure that a verified application satisfies the following high level requirements',
        id: '65b40b99-230e-4b1b-bae6-fe313933c60e',
        importanceScore: 'Medium',
        securityRequirements: [
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '4.2 Operation Level Access Control',
            cwes: [],
            content:
              'Verify that the application or framework enforces a strong anti-CSRF mechanism to protect authenticated functionality, and effective anti-automation or anti-CSRF protects unauthenticated functionality.',
            id: '05c8e56a-8acd-4b25-900f-d19675a88a42',
            countermeasures: [],
            requirementIdNumber: '4.2.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '4.1 General Access Control Design',
            cwes: [],
            content:
              'Verify that all user and data attributes and policy information used by access controls cannot be manipulated by end users unless specifically authorized.',
            id: '30ab0c91-613e-4fbd-b4c6-d8e5da120d76',
            countermeasures: [],
            requirementIdNumber: '4.1.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '4.1 General Access Control Design',
            cwes: [],
            content:
              'Verify that the application enforces access control rules on a trusted service layer, especially if client-side access control is present and could be bypassed.',
            id: '3a23884d-228c-4884-894a-c8db56b765a7',
            countermeasures: [
              {
                content: "console.log('console.log('hello world');')",
                countermeasureSource: 'Manual',
                countermeasureSnippetType: 'Code',
                id: 'e3de5c04-e8a9-45f4-9776-b90b4544ac31',
                securityRequirementsIds: ['3a23884d-228c-4884-894a-c8db56b765a7'],
                title: 'Test countermeasure',
              },
            ],
            requirementIdNumber: '4.1.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '4.1 General Access Control Design',
            cwes: [],
            content:
              'Verify that the principle of least privilege exists - users should only be able to access functions, data files, URLs, controllers, services, and other resources, for which they possess specific authorization. This implies protection against spoofing and elevation of privilege. ([C7](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '40e9dd66-c7bf-41af-9874-3b52bb76de9c',
            countermeasures: [],
            requirementIdNumber: '4.1.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '4.2 Operation Level Access Control',
            cwes: [],
            content:
              "Verify that sensitive data and APIs are protected against Insecure Direct Object Reference (IDOR) attacks targeting creation, reading, updating and deletion of records, such as creating or updating someone else's record, viewing everyone's records, or deleting all records.",
            id: '8e8a787e-1a49-4a9b-9d8f-7c72e5fcb59b',
            countermeasures: [],
            requirementIdNumber: '4.2.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '4.3 Other Access Control Considerations',
            cwes: [],
            content:
              'Verify that directory browsing is disabled unless deliberately desired. Additionally, applications should not allow discovery or disclosure of file or directory metadata, such as Thumbs.db, .DS_Store, .git or .svn folders.',
            id: 'a6b41cda-c1fa-4653-9976-ffcd3d2754c6',
            countermeasures: [],
            requirementIdNumber: '4.3.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '4.3 Other Access Control Considerations',
            cwes: [],
            content:
              'Verify the application has additional authorization (such as step up or adaptive authentication) for lower value systems, and / or segregation of duties for high value applications to enforce anti-fraud controls as per the risk of application and past fraud.',
            id: 'ef1dbebe-7f7f-44d8-b890-56132bc3e742',
            countermeasures: [],
            requirementIdNumber: '4.3.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '4.3 Other Access Control Considerations',
            cwes: [],
            content:
              'Verify administrative interfaces use appropriate multi-factor authentication to prevent unauthorized use.',
            id: 'f2f17dcb-f2ea-40fd-8364-8b40624f380c',
            countermeasures: [],
            requirementIdNumber: '4.3.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '4.1 General Access Control Design',
            cwes: [],
            content:
              'Verify that access controls fail securely including when an exception occurs. ([C10](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: 'ff612c2a-b668-4ed7-a830-ed77da1d381f',
            countermeasures: [],
            requirementIdNumber: '4.1.5',
            severity: 'Medium',
          },
        ],
      },
      {
        controlName: '11 Business Logic',
        description:
          'Ensure that a verified application satisfies business logic high level requirements',
        id: '7c5d02cf-741e-488a-82ba-6478d99302f9',
        importanceScore: 'Medium',
        securityRequirements: [
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '11.1 Business Logic Security',
            cwes: [],
            content:
              'Verify that the application will only process business logic flows for the same user in sequential step order and without skipping steps.',
            id: '2e48e1f7-6b0e-477a-824d-a8a48a33ed7c',
            countermeasures: [],
            requirementIdNumber: '11.1.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '11.1 Business Logic Security',
            cwes: [],
            content:
              'Verify that the application has configurable alerting when automated attacks or unusual activity is detected.',
            id: '3c10c058-d473-4f2c-bd0d-d392c55948fd',
            countermeasures: [],
            requirementIdNumber: '11.1.8',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '11.1 Business Logic Security',
            cwes: [],
            content:
              'Verify the application has appropriate limits for specific business actions or transactions which are correctly enforced on a per user basis.',
            id: '45164fda-566c-4ca1-8a48-8c6bee90e49b',
            countermeasures: [],
            requirementIdNumber: '11.1.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '11.1 Business Logic Security',
            cwes: [],
            content:
              'Verify that the application monitors for unusual events or activity from a business logic perspective. For example, attempts to perform actions out of order or actions which a normal user would never attempt. ([C9](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '576883de-79b7-4a0a-9e02-a3029605dae1',
            countermeasures: [],
            requirementIdNumber: '11.1.7',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '11.1 Business Logic Security',
            cwes: [],
            content:
              'Verify the application has business logic limits or validation to protect against likely business risks or threats, identified using threat modeling or similar methodologies.',
            id: '7aec4791-a370-492e-9d8e-dba33160c817',
            countermeasures: [],
            requirementIdNumber: '11.1.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '11.1 Business Logic Security',
            cwes: [],
            content:
              'Verify that the application does not suffer from "Time Of Check to Time Of Use" (TOCTOU) issues or other race conditions for sensitive operations.',
            id: '8a6bac89-b23a-448d-82d7-3e3a52cbb995',
            countermeasures: [],
            requirementIdNumber: '11.1.6',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '11.1 Business Logic Security',
            cwes: [],
            content:
              'Verify that the application will only process business logic flows with all steps being processed in realistic human time, i.e. transactions are not submitted too quickly.',
            id: '8b7ed343-a72b-4723-b73b-48c766aee868',
            countermeasures: [],
            requirementIdNumber: '11.1.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '11.1 Business Logic Security',
            cwes: [],
            content:
              'Verify that the application has anti-automation controls to protect against excessive calls such as mass data exfiltration, business logic requests, file uploads or denial of service attacks.',
            id: 'df460bdf-9209-405f-8812-69f5e901e51a',
            countermeasures: [],
            requirementIdNumber: '11.1.4',
            severity: 'Medium',
          },
        ],
      },
      {
        controlName: '3 Session Management',
        description:
          'One of the core components of any web-based application or stateful API is the mechanism by which it controls and maintains the state for a user or device interacting with it. Session management changes a stateless protocol to stateful, which is critical for differentiating different users or devices.',
        id: '87256740-3c90-49c1-86cb-3d79ed5fc6c0',
        importanceScore: 'Medium',
        securityRequirements: [
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '3.4 Cookie-based Session Management',
            cwes: [],
            content:
              "Verify that cookie-based session tokens have the 'Secure' attribute set. ([C6](https://owasp.org/www-project-proactive-controls/#div-numbering))",
            id: '072ef8ef-ba64-460c-806f-13587cd6a4fa',
            countermeasures: [],
            requirementIdNumber: '3.4.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '3.4 Cookie-based Session Management',
            cwes: [],
            content:
              "Verify that cookie-based session tokens have the 'HttpOnly' attribute set. ([C6](https://owasp.org/www-project-proactive-controls/#div-numbering))",
            id: '1dc39d66-12ed-4a61-821b-18b7d9cb1cf0',
            countermeasures: [],
            requirementIdNumber: '3.4.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '3.3 Session Termination',
            cwes: [],
            content:
              'Verify that the application gives the option to terminate all other active sessions after a successful password change (including change via password reset/recovery), and that this is effective across the application, federated login (if present), and any relying parties.',
            id: '2248fa25-da5d-4b5e-a8d4-71660d73219d',
            countermeasures: [],
            requirementIdNumber: '3.3.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '3.2 Session Binding',
            cwes: [],
            content:
              'Verify the application only stores session tokens in the browser using secure methods such as appropriately secured cookies (see section 3.4) or HTML 5 session storage.',
            id: '23f42c6d-9adc-4293-bcbc-56adf8c328cf',
            countermeasures: [],
            requirementIdNumber: '3.2.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '3.2 Session Binding',
            cwes: [],
            content:
              'Verify the application generates a new session token on user authentication. ([C6](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '24eda3a4-6d47-4e59-a430-3a69fa00217c',
            countermeasures: [],
            requirementIdNumber: '3.2.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '3.3 Session Termination',
            cwes: [],
            content:
              'Verify that users are able to view and (having re-entered login credentials) log out of any or all currently active sessions and devices.',
            id: '259a4f0e-c6d5-4f29-8c52-d7ccba82a375',
            countermeasures: [],
            requirementIdNumber: '3.3.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '3.5 Token-based Session Management',
            cwes: [],
            content:
              'Verify that stateless session tokens use digital signatures, encryption, and other countermeasures to protect against tampering, enveloping, replay, null cipher, and key substitution attacks.',
            id: '306bfb1f-87af-4af4-9b1a-555849dfacc4',
            countermeasures: [],
            requirementIdNumber: '3.5.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '3.4 Cookie-based Session Management',
            cwes: [],
            content:
              'Verify that cookie-based session tokens use the "__Host-" prefix so cookies are only sent to the host that initially set the cookie.',
            id: '3d65bbe2-65e0-432e-865c-adbe3d0396ed',
            countermeasures: [],
            requirementIdNumber: '3.4.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '3.3 Session Termination',
            cwes: [],
            content:
              'Verify that logout and expiration invalidate the session token, such that the back button or a downstream relying party does not resume an authenticated session, including across relying parties. ([C6](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '4d65f823-791b-404f-be88-077afc441917',
            countermeasures: [],
            requirementIdNumber: '3.3.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '3.5 Token-based Session Management',
            cwes: [],
            content:
              'Verify the application allows users to revoke OAuth tokens that form trust relationships with linked applications.',
            id: '5d7f50d3-05d8-44a4-ba84-30e1ec1b305c',
            countermeasures: [],
            requirementIdNumber: '3.5.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '3.2 Session Binding',
            cwes: [],
            content:
              'Verify that session tokens possess at least 64 bits of entropy. ([C6](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '636cbea7-a41f-4885-bdc1-911129ffac97',
            countermeasures: [],
            requirementIdNumber: '3.2.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '3.7 Defenses Against Session Management Exploits',
            cwes: [],
            content:
              'Verify the application ensures a full, valid login session or requires re-authentication or secondary verification before allowing any sensitive transactions or account modifications.',
            id: '65905fbe-19d1-4b96-a2d7-1302cda6468b',
            countermeasures: [],
            requirementIdNumber: '3.7.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '3.6 Federated Re-authentication',
            cwes: [],
            content:
              "Verify that Relying Parties (RPs) specify the maximum authentication time to Credential Service Providers (CSPs) and that CSPs re-authenticate the user if they haven't used a session within that period.",
            id: '67cfb55b-c418-4922-b889-4024e9c6d3f6',
            countermeasures: [],
            requirementIdNumber: '3.6.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '3.6 Federated Re-authentication',
            cwes: [],
            content:
              'Verify that Credential Service Providers (CSPs) inform Relying Parties (RPs) of the last authentication event, to allow RPs to determine if they need to re-authenticate the user.',
            id: '6b7792c4-45d2-4fb1-b2d4-5883cc6c5a3b',
            countermeasures: [],
            requirementIdNumber: '3.6.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '3.4 Cookie-based Session Management',
            cwes: [],
            content:
              'Verify that if the application is published under a domain name with other applications that set or use session cookies that might disclose the session cookies, set the path attribute in cookie-based session tokens using the most precise path possible. ([C6](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '9f257d69-f751-4bfe-a913-1c4e20f93e41',
            countermeasures: [],
            requirementIdNumber: '3.4.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '3.5 Token-based Session Management',
            cwes: [],
            content:
              'Verify the application uses session tokens rather than static API secrets and keys, except with legacy implementations.',
            id: 'a3826ef5-8a87-4c5f-ab9b-b864348789e9',
            countermeasures: [],
            requirementIdNumber: '3.5.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '3.3 Session Termination',
            cwes: [],
            content:
              'If authenticators permit users to remain logged in, verify that re-authentication occurs periodically both when actively used or after an idle period. ([C6](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: 'a7e069d2-2b9b-45cf-b2a7-6009edfcb284',
            countermeasures: [],
            requirementIdNumber: '3.3.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '3.2 Session Binding',
            cwes: [],
            content:
              'Verify that session tokens are generated using approved cryptographic algorithms. ([C6](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: 'ad1c0906-5fb0-40c3-b5e7-e11832f40ba0',
            countermeasures: [],
            requirementIdNumber: '3.2.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '3.4 Cookie-based Session Management',
            cwes: [],
            content:
              "Verify that cookie-based session tokens utilize the 'SameSite' attribute to limit exposure to cross-site request forgery attacks. ([C6](https://owasp.org/www-project-proactive-controls/#div-numbering))",
            id: 'b6123ae1-39bd-46c5-8c4f-ded2d73b8c57',
            countermeasures: [],
            requirementIdNumber: '3.4.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '3.1 Fundamental Session Management Security',
            cwes: [],
            content: 'Verify the application never reveals session tokens in URL parameters.',
            id: 'ee0ed919-d7de-45c6-b480-a28855976e8f',
            countermeasures: [
              {
                content: "console.log('hello world')",
                countermeasureSource: 'Manual',
                countermeasureSnippetType: 'Code',
                id: 'c5e97d60-0652-4062-a1f4-2ce51ae311f5',
                securityRequirementsIds: ['ee0ed919-d7de-45c6-b480-a28855976e8f'],
                title: 'Countermeasure',
              },
            ],
            requirementIdNumber: '3.1.1',
            severity: 'Medium',
          },
        ],
      },
      {
        controlName: '9 Communication',
        description:
          'Ensure that a verified application meets the communication high level requirements',
        id: '8a12009f-095f-4f9e-936f-b3ce5640233d',
        importanceScore: 'Medium',
        securityRequirements: [
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '9.1 Client Communication Security',
            cwes: [],
            content:
              'Verify using up to date TLS testing tools that only strong cipher suites are enabled, with the strongest cipher suites set as preferred.',
            id: '04393361-c157-4bd3-8bf7-575d92d164d5',
            countermeasures: [],
            requirementIdNumber: '9.1.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '9.1 Client Communication Security',
            cwes: [],
            content:
              'Verify that TLS is used for all client connectivity, and does not fall back to insecure or unencrypted communications. ([C8](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '06650843-cddd-44d7-aaeb-305e3f256ef1',
            countermeasures: [],
            requirementIdNumber: '9.1.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '9.2 Server Communication Security',
            cwes: [],
            content:
              'Verify that encrypted communications such as TLS is used for all inbound and outbound connections, including for management ports, monitoring, authentication, API, or web service calls, database, cloud, serverless, mainframe, external, and partner connections. The server must not fall back to insecure or unencrypted protocols.',
            id: '2d1a7ad2-3208-4a04-8b9a-d29f49d6c529',
            countermeasures: [],
            requirementIdNumber: '9.2.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '9.2 Server Communication Security',
            cwes: [],
            content:
              'Verify that connections to and from the server use trusted TLS certificates. Where internally generated or self-signed certificates are used, the server must be configured to only trust specific internal CAs and specific self-signed certificates. All others should be rejected.',
            id: '4c3768d8-3266-4f61-be13-0a285651ed5a',
            countermeasures: [],
            requirementIdNumber: '9.2.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '9.2 Server Communication Security',
            cwes: [],
            content:
              'Verify that all encrypted connections to external systems that involve sensitive information or functions are authenticated.',
            id: '62bc276e-e8b7-45fc-a1d7-9ec7b262afe9',
            countermeasures: [],
            requirementIdNumber: '9.2.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '9.1 Client Communication Security',
            cwes: [],
            content:
              'Verify that only the latest recommended versions of the TLS protocol are enabled, such as TLS 1.2 and TLS 1.3. The latest version of the TLS protocol should be the preferred option.',
            id: '8162573a-8cbf-4f5a-bdf9-4b932804dbfa',
            countermeasures: [],
            requirementIdNumber: '9.1.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '9.2 Server Communication Security',
            cwes: [],
            content:
              'Verify that proper certification revocation, such as Online Certificate Status Protocol (OCSP) Stapling, is enabled and configured.',
            id: '962f953a-e203-4f9a-9a04-52f7a735e4cc',
            countermeasures: [],
            requirementIdNumber: '9.2.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '9.2 Server Communication Security',
            cwes: [],
            content: 'Verify that backend TLS connection failures are logged.',
            id: '9bbb3969-cf11-4b02-a61f-537783963ada',
            countermeasures: [],
            requirementIdNumber: '9.2.5',
            severity: 'Medium',
          },
        ],
      },
      {
        controlName: '2 Authentication',
        description:
          'Authentication is the act of establishing, or confirming, someone (or something) as authentic and that claims made by a person or about a device are correct, resistant to impersonation, and prevent recovery or interception of passwords.',
        id: '8d70ac3b-b260-41dd-b366-86a92b32d22a',
        importanceScore: 'Medium',
        securityRequirements: [
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.7 Out of Band Verifier',
            cwes: [],
            content:
              'Verify that the out of band verifier retains only a hashed version of the authentication code.',
            id: '01d1b431-a4f3-4b99-8550-ba52b6bfda17',
            countermeasures: [],
            requirementIdNumber: '2.7.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.2 General Authenticator Security',
            cwes: [],
            content:
              'Verify that where a Credential Service Provider (CSP) and the application verifying authentication are separated, mutually authenticated TLS is in place between the two endpoints.',
            id: '01e955b4-0485-498e-bdc3-5b2bceb7f09e',
            countermeasures: [],
            requirementIdNumber: '2.2.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.4 Credential Storage',
            cwes: [],
            content:
              'Verify that if bcrypt is used, the work factor SHOULD be as large as verification server performance will allow, with a minimum of 10. ([C6](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '081109a2-18f1-4ae2-bbf6-7ead2b9f7871',
            countermeasures: [],
            requirementIdNumber: '2.4.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.1 Password Security',
            cwes: [],
            content:
              "Verify that password change functionality requires the user's current and new password.",
            id: '0d14925e-b691-4b69-9663-11eafaf58cf5',
            countermeasures: [],
            requirementIdNumber: '2.1.6',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.1 Password Security',
            cwes: [],
            content:
              'Verify that there are no password composition rules limiting the type of characters permitted. There should be no requirement for upper or lower case or numbers or special characters. ([C6](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '111dca06-5269-40eb-b090-2140a66f1042',
            countermeasures: [],
            requirementIdNumber: '2.1.9',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.1 Password Security',
            cwes: [],
            content:
              'Verify that any printable Unicode character, including language neutral characters such as spaces and Emojis are permitted in passwords.',
            id: '15da3fd1-c3a5-4230-84e8-35dc18fb0d5e',
            countermeasures: [],
            requirementIdNumber: '2.1.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.5 Credential Recovery',
            cwes: [],
            content:
              'Verify forgotten password, and other recovery paths use a secure recovery mechanism, such as time-based OTP (TOTP) or other soft token, mobile push, or another offline recovery mechanism. ([C6](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '1cca5bff-3307-417c-90ae-b20900265190',
            countermeasures: [],
            requirementIdNumber: '2.5.6',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.6 Look-up Secret Verifier',
            cwes: [],
            content:
              'Verify that lookup secrets are resistant to offline attacks, such as predictable values.',
            id: '1e673d63-c832-4bbd-9dc3-32a663120bf6',
            countermeasures: [],
            requirementIdNumber: '2.6.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.8 One Time Verifier',
            cwes: [],
            content: 'Verify that time-based OTP can be used only once within the validity period.',
            id: '1e839dbb-ce97-4d4a-9711-cab6c31ee709',
            countermeasures: [],
            requirementIdNumber: '2.8.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.7 Out of Band Verifier',
            cwes: [],
            content:
              'Verify that the out of band authenticator and verifier communicates over a secure independent channel.',
            id: '1f4772d0-523d-4578-a98f-698d8efed89b',
            countermeasures: [],
            requirementIdNumber: '2.7.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.7 Out of Band Verifier',
            cwes: [],
            content:
              'Verify that the initial authentication code is generated by a secure random number generator, containing at least 20 bits of entropy (typically a six digital random number is sufficient).',
            id: '26319f43-0318-459a-b32e-3cb7a7c10c55',
            countermeasures: [],
            requirementIdNumber: '2.7.6',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.1 Password Security',
            cwes: [],
            content:
              'Verify that there are no periodic credential rotation or password history requirements.',
            id: '2c447b45-622b-4179-9899-950670ae21c9',
            countermeasures: [],
            requirementIdNumber: '2.1.10',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.5 Credential Recovery',
            cwes: [],
            content:
              'Verify password credential recovery does not reveal the current password in any way. ([C6](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '2e0db46c-f0b6-4a5b-87b9-f07644e070ff',
            countermeasures: [],
            requirementIdNumber: '2.5.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.5 Credential Recovery',
            cwes: [],
            content:
              'Verify password hints or knowledge-based authentication (so-called "secret questions") are not present.',
            id: '33abb297-5b74-411f-a9d6-96d288f68836',
            countermeasures: [],
            requirementIdNumber: '2.5.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.5 Credential Recovery',
            cwes: [],
            content:
              'Verify that a system generated initial activation or recovery secret is not sent in clear text to the user. ([C6](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '35a54b3a-dd5f-4370-8c51-854b0d71ab29',
            countermeasures: [],
            requirementIdNumber: '2.5.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.5 Credential Recovery',
            cwes: [],
            content:
              'Verify that if OTP or multi-factor authentication factors are lost, that evidence of identity proofing is performed at the same level as during enrollment.',
            id: '36200531-e431-4983-86aa-051679704252',
            countermeasures: [],
            requirementIdNumber: '2.5.7',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.4 Credential Storage',
            cwes: [],
            content:
              'Verify that the salt is at least 32 bits in length and be chosen arbitrarily to minimize salt value collisions among stored hashes. For each credential, a unique salt value and the resulting hash SHALL be stored. ([C6](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '48073978-a110-4f78-b982-12b41cc594af',
            countermeasures: [],
            requirementIdNumber: '2.4.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.1 Password Security',
            cwes: [],
            content: 'Verify users can change their password.',
            id: '4870ec47-34bd-40e5-978c-09c62cccd3b5',
            countermeasures: [],
            requirementIdNumber: '2.1.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.9 Cryptographic Verifier',
            cwes: [],
            content:
              'Verify that approved cryptographic algorithms are used in the generation, seeding, and verification.',
            id: '4e34a4be-0727-4b0b-82cd-9c9417ff0b50',
            countermeasures: [],
            requirementIdNumber: '2.9.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.4 Credential Storage',
            cwes: [],
            content:
              'Verify that if PBKDF2 is used, the iteration count SHOULD be as large as verification server performance will allow, typically at least 100,000 iterations. ([C6](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '5419499d-504d-4f14-b7d3-0d433b73dbab',
            countermeasures: [],
            requirementIdNumber: '2.4.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.7 Out of Band Verifier',
            cwes: [],
            content:
              'Verify that clear text out of band (NIST "restricted") authenticators, such as SMS or PSTN, are not offered by default, and stronger alternatives such as push notifications are offered first.',
            id: '565fc123-66f0-45f1-8940-18c090bd7425',
            countermeasures: [],
            requirementIdNumber: '2.7.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.6 Look-up Secret Verifier',
            cwes: [],
            content: 'Verify that lookup secrets can be used only once.',
            id: '61563702-9153-4e87-94ca-219add8efe13',
            countermeasures: [],
            requirementIdNumber: '2.6.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.8 One Time Verifier',
            cwes: [],
            content:
              'Verify that if a time-based multi-factor OTP token is re-used during the validity period, it is logged and rejected with secure notifications being sent to the holder of the device.',
            id: '618a1d94-faec-41bd-9089-a839f19eabca',
            countermeasures: [],
            requirementIdNumber: '2.8.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.8 One Time Verifier',
            cwes: [],
            content:
              'Verify physical single-factor OTP generator can be revoked in case of theft or other loss. Ensure that revocation is immediately effective across logged in sessions, regardless of location.',
            id: '6ea442dd-cf2d-49a2-9967-67d5be857c24',
            countermeasures: [],
            requirementIdNumber: '2.8.6',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.10 Service Authentication',
            cwes: [],
            content:
              'Verify passwords, integrations with databases and third-party systems, seeds and internal secrets, and API keys are managed securely and not included in the source code or stored within source code repositories. Such storage SHOULD resist offline attacks. The use of a secure software key store (L1), hardware TPM, or an HSM (L3) is recommended for password storage.',
            id: '7283bbe9-56cf-438f-9de8-bef48caed98d',
            countermeasures: [],
            requirementIdNumber: '2.10.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.1 Password Security',
            cwes: [],
            content:
              "Verify that passwords submitted during account registration, login, and password change are checked against a set of breached passwords either locally (such as the top 1,000 or 10,000 most common passwords which match the system's password policy) or using an external API. If using an API a zero knowledge proof or other mechanism should be used to ensure that the plain text password is not sent or used in verifying the breach status of the password. If the password is breached, the application must require the user to set a new non-breached password. ([C6](https://owasp.org/www-project-proactive-controls/#div-numbering))",
            id: '78c08297-81ea-476a-bb7e-07b909343b63',
            countermeasures: [],
            requirementIdNumber: '2.1.7',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.8 One Time Verifier',
            cwes: [],
            content:
              'Verify that biometric authenticators are limited to use only as secondary factors in conjunction with either something you have and something you know.',
            id: '7d51bd30-4861-48a8-a545-5f7e66df327c',
            countermeasures: [],
            requirementIdNumber: '2.8.7',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.10 Service Authentication',
            cwes: [],
            content:
              'Verify that if passwords are required for service authentication, the service account used is not a default credential. (e.g. root/root or admin/admin are default in some services during installation).',
            id: '85424320-a575-44ff-9251-0ae36a859040',
            countermeasures: [],
            requirementIdNumber: '2.10.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.1 Password Security',
            cwes: [],
            content:
              'Verify that a password strength meter is provided to help users set a stronger password.',
            id: '8585c982-d6d5-454c-9f42-594254ed0f74',
            countermeasures: [],
            requirementIdNumber: '2.1.8',
            severity: 'Medium',
          },
          {
            activityStatus: 'Inactive',
            antiPatterns: [],
            categoryName: '2.2 General Authenticator Security',
            cwes: [],
            content:
              'Verify that anti-automation controls are effective at mitigating breached credential testing, brute force, and account lockout attacks. Such controls include blocking the most common breached passwords, soft lockouts, rate limiting, CAPTCHA, ever increasing delays between attempts, IP address restrictions, or risk-based restrictions such as location, first login on a device, recent attempts to unlock the account, or similar. Verify that no more than 100 failed attempts per hour is possible on a single account.',
            id: '981db8b1-b0b1-4dea-a4f0-efe4cbb6b475',
            countermeasures: [],
            requirementIdNumber: '2.2.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.3 Authenticator Lifecycle',
            cwes: [],
            content:
              'Verify that renewal instructions are sent with sufficient time to renew time bound authenticators.',
            id: '995ae1cf-d1ea-410d-ab99-4eecaa07e72c',
            countermeasures: [],
            requirementIdNumber: '2.3.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.9 Cryptographic Verifier',
            cwes: [],
            content:
              'Verify that cryptographic keys used in verification are stored securely and protected against disclosure, such as using a Trusted Platform Module (TPM) or Hardware Security Module (HSM), or an OS service that can use this secure storage.',
            id: '9c908568-3e0a-4bac-8425-4fc72a8aec13',
            countermeasures: [],
            requirementIdNumber: '2.9.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.4 Credential Storage',
            cwes: [],
            content:
              'Verify that passwords are stored in a form that is resistant to offline attacks. Passwords SHALL be salted and hashed using an approved one-way key derivation or password hashing function. Key derivation and password hashing functions take a password, a salt, and a cost factor as inputs when generating a password hash. ([C6](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '9d300085-746b-4772-bc1b-08b45bf5514e',
            countermeasures: [],
            requirementIdNumber: '2.4.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.1 Password Security',
            cwes: [],
            content:
              'Verify that the user can choose to either temporarily view the entire masked password, or temporarily view the last typed character of the password on platforms that do not have this as built-in functionality.',
            id: '9d752d16-fff5-41de-9716-7f34bb9ef7b6',
            countermeasures: [],
            requirementIdNumber: '2.1.12',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.8 One Time Verifier',
            cwes: [],
            content: 'Verify that time-based OTPs have a defined lifetime before expiring.',
            id: '9f9f7a9b-7683-4050-a454-f792f10d37a7',
            countermeasures: [],
            requirementIdNumber: '2.8.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.2 General Authenticator Security',
            cwes: [],
            content:
              'Verify that the use of weak authenticators (such as SMS and email) is limited to secondary verification and transaction approval and not as a replacement for more secure authentication methods. Verify that stronger methods are offered before weak methods, users are aware of the risks, or that proper measures are in place to limit the risks of account compromise.',
            id: 'a0b35bf8-6a2e-4801-b988-e1901aa52c83',
            countermeasures: [],
            requirementIdNumber: '2.2.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Inactive',
            antiPatterns: [],
            categoryName: '2.1 Password Security',
            cwes: [],
            content:
              'Verify that password truncation is not performed. However, consecutive multiple spaces may be replaced by a single space. ([C6](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: 'af1baaa7-da98-4bf9-9b4a-8fac8f74f33c',
            countermeasures: [],
            requirementIdNumber: '2.1.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.1 Password Security',
            cwes: [],
            content:
              'Verify that "paste" functionality, browser password helpers, and external password managers are permitted.',
            id: 'af48674d-86b0-4208-8d2d-dba7284a19dc',
            countermeasures: [],
            requirementIdNumber: '2.1.11',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.5 Credential Recovery',
            cwes: [],
            content:
              'Verify that if an authentication factor is changed or replaced, that the user is notified of this event.',
            id: 'b2485ba6-4801-4978-b315-b894087b6100',
            countermeasures: [],
            requirementIdNumber: '2.5.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.10 Service Authentication',
            cwes: [],
            content:
              'Verify that intra-service secrets do not rely on unchanging credentials such as passwords, API keys or shared accounts with privileged access.',
            id: 'ba920351-a74a-4f81-85cd-6bb185192180',
            countermeasures: [],
            requirementIdNumber: '2.10.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.4 Credential Storage',
            cwes: [],
            content:
              'Verify that an additional iteration of a key derivation function is performed, using a salt value that is secret and known only to the verifier. Generate the salt value using an approved random bit generator [SP 800-90Ar1] and provide at least the minimum security strength specified in the latest revision of SP 800-131A. The secret salt value SHALL be stored separately from the hashed passwords (e.g., in a specialized device like a hardware security module).',
            id: 'bfeccacb-33f3-4359-b05d-cb7c28932d90',
            countermeasures: [],
            requirementIdNumber: '2.4.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.1 Password Security',
            cwes: ['CWE-290', 'CWE-287'],
            content: 'dsadasdas',
            id: 'c11e5b87-38f0-4a9e-b37e-46b7f825eba3',
            countermeasures: [
              {
                content: '123',
                countermeasureSource: 'Manual',
                countermeasureSnippetType: 'Code',
                id: '6195b0c6-caf5-4231-8b8d-6738c6cbd166',
                securityRequirementsIds: ['c11e5b87-38f0-4a9e-b37e-46b7f825eba3'],
                title: '123',
              },
              {
                content: 'asdasd',
                countermeasureSource: 'Manual',
                countermeasureSnippetType: 'Documentation',
                id: '7918f85a-a16b-4d29-bbd8-124055520d51',
                securityRequirementsIds: ['c11e5b87-38f0-4a9e-b37e-46b7f825eba3'],
                title: 'asdasd',
              },
            ],
            description: '124356',
            requirementIdNumber: '2.1.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.3 Authenticator Lifecycle',
            cwes: [],
            content:
              'Verify that enrollment and use of user-provided authentication devices are supported, such as a U2F or FIDO tokens.',
            id: 'c19170ec-982a-46e7-a120-3de28ad6a2f7',
            countermeasures: [],
            requirementIdNumber: '2.3.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.6 Look-up Secret Verifier',
            cwes: [],
            content:
              'Verify that lookup secrets have sufficient randomness (112 bits of entropy), or if less than 112 bits of entropy, salted with a unique and random 32-bit salt and hashed with an approved one-way hash.',
            id: 'c396c8fb-1aa3-4cd8-b833-570f9976cc2a',
            countermeasures: [],
            requirementIdNumber: '2.6.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.2 General Authenticator Security',
            cwes: [],
            content:
              'Verify intent to authenticate by requiring the entry of an OTP token or user-initiated action such as a button press on a FIDO hardware key.',
            id: 'cb86581d-9a2e-41d2-9d93-787a2d4eb829',
            countermeasures: [],
            requirementIdNumber: '2.2.7',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.3 Authenticator Lifecycle',
            cwes: [],
            content:
              'Verify system generated initial passwords or activation codes SHOULD be securely randomly generated, SHOULD be at least 6 characters long, and MAY contain letters and numbers, and expire after a short period of time. These initial secrets must not be permitted to become the long term password.',
            id: 'cba2e592-e2ab-4590-bda2-c167cfc3a5b1',
            countermeasures: [],
            requirementIdNumber: '2.3.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Inactive',
            antiPatterns: [],
            categoryName: '2.1 Password Security',
            cwes: [],
            content:
              'Verify that passwords of at least 64 characters are permitted, and that passwords of more than 128 characters are denied. ([C6](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: 'cf491b2d-6202-4c6e-80fb-85cc23713a70',
            countermeasures: [],
            requirementIdNumber: '2.1.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.8 One Time Verifier',
            cwes: [],
            content:
              'Verify that symmetric keys used to verify submitted OTPs are highly protected, such as by using a hardware security module or secure operating system based key storage.',
            id: 'd88822f9-1f9e-41dd-82e6-2d5a77068142',
            countermeasures: [],
            requirementIdNumber: '2.8.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.8 One Time Verifier',
            cwes: [],
            content:
              'Verify that approved cryptographic algorithms are used in the generation, seeding, and verification of OTPs.',
            id: 'd9e27d77-7523-441f-b391-a8c117d8d6b3',
            countermeasures: [],
            requirementIdNumber: '2.8.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.7 Out of Band Verifier',
            cwes: [],
            content:
              'Verify that the out of band verifier authentication requests, codes, or tokens are only usable once, and only for the original authentication request.',
            id: 'dcf4f94b-d5b8-4647-847e-69289eadbcd3',
            countermeasures: [],
            requirementIdNumber: '2.7.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.2 General Authenticator Security',
            cwes: [],
            content:
              'Verify replay resistance through the mandated use of One-time Passwords (OTP) devices, cryptographic authenticators, or lookup codes.',
            id: 'e508131e-eb14-4761-bf83-8b6bef360b45',
            countermeasures: [],
            requirementIdNumber: '2.2.6',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.2 General Authenticator Security',
            cwes: [],
            content:
              'Verify impersonation resistance against phishing, such as the use of multi-factor authentication, cryptographic devices with intent (such as connected keys with a push to authenticate), or at higher AAL levels, client-side certificates.',
            id: 'e5f53349-4b26-498d-af3a-0a5f337e34ff',
            countermeasures: [],
            requirementIdNumber: '2.2.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.10 Service Authentication',
            cwes: [],
            content:
              'Verify that passwords are stored with sufficient protection to prevent offline recovery attacks, including local system access.',
            id: 'e69f22d5-270a-4e53-9f11-ac8831748755',
            countermeasures: [],
            requirementIdNumber: '2.10.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Inactive',
            antiPatterns: [],
            categoryName: '2.2 General Authenticator Security',
            cwes: [],
            content:
              'Verify that secure notifications are sent to users after updates to authentication details, such as credential resets, email or address changes, logging in from unknown or risky locations. The use of push notifications - rather than SMS or email - is preferred, but in the absence of push notifications, SMS or email is acceptable as long as no sensitive information is disclosed in the notification.',
            id: 'e8f9af49-9519-4675-a84f-33c96f099e1e',
            countermeasures: [],
            requirementIdNumber: '2.2.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.7 Out of Band Verifier',
            cwes: [],
            content:
              'Verify that the out of band verifier expires out of band authentication requests, codes, or tokens after 10 minutes.',
            id: 'ecc31df4-62e3-4241-86ee-2900faebaf71',
            countermeasures: [],
            requirementIdNumber: '2.7.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.9 Cryptographic Verifier',
            cwes: [],
            content:
              'Verify that the challenge nonce is at least 64 bits in length, and statistically unique or unique over the lifetime of the cryptographic device.',
            id: 'f128ed5c-27a7-43af-9b41-2683c6b03e8a',
            countermeasures: [],
            requirementIdNumber: '2.9.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '2.5 Credential Recovery',
            cwes: [],
            content:
              'Verify shared or default accounts are not present (e.g. "root", "admin", or "sa").',
            id: 'f3d70576-b067-41a4-b671-48132afbe949',
            countermeasures: [],
            requirementIdNumber: '2.5.4',
            severity: 'Medium',
          },
        ],
      },
      {
        controlName: '8 Data Protection',
        description:
          'There are three key elements to sound data protection: Confidentiality, Integrity and Availability (CIA). This standard assumes that data protection is enforced on a trusted system, such as a server, which has been hardened and has sufficient protections.',
        id: 'cf586d84-1300-4223-873b-a95a3d7a7eaf',
        importanceScore: 'Medium',
        securityRequirements: [
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '8.1 General Data Protection',
            cwes: [],
            content:
              'Verify that backups are stored securely to prevent data from being stolen or corrupted.',
            id: '034cfd43-68a7-42af-8dfc-df7aa5c41f43',
            countermeasures: [],
            requirementIdNumber: '8.1.6',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '8.1 General Data Protection',
            cwes: [],
            content:
              'Verify the application protects sensitive data from being cached in server components such as load balancers and application caches.',
            id: '130d6ce1-3f11-4114-82e9-e667d9dab3b3',
            countermeasures: [],
            requirementIdNumber: '8.1.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '8.3 Sensitive Private Data',
            cwes: [],
            content:
              'Verify that sensitive or private information that is required to be encrypted, is encrypted using approved algorithms that provide both confidentiality and integrity. ([C8](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '14acdf51-c6be-4cb9-a861-1bdcf1011128',
            countermeasures: [],
            requirementIdNumber: '8.3.7',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '8.2 Client-side Data Protection',
            cwes: [],
            content:
              'Verify the application sets sufficient anti-caching headers so that sensitive data is not cached in modern browsers.',
            id: '23d72002-afdd-4420-b339-85dc6b198851',
            countermeasures: [],
            requirementIdNumber: '8.2.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '8.3 Sensitive Private Data',
            cwes: [],
            content:
              'Verify that sensitive data is sent to the server in the HTTP message body or headers, and that query string parameters from any HTTP verb do not contain sensitive data.',
            id: '2d27c5d2-8388-46f4-87d0-bc789da06bb2',
            countermeasures: [],
            requirementIdNumber: '8.3.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '8.1 General Data Protection',
            cwes: [],
            content:
              'Verify the application can detect and alert on abnormal numbers of requests, such as by IP, user, total per hour or day, or whatever makes sense for the application.',
            id: '2e525b4b-d658-45c5-80c7-a1dfd3b2055d',
            countermeasures: [],
            requirementIdNumber: '8.1.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '8.1 General Data Protection',
            cwes: [],
            content:
              'Verify the application minimizes the number of parameters in a request, such as hidden fields, Ajax variables, cookies and header values.',
            id: '4f429e3b-91c8-4590-beac-e553b42e337e',
            countermeasures: [],
            requirementIdNumber: '8.1.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '8.3 Sensitive Private Data',
            cwes: [],
            content:
              'Verify that users are provided clear language regarding collection and use of supplied personal information and that users have provided opt-in consent for the use of that data before it is used in any way.',
            id: '681b1ada-3b21-4de3-8449-4c9eab24853a',
            countermeasures: [],
            requirementIdNumber: '8.3.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '8.1 General Data Protection',
            cwes: [],
            content:
              'Verify that regular backups of important data are performed and that test restoration of data is performed.',
            id: '6a38f00e-a80e-4c09-b234-9535d37bfc60',
            countermeasures: [],
            requirementIdNumber: '8.1.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '8.2 Client-side Data Protection',
            cwes: [],
            content:
              'Verify that data stored in browser storage (such as localStorage, sessionStorage, IndexedDB, or cookies) does not contain sensitive data.',
            id: '6adedcb1-edd9-4781-abb7-1ba52b1041a0',
            countermeasures: [],
            requirementIdNumber: '8.2.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '8.3 Sensitive Private Data',
            cwes: [],
            content:
              'Verify accessing sensitive data is audited (without logging the sensitive data itself), if the data is collected under relevant data protection directives or where logging of access is required.',
            id: '8d279302-324f-4c2f-8935-039eece4fead',
            countermeasures: [],
            requirementIdNumber: '8.3.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '8.3 Sensitive Private Data',
            cwes: [],
            content:
              'Verify that sensitive information contained in memory is overwritten as soon as it is no longer required to mitigate memory dumping attacks, using zeroes or random data.',
            id: '8d847912-45ef-4da4-88c9-e32716258597',
            countermeasures: [],
            requirementIdNumber: '8.3.6',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '8.3 Sensitive Private Data',
            cwes: [],
            content:
              'Verify that sensitive personal information is subject to data retention classification, such that old or out of date data is deleted automatically, on a schedule, or as the situation requires.',
            id: '9a596004-be5a-4eb9-9aa4-f71f9a639143',
            countermeasures: [],
            requirementIdNumber: '8.3.8',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '8.3 Sensitive Private Data',
            cwes: [],
            content:
              'Verify that all sensitive data created and processed by the application has been identified, and ensure that a policy is in place on how to deal with sensitive data. ([C8](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: 'a6e31860-664a-4871-b5fd-11da608aa408',
            countermeasures: [],
            requirementIdNumber: '8.3.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '8.1 General Data Protection',
            cwes: [],
            content:
              'Verify that all cached or temporary copies of sensitive data stored on the server are protected from unauthorized access or purged/invalidated after the authorized user accesses the sensitive data.',
            id: 'c7416f6b-665b-47aa-b56d-865e332492de',
            countermeasures: [],
            requirementIdNumber: '8.1.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '8.2 Client-side Data Protection',
            cwes: [],
            content:
              'Verify that authenticated data is cleared from client storage, such as the browser DOM, after the client or session is terminated.',
            id: 'dac574ee-8036-4d1e-ab4b-9f190cf73335',
            countermeasures: [],
            requirementIdNumber: '8.2.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '8.3 Sensitive Private Data',
            cwes: [],
            content: 'Verify that users have a method to remove or export their data on demand.',
            id: 'e73b98dc-15fb-48de-98ec-7be938cf73df',
            countermeasures: [],
            requirementIdNumber: '8.3.2',
            severity: 'Medium',
          },
        ],
      },
      {
        controlName: '10 Malicious Code',
        description: 'Ensure that code satisfies malicious code high level requirements',
        id: 'dcc77239-9b6e-4ea5-8ec8-f611def700d5',
        importanceScore: 'Medium',
        securityRequirements: [
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '10.3 Application Integrity',
            cwes: [],
            content:
              'Verify that the application has protection from subdomain takeovers if the application relies upon DNS entries or DNS subdomains, such as expired domain names, out of date DNS pointers or CNAMEs, expired projects at public source code repos, or transient cloud APIs, serverless functions, or storage buckets (*autogen-bucket-id*.cloud.example.com) or similar. Protections can include ensuring that DNS names used by applications are regularly checked for expiry or change.',
            id: '0323fc9c-414b-4f54-a036-a11fc7a5585f',
            countermeasures: [],
            requirementIdNumber: '10.3.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '10.3 Application Integrity',
            cwes: [],
            content:
              'Verify that if the application has a client or server auto-update feature, updates should be obtained over secure channels and digitally signed. The update code must validate the digital signature of the update before installing or executing the update.',
            id: '15072814-20d9-48fc-8880-16c379dacdea',
            countermeasures: [],
            requirementIdNumber: '10.3.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '10.2 Malicious Code Search',
            cwes: [],
            content:
              'Verify that the application source code and third party libraries do not contain Easter eggs or any other potentially unwanted functionality.',
            id: '289be978-eaac-43e1-b3e0-22657da91cb4',
            countermeasures: [],
            requirementIdNumber: '10.2.6',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '10.2 Malicious Code Search',
            cwes: [],
            content:
              'Verify that the application source code and third party libraries do not contain malicious code, such as salami attacks, logic bypasses, or logic bombs.',
            id: '5d420ea1-4e83-4e92-a7be-4cd23f4065ba',
            countermeasures: [],
            requirementIdNumber: '10.2.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '10.3 Application Integrity',
            cwes: [],
            content:
              'Verify that the application employs integrity protections, such as code signing or subresource integrity. The application must not load or execute code from untrusted sources, such as loading includes, modules, plugins, code, or libraries from untrusted sources or the Internet.',
            id: '8ba322b9-9f43-43a4-aa04-abec2e8d540f',
            countermeasures: [],
            requirementIdNumber: '10.3.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '10.1 Code Integrity',
            cwes: [],
            content:
              'Verify that a code analysis tool is in use that can detect potentially malicious code, such as time functions, unsafe file operations and network connections.',
            id: '9522ae94-e23b-43ca-998f-1fff060b831d',
            countermeasures: [],
            requirementIdNumber: '10.1.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '10.2 Malicious Code Search',
            cwes: [],
            content:
              'Verify that the application source code and third party libraries do not contain time bombs by searching for date and time related functions.',
            id: 'a203ba1b-b71a-45b7-99f8-ec062db5dcf7',
            countermeasures: [],
            requirementIdNumber: '10.2.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '10.2 Malicious Code Search',
            cwes: [],
            content:
              'Verify that the application source code and third party libraries do not contain back doors, such as hard-coded or additional undocumented accounts or keys, code obfuscation, undocumented binary blobs, rootkits, or anti-debugging, insecure debugging features, or otherwise out of date, insecure, or hidden functionality that could be used maliciously if discovered.',
            id: 'ed8794bb-2455-4219-9e0f-7e742474da44',
            countermeasures: [],
            requirementIdNumber: '10.2.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '10.2 Malicious Code Search',
            cwes: [],
            content:
              'Verify that the application does not ask for unnecessary or excessive permissions to privacy related features or sensors, such as contacts, cameras, microphones, or location.',
            id: 'f526b70c-78cf-4ec4-af00-1538d05a2e72',
            countermeasures: [],
            requirementIdNumber: '10.2.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '10.2 Malicious Code Search',
            cwes: [],
            content:
              "Verify that the application source code and third party libraries do not contain unauthorized phone home or data collection capabilities. Where such functionality exists, obtain the user's permission for it to operate before collecting any data.",
            id: 'f8481495-9468-4d61-b98a-89dc6bd4aa21',
            countermeasures: [],
            requirementIdNumber: '10.2.1',
            severity: 'Medium',
          },
        ],
      },
      {
        controlName: '7 Error Handling and Logging',
        description:
          'The primary objective of error handling and logging is to provide useful information for the user, administrators, and incident response teams. The objective is not to create massive amounts of logs, but high quality logs, with more signal than discarded noise.',
        id: 'e4ec2b9b-3f57-4185-81a4-3f969e7da00f',
        importanceScore: 'Medium',
        securityRequirements: [
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '7.3 Log Protection',
            cwes: [],
            content:
              'Verify that time sources are synchronized to the correct time and time zone. Strongly consider logging only in UTC if systems are global to assist with post-incident forensic analysis. ([C9](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '10ad7259-e067-408c-aae8-43532cc23dcd',
            countermeasures: [],
            requirementIdNumber: '7.3.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '7.2 Log Processing',
            cwes: [],
            content:
              'Verify that all authentication decisions are logged, without storing sensitive session tokens or passwords. This should include requests with relevant metadata needed for security investigations.',
            id: '3a2204ca-cd18-448f-b4dd-67390245ebc1',
            countermeasures: [],
            requirementIdNumber: '7.2.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '7.4 Error Handling',
            cwes: [],
            content:
              'Verify that exception handling (or a functional equivalent) is used across the codebase to account for expected and unexpected error conditions. ([C10](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '3f2659b7-efc3-46df-b0dc-9d80a521bede',
            countermeasures: [],
            requirementIdNumber: '7.4.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '7.4 Error Handling',
            cwes: [],
            content:
              'Verify that a "last resort" error handler is defined which will catch all unhandled exceptions. ([C10](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '50ed6d6f-7cb6-451d-bd18-c1f375d995c2',
            countermeasures: [],
            requirementIdNumber: '7.4.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '7.3 Log Protection',
            cwes: [],
            content:
              'Verify that security logs are protected from unauthorized access and modification. ([C9](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '57090711-c8f0-4ae6-83a4-7122e0f86832',
            countermeasures: [],
            requirementIdNumber: '7.3.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '7.4 Error Handling',
            cwes: [],
            content:
              'Verify that a generic message is shown when an unexpected or security sensitive error occurs, potentially with a unique ID which support personnel can use to investigate. ([C10](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '67f29f34-760b-4e8b-901f-ff251c1954bf',
            countermeasures: [],
            requirementIdNumber: '7.4.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '7.1 Log Content',
            cwes: [],
            content:
              'Verify that each log event includes necessary information that would allow for a detailed investigation of the timeline when an event happens. ([C9](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '75794de4-6764-4b72-a77c-f02181ab6573',
            countermeasures: [],
            requirementIdNumber: '7.1.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '7.3 Log Protection',
            cwes: [],
            content:
              'Verify that all logging components appropriately encode data to prevent log injection. ([C9](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '8cc1b66a-ad68-4ac7-8a3e-8cefe3932476',
            countermeasures: [],
            requirementIdNumber: '7.3.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '7.1 Log Content',
            cwes: [],
            content:
              'Verify that the application does not log other sensitive data as defined under local privacy laws or relevant security policy. ([C9](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '8de6a8fa-2178-47dc-a9ed-a7419dd79e02',
            countermeasures: [],
            requirementIdNumber: '7.1.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '7.1 Log Content',
            cwes: [],
            content:
              'Verify that the application does not log credentials or payment details. Session tokens should only be stored in logs in an irreversible, hashed form. ([C9, C10](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: 'afd8a07e-20d3-4bde-858e-20abf7c486a3',
            countermeasures: [],
            requirementIdNumber: '7.1.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '7.2 Log Processing',
            cwes: [],
            content:
              'Verify that all access control decisions can be logged and all failed decisions are logged. This should include requests with relevant metadata needed for security investigations.',
            id: 'd5c9f5ba-22d7-4ba5-8304-ed890bb4477b',
            countermeasures: [],
            requirementIdNumber: '7.2.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '7.1 Log Content',
            cwes: [],
            content:
              'Verify that the application logs security relevant events including successful and failed authentication events, access control failures, deserialization failures and input validation failures. ([C5, C7](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: 'fb849ef0-81c3-4ba4-b98b-3f85b0f2990f',
            countermeasures: [],
            requirementIdNumber: '7.1.3',
            severity: 'Medium',
          },
        ],
      },
      {
        controlName: '5 Validation, Sanitization and Encoding',
        description:
          'The most common web application security weakness is the failure to properly validate input coming from the client or the environment before directly using it without any output encoding. This weakness leads to almost all of the significant vulnerabilities in web applications, such as Cross-Site Scripting (XSS), SQL injection, interpreter injection, locale/Unicode attacks, file system attacks, and buffer overflows.',
        id: 'f27d05fd-86cd-4c4a-b609-3b5b029f85b2',
        importanceScore: 'Medium',
        securityRequirements: [
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.4 Memory, String, and Unmanaged Code',
            cwes: [],
            content:
              'Verify that sign, range, and input validation techniques are used to prevent integer overflows.',
            id: '061cc53e-bbc9-478e-9894-ed67123cf128',
            countermeasures: [],
            requirementIdNumber: '5.4.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.2 Sanitization and Sandboxing',
            cwes: [],
            content:
              'Verify that all untrusted HTML input from WYSIWYG editors or similar is properly sanitized with an HTML sanitizer library or framework feature. ([C5](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '0885b512-27da-427c-8584-f37ffc3648c4',
            countermeasures: [],
            requirementIdNumber: '5.2.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.3 Output Encoding and Injection Prevention',
            cwes: [],
            content:
              'Verify that the application protects against XPath injection or XML injection attacks. ([C4](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '16cd96d7-566e-49a6-9e54-f7dfb56ee8c0',
            countermeasures: [],
            requirementIdNumber: '5.3.10',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.4 Memory, String, and Unmanaged Code',
            cwes: [],
            content:
              'Verify that the application uses memory-safe string, safer memory copy and pointer arithmetic to detect or prevent stack, buffer, or heap overflows.',
            id: '1f82d6b9-ccd2-4d65-aed0-46a50addb58d',
            countermeasures: [],
            requirementIdNumber: '5.4.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.2 Sanitization and Sandboxing',
            cwes: [],
            content:
              'Verify that the application sanitizes, disables, or sandboxes user-supplied Scalable Vector Graphics (SVG) scriptable content, especially as they relate to XSS resulting from inline scripts, and foreignObject.',
            id: '24d3dd8f-6ef4-42bd-be73-3bb6fd4eca3b',
            countermeasures: [],
            requirementIdNumber: '5.2.7',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.3 Output Encoding and Injection Prevention',
            cwes: [],
            content:
              'Verify that the application protects against LDAP injection vulnerabilities, or that specific security controls to prevent LDAP injection have been implemented. ([C4](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '2553f814-be9e-4159-bc02-9281cb4d14c9',
            countermeasures: [],
            requirementIdNumber: '5.3.7',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.3 Output Encoding and Injection Prevention',
            cwes: [],
            content:
              "Verify that output encoding is relevant for the interpreter and context required. For example, use encoders specifically for HTML values, HTML attributes, JavaScript, URL parameters, HTTP headers, SMTP, and others as the context requires, especially from untrusted inputs (e.g. names with Unicode or apostrophes, such as „Å≠„Åì or O'Hara). ([C4](https://owasp.org/www-project-proactive-controls/#div-numbering))",
            id: '2a9864a5-88e5-49f9-ba79-90655c157b77',
            countermeasures: [],
            requirementIdNumber: '5.3.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.3 Output Encoding and Injection Prevention',
            cwes: [],
            content:
              'Verify that context-aware, preferably automated - or at worst, manual - output escaping protects against reflected, stored, and DOM based XSS. ([C4](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '34a5eda4-5b22-4c5f-944f-3a361725557b',
            countermeasures: [],
            requirementIdNumber: '5.3.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.5 Deserialization Prevention',
            cwes: [],
            content:
              'Verify that deserialization of untrusted data is avoided or is protected in both custom code and third-party libraries (such as JSON, XML and YAML parsers).',
            id: '39a26b40-6010-45d8-ba81-fa3929e92052',
            countermeasures: [],
            requirementIdNumber: '5.5.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.4 Memory, String, and Unmanaged Code',
            cwes: [],
            content:
              'Verify that format strings do not take potentially hostile input, and are constant.',
            id: '3c1c2c7b-a43c-4e67-b2dc-cac201d44cc5',
            countermeasures: [],
            requirementIdNumber: '5.4.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.3 Output Encoding and Injection Prevention',
            cwes: [],
            content:
              "Verify that output encoding preserves the user's chosen character set and locale, such that any Unicode character point is valid and safely handled. ([C4](https://owasp.org/www-project-proactive-controls/#div-numbering))",
            id: '44d2ac7f-4948-491c-a61f-1ac3bcd2daf3',
            countermeasures: [],
            requirementIdNumber: '5.3.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.5 Deserialization Prevention',
            cwes: [],
            content:
              'Verify that serialized objects use integrity checks or are encrypted to prevent hostile object creation or data tampering. ([C5](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '55b2c067-0e77-404d-b8f8-1cc3a85e4e85',
            countermeasures: [],
            requirementIdNumber: '5.5.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.2 Sanitization and Sandboxing',
            cwes: [],
            content:
              'Verify that the application sanitizes, disables, or sandboxes user-supplied scriptable or expression template language content, such as Markdown, CSS or XSL stylesheets, BBCode, or similar.',
            id: '571208b5-64d0-4d88-94d2-fdb63e99a543',
            countermeasures: [],
            requirementIdNumber: '5.2.8',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.2 Sanitization and Sandboxing',
            cwes: [],
            content:
              'Verify that the application sanitizes user input before passing to mail systems to protect against SMTP or IMAP injection.',
            id: '5bfee67c-8a7c-4030-be4e-29785be3c00b',
            countermeasures: [],
            requirementIdNumber: '5.2.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.5 Deserialization Prevention',
            cwes: [],
            content:
              'Verify that the application correctly restricts XML parsers to only use the most restrictive configuration possible and to ensure that unsafe features such as resolving external entities are disabled to prevent XML eXternal Entity (XXE) attacks.',
            id: '5dc9c381-37f7-43b8-bad7-d83d514c5810',
            countermeasures: [],
            requirementIdNumber: '5.5.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.1 Input Validation',
            cwes: [],
            content:
              'Verify that the application has defenses against HTTP parameter pollution attacks, particularly if the application framework makes no distinction about the source of request parameters (GET, POST, cookies, headers, or environment variables).',
            id: '5f0d93f2-61d6-47a9-ae26-03efcd914fe5',
            countermeasures: [],
            requirementIdNumber: '5.1.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.1 Input Validation',
            cwes: [],
            content:
              'Verify that frameworks protect against mass parameter assignment attacks, or that the application has countermeasures to protect against unsafe parameter assignment, such as marking fields private or similar. ([C5](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '713995fb-6688-454f-8fed-f886c38c7f76',
            countermeasures: [],
            requirementIdNumber: '5.1.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.2 Sanitization and Sandboxing',
            cwes: [],
            content:
              'Verify that the application protects against SSRF attacks, by validating or sanitizing untrusted data or HTTP file metadata, such as filenames and URL input fields, and uses allow lists of protocols, domains, paths and ports.',
            id: '75153ecb-17fe-4a8a-a817-8108db141069',
            countermeasures: [],
            requirementIdNumber: '5.2.6',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.2 Sanitization and Sandboxing',
            cwes: [],
            content:
              'Verify that the application avoids the use of eval() or other dynamic code execution features. Where there is no alternative, any user input being included must be sanitized or sandboxed before being executed.',
            id: '7704722e-f085-49e4-8b63-c7733ff1020e',
            countermeasures: [],
            requirementIdNumber: '5.2.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.1 Input Validation',
            cwes: [],
            content:
              'Verify that URL redirects and forwards only allow destinations which appear on an allow list, or show a warning when redirecting to potentially untrusted content.',
            id: '8e420826-6729-4b44-b356-2779884d5514',
            countermeasures: [],
            requirementIdNumber: '5.1.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.5 Deserialization Prevention',
            cwes: [],
            content:
              'Verify that when parsing JSON in browsers or JavaScript-based backends, JSON.parse is used to parse the JSON document. Do not use eval() to parse JSON.',
            id: '96bbf6c6-70a6-4fb2-b633-6bac01649631',
            countermeasures: [],
            requirementIdNumber: '5.5.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.3 Output Encoding and Injection Prevention',
            cwes: [],
            content:
              'Verify that data selection or database queries (e.g. SQL, HQL, ORM, NoSQL) use parameterized queries, ORMs, entity frameworks, or are otherwise protected from database injection attacks. ([C3](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: '9df60dbb-c3b6-4711-aca3-782ff136448a',
            countermeasures: [],
            requirementIdNumber: '5.3.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.1 Input Validation',
            cwes: [],
            content:
              'Verify that all input (HTML form fields, REST requests, URL parameters, HTTP headers, cookies, batch files, RSS feeds, etc) is validated using positive validation (allow lists). ([C5](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: 'b08da004-ca43-49e8-b8b3-6997dfc57732',
            countermeasures: [],
            requirementIdNumber: '5.1.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.2 Sanitization and Sandboxing',
            cwes: [],
            content:
              'Verify that the application protects against template injection attacks by ensuring that any user input being included is sanitized or sandboxed.',
            id: 'b62b8b04-4331-4137-aa58-6c891ba32ec1',
            countermeasures: [],
            requirementIdNumber: '5.2.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.3 Output Encoding and Injection Prevention',
            cwes: [],
            content:
              'Verify that where parameterized or safer mechanisms are not present, context-specific output encoding is used to protect against injection attacks, such as the use of SQL escaping to protect against SQL injection. ([C3, C4](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: 'd4d01cca-3ea0-4cb1-8ae0-d2f21742cf41',
            countermeasures: [],
            requirementIdNumber: '5.3.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.3 Output Encoding and Injection Prevention',
            cwes: [],
            content:
              'Verify that the application protects against OS command injection and that operating system calls use parameterized OS queries or use contextual command line output encoding. ([C4](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: 'de49c76b-5aad-4e98-b5c9-b286ee9362d4',
            countermeasures: [],
            requirementIdNumber: '5.3.8',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.2 Sanitization and Sandboxing',
            cwes: [],
            content:
              'Verify that unstructured data is sanitized to enforce safety measures such as allowed characters and length.',
            id: 'eeaa3fc8-7cf8-4f50-8170-deb816b467e1',
            countermeasures: [],
            requirementIdNumber: '5.2.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.1 Input Validation',
            cwes: [],
            content:
              'Verify that structured data is strongly typed and validated against a defined schema including allowed characters, length and pattern (e.g. credit card numbers, e-mail addresses, telephone numbers, or validating that two related fields are reasonable, such as checking that suburb and zip/postcode match). ([C5](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: 'f566586c-cc94-4ba3-a746-1e5ed1ace802',
            countermeasures: [],
            requirementIdNumber: '5.1.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.3 Output Encoding and Injection Prevention',
            cwes: [],
            content:
              'Verify that the application protects against JSON injection attacks, JSON eval attacks, and JavaScript expression evaluation. ([C4](https://owasp.org/www-project-proactive-controls/#div-numbering))',
            id: 'f93ef9f6-7961-454b-9f4e-861ec7c03d50',
            countermeasures: [],
            requirementIdNumber: '5.3.6',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '5.3 Output Encoding and Injection Prevention',
            cwes: [],
            content:
              'Verify that the application protects against Local File Inclusion (LFI) or Remote File Inclusion (RFI) attacks.',
            id: 'fba5be95-9b2d-49e9-b3c2-7304cdc9696b',
            countermeasures: [],
            requirementIdNumber: '5.3.9',
            severity: 'Medium',
          },
        ],
      },
      {
        controlName: '12 Files and Resources',
        description:
          'Ensure that a verified application satisfies the following high level requirements: \n * Untrusted file data should be handled accordingly and in a secure manner. \n  * Untrusted file data obtained from untrusted sources are stored outside the web root and with limited permissions.',
        id: 'f689080d-a717-4d08-a2b3-de58074da20f',
        importanceScore: 'Medium',
        securityRequirements: [
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '12.3 File Execution',
            cwes: [],
            content:
              'Verify that user-submitted filename metadata is validated or ignored to prevent the disclosure or execution of remote files via Remote File Inclusion (RFI) or Server-side Request Forgery (SSRF) attacks.',
            id: '0ad5edca-0f33-48b3-9c3c-5b36bf1a7669',
            countermeasures: [],
            requirementIdNumber: '12.3.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '12.1 File Upload',
            cwes: [],
            content:
              'Verify that a file size quota and maximum number of files per user is enforced to ensure that a single user cannot fill up the storage with too many files, or excessively large files.',
            id: '124ecfa6-aa1c-497a-9052-6d54d25485a4',
            countermeasures: [],
            requirementIdNumber: '12.1.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '12.5 File Download',
            cwes: [],
            content:
              'Verify that the web tier is configured to serve only files with specific file extensions to prevent unintentional information and source code leakage. For example, backup files (e.g. .bak), temporary working files (e.g. .swp), compressed files (.zip, .tar.gz, etc) and other extensions commonly used by editors should be blocked unless required.',
            id: '1379700e-9c4a-4eef-bab5-0ca084bf8be3',
            countermeasures: [],
            requirementIdNumber: '12.5.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '12.2 File Integrity',
            cwes: [],
            content:
              "Verify that files obtained from untrusted sources are validated to be of expected type based on the file's content.",
            id: '232b6a28-bec2-4570-ae16-bd4ec9e2a8ce',
            countermeasures: [],
            requirementIdNumber: '12.2.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '12.3 File Execution',
            cwes: [],
            content:
              'Verify that the application protects against Reflective File Download (RFD) by validating or ignoring user-submitted filenames in a JSON, JSONP, or URL parameter, the response Content-Type header should be set to text/plain, and the Content-Disposition header should have a fixed filename.',
            id: '43d7a752-3a7f-4510-bfd6-635d6ec55bb4',
            countermeasures: [],
            requirementIdNumber: '12.3.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '12.4 File Storage',
            cwes: [],
            content:
              'Verify that files obtained from untrusted sources are stored outside the web root, with limited permissions.',
            id: '47caeaa5-d210-4423-acd1-d04f5330ca5d',
            countermeasures: [],
            requirementIdNumber: '12.4.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '12.3 File Execution',
            cwes: [],
            content:
              'Verify that user-submitted filename metadata is validated or ignored to prevent the disclosure, creation, updating or removal of local files (LFI).',
            id: '594c5ed7-9e6a-42f6-bc98-ac7a05c44873',
            countermeasures: [],
            requirementIdNumber: '12.3.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '12.3 File Execution',
            cwes: [],
            content:
              'Verify that untrusted file metadata is not used directly with system API or libraries, to protect against OS command injection.',
            id: '750b45fc-1262-467d-8f0a-e19ee932ed0b',
            countermeasures: [],
            requirementIdNumber: '12.3.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '12.1 File Upload',
            cwes: [],
            content:
              'Verify that the application checks compressed files (e.g. zip, gz, docx, odt) against maximum allowed uncompressed size and against maximum number of files before uncompressing the file.',
            id: '7adda7d1-cf82-48a2-9b02-b29fb69da8c9',
            countermeasures: [],
            requirementIdNumber: '12.1.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '12.6 SSRF Protection',
            cwes: [],
            content:
              'Verify that the web or application server is configured with an allow list of resources or systems to which the server can send requests or load data/files from.',
            id: '8cfabd88-1dc0-4574-bf33-e484e498bae7',
            countermeasures: [],
            requirementIdNumber: '12.6.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '12.1 File Upload',
            cwes: [],
            content:
              'Verify that the application will not accept large files that could fill up storage or cause a denial of service.',
            id: '9d26b6aa-9539-4a2c-a6f9-695ab6378d3c',
            countermeasures: [],
            requirementIdNumber: '12.1.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '12.3 File Execution',
            cwes: [],
            content:
              'Verify that the application does not include and execute functionality from untrusted sources, such as unverified content distribution networks, JavaScript libraries, node npm libraries, or server-side DLLs.',
            id: 'ac3c99f9-4ad7-412b-8668-ac5d66443d86',
            countermeasures: [],
            requirementIdNumber: '12.3.6',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '12.5 File Download',
            cwes: [],
            content:
              'Verify that direct requests to uploaded files will never be executed as HTML/JavaScript content.',
            id: 'aee6adab-5f11-442b-8810-139f7869d700',
            countermeasures: [],
            requirementIdNumber: '12.5.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '12.3 File Execution',
            cwes: [],
            content:
              'Verify that user-submitted filename metadata is not used directly by system or framework filesystems and that a URL API is used to protect against path traversal.',
            id: 'e924e8e4-4e02-4414-85f7-364dcd3c1970',
            countermeasures: [],
            requirementIdNumber: '12.3.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: '12.4 File Storage',
            cwes: [],
            content:
              'Verify that files obtained from untrusted sources are scanned by antivirus scanners to prevent upload and serving of known malicious content.',
            id: 'e934ed9c-08de-4490-969f-76471b2da87f',
            countermeasures: [],
            requirementIdNumber: '12.4.2',
            severity: 'Medium',
          },
        ],
      },
    ],
    source: 'Owasp',
    type: 'IndustryStandard',
  },
  {
    activityStatus: 'Active',
    frameworkDescription:
      'The AWS Foundational Security Best Practices standard is a set of controls that detect when your AWS accounts and resources deviate from security best practices.',
    id: 'a5c19127-12b4-4fa8-8a8a-a8acc4136e60',
    isDefault: true,
    labels: [],
    name: 'AWS FSBP',
    securityControls: [
      {
        controlName: 'DynamoDB',
        description: 'These controls are related to DynamoDB resources.',
        id: '0c6848d4-62e6-410b-863f-8ad11abb308a',
        importanceScore: 'Medium',
        securityRequirements: [
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Identify',
            cwes: [],
            content:
              "A tag is a label that you assign to an AWS resource, and it consists of a key and an optional value. You can create tags to categorize resources by purpose, owner, environment, or other criteria. Tags can help you identify, organize, search for, and filter resources. Tagging also helps you track accountable resource owners for actions and notifications. When you use tagging, you can implement attribute-based access control (ABAC) as an authorization strategy, which defines permissions based on tags. You can attach tags to IAM entities (users or roles) and to AWS resources. You can create a single ABAC policy or a separate set of policies for your IAM principals. You can design these ABAC policies to allow operations when the principal's tag matches the resource tag. For more information, see What is ABAC for AWS? in the IAM User Guide.",
            id: '0cc00062-92ed-4569-aca8-34ea8482b23f',
            countermeasures: [],
            requirementIdNumber: 'DynamoDB.5',
            severity: 'Low',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              'Encrypting data at rest reduces the risk of data stored on disk being accessed by a user not authenticated to AWS. The encryption adds another set of access controls to limit the ability of unauthorized users to access to the data. For example, API permissions are required to decrypt the data before it can be read.',
            id: '40cb46fd-207e-4b10-b61d-aa5479b66d0f',
            countermeasures: [],
            requirementIdNumber: 'DynamoDB.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Recover',
            cwes: [],
            content:
              'Backups help you to recover more quickly from a security incident. They also strengthen the resilience of your systems. DynamoDB point-in-time recovery automates backups for DynamoDB tables. It reduces the time to recover from accidental delete or write operations. DynamoDB tables that have PITR enabled can be restored to any point in time in the last 35 days.',
            id: '5cc68134-500c-49e8-bf8f-5ec4ed0c06cb',
            countermeasures: [],
            requirementIdNumber: 'DynamoDB.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              'Scaling capacity with demand avoids throttling exceptions, which helps to maintain availability of your applications. DynamoDB tables in on-demand capacity mode are only limited by the DynamoDB throughput default table quotas. To raise these quotas, you can file a support ticket with AWS Support.DynamoDB tables in provisioned mode with auto scaling adjust the provisioned throughput capacity dynamically in response to traffic patterns. For more information about DynamoDB request throttling, see Request throttling and burst capacity in the Amazon DynamoDB Developer Guide.',
            id: '79d00108-19bb-4ad0-a905-37182dcc772e',
            countermeasures: [],
            requirementIdNumber: 'DynamoDB.7',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              "You can protect a DynamoDB table from accidental deletion with the deletion protection property. Enabling this property for tables helps ensure that tables don't get accidentally deleted during regular table management operations by your administrators. This helps prevent disruption to your normal business operations.",
            id: 'bb57cace-002b-4808-977d-9e03b1377468',
            countermeasures: [],
            requirementIdNumber: 'DynamoDB.6',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Recover',
            cwes: [],
            content:
              'AWS Backup is a fully managed backup service that helps you centralize and automate the backing up of data across AWS services. With AWS Backup, you can create backup plans that define your backup requirements, such as how frequently to back up your data and how long to retain those backups. Including DynamoDB tables in your backup plans helps you protect your data from unintended loss or deletion',
            id: 'c4ea4f83-a0ed-4df9-ac32-c8a0915af326',
            countermeasures: [],
            requirementIdNumber: 'DynamoDB.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Recover',
            cwes: [],
            content:
              'Scaling capacity with demand avoids throttling exceptions, which helps to maintain availability of your applications. DynamoDB tables in on-demand capacity mode are only limited by the DynamoDB throughput default table quotas. To raise these quotas, you can file a support ticket with AWS Support.DynamoDB tables in provisioned mode with auto scaling adjust the provisioned throughput capacity dynamically in response to traffic patterns. For more information about DynamoDB request throttling, see Request throttling and burst capacity in the Amazon DynamoDB Developer Guide.',
            id: 'eed0a538-95b8-4b2f-9e1d-1ea79832ab73',
            countermeasures: [],
            requirementIdNumber: 'DynamoDB.1',
            severity: 'Medium',
          },
        ],
      },
      {
        controlName: 'S3',
        description: 'These controls are related to S3 resources.',
        id: '364859e3-42d3-465b-b52f-8b052cdc9135',
        importanceScore: 'Medium',
        securityRequirements: [
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              'Server-side encryption (SSE) is the encryption of data at its destination by the application or service that receives it. Unless you specify otherwise, S3 buckets use Amazon S3 managed keys (SSE-S3) by default for server-side encryption. However, for added control, you can choose to configure buckets to use server-side encryption with AWS KMS keys (SSE-KMS or DSSE-KMS) instead. Amazon S3 encrypts your data at the object level as it writes it to disks in AWS data centers and decrypts it for you when you access it.',
            id: '01fcb916-6872-4d21-94d1-b61e03d0f3bc',
            countermeasures: [],
            requirementIdNumber: 'S3.17',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              'You can use S3 Object Lock to store objects using a write-once-read-many (WORM) model. Object Lock can help prevent objects in S3 buckets from being deleted or overwritten for a fixed amount of time or indefinitely. You can use S3 Object Lock to meet regulatory requirements that require WORM storage, or add an extra layer of protection against object changes and deletion.',
            id: '065e664d-046a-4a63-ad4f-eb4d0205a41a',
            countermeasures: [],
            requirementIdNumber: 'S3.15',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Identify',
            cwes: [],
            content:
              "This control checks whether server access logging is enabled for an Amazon S3 general purpose bucket. The control fails if server access logging isn't enabled. When logging is enabled, Amazon S3 delivers access logs for a source bucket to a chosen target bucket. The target bucket must be in the same AWS Region as the source bucket and must not have a default retention period configured. The target logging bucket does not need to have server access logging enabled, and you should suppress findings for this bucket.",
            id: '0e4b4112-1fa3-4688-92e4-b08ff435bf4d',
            countermeasures: [],
            requirementIdNumber: 'S3.9',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Identify',
            cwes: [],
            content:
              "S3 object-level operations, such as GetObject, DeleteObject, and PutObject, are called data events. By default, CloudTrail doesn't log data events, but you can configure trails to log data events for S3 buckets. When you enable object-level logging for read data events, you can log each individual object (file) access within an S3 bucket. Enabling object-level logging can help you meet data compliance requirements, perform comprehensive security analysis, monitor specific patterns of user behavior in your AWS account, and take action on object-level API activity within your S3 buckets by using Amazon CloudWatch Events. This control produces a PASSED finding if you configure a multi-Region trail that logs read-only or all types of data events for all S3 buckets.",
            id: '11a844d1-34ff-432e-842b-bb0d948e0627',
            countermeasures: [],
            requirementIdNumber: 'S3.23',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              'Some use cases may require that everyone on the internet be able to read from your S3 bucket. However, those situations are rare. To ensure the integrity and security of your data, your S3 bucket should not be publicly readable.',
            id: '2d7c3a24-f59d-4a5a-8c83-b06ad4e802d7',
            countermeasures: [],
            requirementIdNumber: 'S3.2',
            severity: 'High',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              'Amazon S3 public access block is designed to provide controls across an entire AWS account or at the individual S3 bucket level to ensure that objects never have public access. Public access is granted to buckets and objects through access control lists (ACLs), bucket policies, or both. Unless you intend to have your S3 buckets be publicly accessible, you should configure the account level Amazon S3 Block Public Access feature.',
            id: '349a3ece-106c-4f80-b696-d400b3550a50',
            countermeasures: [],
            requirementIdNumber: 'S3.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Identify',
            cwes: [],
            content:
              'When you enable S3 Event Notifications, you receive alerts when specific events occur that impact your S3 buckets. For example, you can be notified of object creation, object removal, and object restoration. These notifications can alert relevant teams to accidental or intentional modifications that may lead to unauthorized data access.',
            id: '3685a26c-6bee-4a1f-9fc1-fb919742eb06',
            countermeasures: [],
            requirementIdNumber: 'S3.11',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              "The Amazon S3 Block Public Access feature helps you manage access to your S3 resources at three levels: the account, bucket, and access point levels. The settings at each level can be configured independently, allowing you to have different levels of public access restrictions for your data. The access point settings can't individually override the more restrictive settings at higher levels (account level or bucket assigned to the access point). Instead, the settings at the access point level are additive, meaning they complement and work alongside the settings at the other levels. Unless you intend an S3 access point to be publicly accessible, you should enable block public access settings.",
            id: '37c83cf0-ee93-44c3-a698-5c87f3cddacb',
            countermeasures: [],
            requirementIdNumber: 'S3.19',
            severity: 'High',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              'Replication is the automatic, asynchronous copying of objects across buckets in the same or different AWS Regions. Replication copies newly created objects and object updates from a source bucket to a destination bucket or buckets. AWS best practices recommend replication for source and destination buckets that are owned by the same AWS account. In addition to availability, you should consider other systems hardening settings.',
            id: '79bfeaf6-77f9-42aa-9903-7baf4f771604',
            countermeasures: [],
            requirementIdNumber: 'S3.7',
            severity: 'Low',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Identify',
            cwes: [],
            content:
              "We recommended creating a Lifecycle configuration for your S3 bucket to help you define actions that you want Amazon S3 to take during an object's lifetime.",
            id: '7a937f6b-070c-4558-b880-3c83d0212ff7',
            countermeasures: [],
            requirementIdNumber: 'S3.10',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              'Versioning keeps multiple variants of an object in the same S3 bucket. You can use versioning to preserve, retrieve, and restore earlier versions of an object stored in your S3 bucket. Versioning helps you recover from both unintended user actions and application failures.',
            id: 'a341cebb-c0f4-4e62-ae9e-d0ad20adb3cc',
            countermeasures: [],
            requirementIdNumber: 'S3.14',
            severity: 'Low',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Identify',
            cwes: [],
            content:
              "S3 object-level operations, such as GetObject, DeleteObject, and PutObject, are called data events. By default, CloudTrail doesn't log data events, but you can configure trails to log data events for S3 buckets. When you enable object-level logging for write data events, you can log each individual object (file) access within an S3 bucket. Enabling object-level logging can help you meet data compliance requirements, perform comprehensive security analysis, monitor specific patterns of user behavior in your AWS account, and take action on object-level API activity within your S3 buckets by using Amazon CloudWatch Events. This control produces a PASSED finding if you configure a multi-Region trail that logs write-only or all types of data events for all S3 buckets.",
            id: 'a78f2032-bdc7-44ac-b052-df1ab974cae2',
            countermeasures: [],
            requirementIdNumber: 'S3.22',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              "Creating a Lifecycle configuration for your S3 bucket defines actions that you want Amazon S3 to take during an object's lifetime. For example, you can transition objects to another storage class, archive them, or delete them after a specified period of time.",
            id: 'aa8c40eb-189d-48ab-8dfd-31664a7430ac',
            countermeasures: [],
            requirementIdNumber: 'S3.13',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              'ACLs are legacy access control mechanisms that predate IAM. Instead of ACLs, we recommend using S3 bucket policies or AWS Identity and Access Management (IAM) policies to manage access to your S3 buckets.',
            id: 'b682fced-2b05-4283-ae11-3bfdeaf7257c',
            countermeasures: [],
            requirementIdNumber: 'S3.12',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              'Some use cases require that everyone on the internet be able to write to your S3 bucket. However, those situations are rare. To ensure the integrity and security of your data, your S3 bucket should not be publicly writable.',
            id: 'b9d9a3c6-9118-4255-838f-52c2db8e90c9',
            countermeasures: [],
            requirementIdNumber: 'S3.3',
            severity: 'High',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              'When working with S3 Versioning in Amazon S3 buckets, you can optionally add another layer of security by configuring a bucket to enable MFA delete. When you do this, the bucket owner must include two forms of authentication in any request to delete a version or change the versioning state of the bucket. MFA delete provides added security if your security credentials are compromised. MFA delete can also help prevent accidental bucket deletions by requiring the user who initiates the delete action to prove physical possession of an MFA device with an MFA code and adding an extra layer of friction and security to the delete action.',
            id: 'bbbddcfb-ee89-4a57-b755-1ff7ac2e0434',
            countermeasures: [],
            requirementIdNumber: 'S3.20',
            severity: 'Low',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              'S3 buckets should have policies that require all requests (Action: S3:*) to only accept transmission of data over HTTPS in the S3 resource policy, indicated by the condition key aws:SecureTransport.',
            id: 'd3e3b904-17ce-4ce1-9012-7bf2d2aa8cc8',
            countermeasures: [],
            requirementIdNumber: 'S3.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              'Block Public Access at the S3 bucket level provides controls to ensure that objects never have public access. Public access is granted to buckets and objects through access control lists (ACLs), bucket policies, or both. Unless you intend to have your S3 buckets publicly accessible, you should configure the bucket level Amazon S3 Block Public Access feature.',
            id: 'e6c9bc3a-fe73-45a6-99cb-ea7f8c6efccb',
            countermeasures: [],
            requirementIdNumber: 'S3.8',
            severity: 'High',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              'Implementing least privilege access is fundamental to reducing security risk and the impact of errors or malicious intent. If an S3 bucket policy allows access from external accounts, it could result in data exfiltration by an insider threat or an attacker. The blacklistedactionpatterns parameter allows for successful evaluation of the rule for S3 buckets. The parameter grants access to external accounts for action patterns that are not included in the blacklistedactionpatterns list.',
            id: 'eea01449-2c82-4ab3-951f-437b92ae1859',
            countermeasures: [],
            requirementIdNumber: 'S3.6',
            severity: 'High',
          },
        ],
      },
      {
        controlName: 'API Gateway',
        description: 'These controls are related to API Gateway resources.',
        id: 'cb68dd0e-2b0f-4888-82bd-55f537518ab9',
        importanceScore: 'Medium',
        securityRequirements: [
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              'API Gateway REST API stages should be configured with SSL certificates to allow backend systems to authenticate that requests originate from API Gateway.',
            id: '21bbbcb3-438d-4000-97ef-61509292f846',
            countermeasures: [],
            requirementIdNumber: 'APIGateway.2',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              'AWS WAF is a web application firewall that helps protect web applications and APIs from attacks. It enables you to configure an ACL, which is a set of rules that allow, block, or count web requests based on customizable web security rules and conditions that you define. Ensure that your API Gateway stage is associated with an AWS WAF web ACL to help protect it from malicious attacks.',
            id: '36fb2bb6-8b2c-4094-8767-7e98604bb08f',
            countermeasures: [],
            requirementIdNumber: 'APIGateway.4',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              'API Gateway supports multiple mechanisms for controlling and managing access to your API. By specifying an authorization type, you can restrict access to your API to only authorized users or processes.',
            id: '497e9d50-de2e-4814-b992-f6cf3f928c98',
            countermeasures: [],
            requirementIdNumber: 'APIGateway.8',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [
              {
                content: '1231',
                id: '2d1a32cf-67d6-40c7-9140-eabd63fa9a13',
                type: 'Documentation',
              },
            ],
            categoryName: 'Detect',
            cwes: [],
            content:
              'X-Ray active tracing enables a more rapid response to performance changes in the underlying infrastructure. Changes in performance could result in a lack of availability of the API. X-Ray active tracing provides real-time metrics of user requests that flow through your API Gateway REST API operations and connected services.',
            id: '53b3478a-d147-477f-ad25-aec254901f37',
            countermeasures: [
              {
                content: '123',
                countermeasureSource: 'Manual',
                countermeasureSnippetType: 'Architecture',
                id: '401a9d9f-d656-452d-b591-0c0ea69b14ec',
                securityRequirementsIds: ['53b3478a-d147-477f-ad25-aec254901f37'],
                title: '123',
              },
            ],
            description: 'jhbjhbjhb',
            requirementIdNumber: 'APIGateway.3',
            severity: 'Low',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Identify',
            cwes: [],
            content:
              'API Gateway REST or WebSocket API stages should have relevant logs enabled. API Gateway REST and WebSocket API execution logging provides detailed records of requests made to API Gateway REST and WebSocket API stages. The stages include API integration backend responses, Lambda authorizer responses, and the requestId for AWS integration endpoints.',
            id: '8f6c98e6-fb2e-4dfa-96c7-9544f9fe7326',
            countermeasures: [],
            requirementIdNumber: 'APIGateway.1',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              'Encrypting data at rest reduces the risk of data stored on disk being accessed by a user not authenticated to AWS. It adds another set of access controls to limit unauthorized users ability access the data. For example, API permissions are required to decrypt the data before it can be read.',
            id: '9b306555-4442-4227-8218-999d82e663df',
            countermeasures: [],
            requirementIdNumber: 'APIGateway.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Identify',
            cwes: [],
            content:
              'API Gateway access logs provide detailed information about who has accessed your API and how the caller accessed the API. These logs are useful for applications such as security and access audits and forensics investigation. Enable these access logs to analyze traffic patterns and to troubleshoot issues.',
            id: 'b88dd4bc-8e36-4e51-b823-43fcad9d8bd6',
            countermeasures: [],
            requirementIdNumber: 'APIGateway.9',
            severity: 'Medium',
          },
        ],
      },
      {
        controlName: 'CloudFront',
        description: 'These controls are related to CloudFront resources.',
        id: 'cc4b48ec-df08-4b4c-8f8e-82fd3015279d',
        importanceScore: 'Medium',
        securityRequirements: [
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Identify',
            cwes: [],
            content:
              'When a CloudFront distribution in your account is configured to point to a non-existent bucket, a malicious third party can create the referenced bucket and serve their own content through your distribution. We recommend checking all origins regardless of routing behavior to ensure that your distributions are pointing to appropriate origins.',
            id: '0120d79c-07cf-4aeb-8334-75d533bd7a95',
            countermeasures: [],
            requirementIdNumber: 'CloudFront.12',
            severity: 'High',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              "A user might sometimes request the distribution's root URL instead of an object in the distribution. When this happens, specifying a default root object can help you to avoid exposing the contents of your web distribution.",
            id: '1c82409e-a979-483f-81b9-b1e6a15a21dd',
            countermeasures: [],
            requirementIdNumber: 'CloudFront.1',
            severity: 'High',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              'In 2015, the Internet Engineering Task Force (IETF) officially announced that SSL 3.0 should be deprecated due to the protocol being insufficiently secure. It is recommended that you use TLSv1.2 or later for HTTPS communication to your custom origins.',
            id: '24320230-f4fa-4501-8db8-b50f3e97d6b8',
            countermeasures: [],
            requirementIdNumber: 'CloudFront.10',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              'HTTPS (TLS) can be used to help prevent eavesdropping or manipulation of network traffic. Only encrypted connections over HTTPS (TLS) should be allowed.',
            id: '2f6ffc96-664e-4c32-974f-d751d4e1aa2e',
            countermeasures: [],
            requirementIdNumber: 'CloudFront.9',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              'Custom SSL/TLS allow your users to access content by using alternate domain names. You can store custom certificates in AWS Certificate Manager (recommended), or in IAM.',
            id: '4842e5d4-3603-4d66-ac79-c357d76d9d77',
            countermeasures: [],
            requirementIdNumber: 'CloudFront.7',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Recover',
            cwes: [],
            content:
              'CloudFront origin failover can increase availability. Origin failover automatically redirects traffic to a secondary origin if the primary origin is unavailable or if it returns specific HTTP response status codes.',
            id: '5875d5e3-6f60-49f9-95f9-9cb1275e36b5',
            countermeasures: [],
            requirementIdNumber: 'CloudFront.4',
            severity: 'Low',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              'AWS WAF is a web application firewall that helps protect web applications and APIs from attacks. It allows you to configure a set of rules, called a web access control list (web ACL), that allow, block, or count web requests based on customizable web security rules and conditions that you define. Ensure your CloudFront distribution is associated with an AWS WAF web ACL to help protect it from malicious attacks.',
            id: '5993e73a-74d0-4db6-bcc1-17768e998b63',
            countermeasures: [],
            requirementIdNumber: 'CloudFront.6',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              "When using an S3 bucket as an origin for your CloudFront distribution, you can enable OAC. This permits access to the content in the bucket only through the specified CloudFront distribution, and prohibits access directly from the bucket or another distribution. Although CloudFront supports Origin Access Identity (OAI), OAC offers additional functionality, and distributions using OAI can migrate to OAC. While OAI provides a secure way to access S3 origins, it has limitations, such as lack of support for granular policy configurations and for HTTP/HTTPS requests that use the POST method in AWS Regions that require AWS Signature Version 4 (SigV4). OAI also doesn't support encryption with AWS Key Management Service. OAC is based on an AWS best practice of using IAM service principals to authenticate with S3 origins.",
            id: '6d99f421-da59-4010-a15f-9fe49f9d8488',
            countermeasures: [],
            requirementIdNumber: 'CloudFront.13',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              "Server Name Indication (SNI) is an extension to the TLS protocol that is supported by browsers and clients released after 2010. If you configure CloudFront to serve HTTPS requests using SNI, CloudFront associates your alternate domain name with an IP address for each edge location. When a viewer submits an HTTPS request for your content, DNS routes the request to the IP address for the correct edge location. The IP address to your domain name is determined during the SSL/TLS handshake negotiation; the IP address isn't dedicated to your distribution.",
            id: '7878ac0c-96b1-49dd-91c7-857dc7895740',
            countermeasures: [],
            requirementIdNumber: 'CloudFront.8',
            severity: 'Low',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Protect',
            cwes: [],
            content:
              'HTTPS (TLS) can be used to help prevent potential attackers from using person-in-the-middle or similar attacks to eavesdrop on or manipulate network traffic. Only encrypted connections over HTTPS (TLS) should be allowed. Encrypting data in transit can affect performance. You should test your application with this feature to understand the performance profile and the impact of TLS.',
            id: '9c0c7b8d-cf72-4326-bc56-06051a62a82b',
            countermeasures: [],
            requirementIdNumber: 'CloudFront.3',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Identify',
            cwes: [],
            content:
              'CloudFront access logs provide detailed information about every user request that CloudFront receives. Each log contains information such as the date and time the request was received, the IP address of the viewer that made the request, the source of the request, and the port number of the request from the viewer.',
            id: 'b9df0dc8-6636-4a06-b882-02b78ef9462f',
            countermeasures: [],
            requirementIdNumber: 'CloudFront.5',
            severity: 'Medium',
          },
          {
            activityStatus: 'Active',
            antiPatterns: [],
            categoryName: 'Identify',
            cwes: [],
            content:
              "A tag is a label that you assign to an AWS resource, and it consists of a key and an optional value. You can create tags to categorize resources by purpose, owner, environment, or other criteria. Tags can help you identify, organize, search for, and filter resources. Tagging also helps you track accountable resource owners for actions and notifications. When you use tagging, you can implement attribute-based access control (ABAC) as an authorization strategy, which defines permissions based on tags. You can attach tags to IAM entities (users or roles) and to AWS resources. You can create a single ABAC policy or a separate set of policies for your IAM principals. You can design these ABAC policies to allow operations when the principal's tag matches the resource tag",
            id: 'f29f9a3c-39b7-460f-87ca-0c1d23a91e1d',
            countermeasures: [],
            requirementIdNumber: 'CloudFront.14',
            severity: 'Low',
          },
        ],
      },
    ],
    source: 'Aws',
    type: 'IndustryStandard',
  },
];
