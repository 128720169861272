// App - Types
import type { StoryStatus } from '../story-status';

export const StoryStatusesDto = [
  'ReviewCandidate',
  'DeclinedByStakeholder',
  'PreReview',
  'ReadyForReview',
  'InProgress',
  'PendingDevelopers',
  'PendingSecurityApproval',
  'Done',
  'UNKNOWN',
] as const;

export type StoryStatusDto = (typeof StoryStatusesDto)[number];

export const castStoryStatusDtoToStoryStatus = (dto: StoryStatusDto): StoryStatus => {
  if (dto === 'ReviewCandidate') return 'candidate';

  if (dto === 'DeclinedByStakeholder') return 'declined';

  if (dto === 'PreReview') return 'pre_review';

  if (dto === 'ReadyForReview') return 'ready_for_review';

  if (dto === 'InProgress') return 'in_progress';

  if (dto === 'PendingDevelopers') return 'pending_developers';

  if (dto === 'PendingSecurityApproval') return 'pending_security_approval';

  if (dto === 'Done') return 'done';

  return 'UNKNOWN';
};

export const castStoryStatusToStoryStatusDto = (status: StoryStatus): StoryStatusDto => {
  if (status === 'candidate') return 'ReviewCandidate';

  if (status === 'declined') return 'DeclinedByStakeholder';

  if (status === 'pre_review') return 'PreReview';

  if (status === 'ready_for_review') return 'ReadyForReview';

  if (status === 'in_progress') return 'InProgress';

  if (status === 'pending_developers') return 'PendingDevelopers';

  if (status === 'pending_security_approval') return 'PendingSecurityApproval';

  if (status === 'done') return 'Done';

  return 'UNKNOWN';
};
