// 3rd
import { z } from 'zod';

// App - Types
import type { Id } from '../id';
import { castTypeToKnowledgeSource } from '../type';
import { TypesDto, castTypeDtoToType, castTypeToTypeDto } from './type';

export const ZodIdDto = z.object({
  knowledgeItemType: z.enum(TypesDto),
  collectionId: z.string(),
  documentId: z.string(),
});

export type IdDto = z.infer<typeof ZodIdDto>;

export const castIdDtoToId = (dto: IdDto): Id => {
  const type = castTypeDtoToType(dto.knowledgeItemType);
  const source = castTypeToKnowledgeSource(type);

  return {
    source,
    type,
    collectionId: dto.collectionId,
    documentId: dto.documentId,
  };
};

export const castIdToIdDto = (id: Id): IdDto => ({
  knowledgeItemType: castTypeToTypeDto(id.type),
  collectionId: id.collectionId,
  documentId: id.documentId,
});
