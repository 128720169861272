// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { Id } from '@/types/knowledge-item/id';
import { ZodIdDto, castIdToIdDto } from '@/types/knowledge-item/dtos/id';
import type { CreateAzureWorkItemFeedback } from '../types/feedback';

// App - Other
import { apiClient } from '@/config/lib/api-client';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  content: z.string(),
  knowledgeSourceConfigurationId: z.string(),
  projectName: z.string(),
  title: z.string(),
  relatedKnowledgeItem: ZodIdDto.optional(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

// #######
// Request
// #######

export const createAzureWorkItem = async (
  model: CreateAzureWorkItemFeedback,
  seedKnowledgeItemId?: Id
): Promise<void> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      content: model.content,
      knowledgeSourceConfigurationId: model.recipientIntegrationConfigurationId,
      projectName: model.projectName,
      title: model.title,
      ...(seedKnowledgeItemId !== undefined
        ? { relatedKnowledgeItem: castIdToIdDto(seedKnowledgeItemId) }
        : {}),
    });

    return await apiClient.post(`/AzureDevOps/CreateWorkItem`, payload);
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseCreateAzureWorkItem = {
  onCreateStart?: () => void;
  onCreateSuccess?: () => void;
  onCreateFailed?: (error: Error) => void;
};

export const useCreateAzureWorkItem = ({
  onCreateStart,
  onCreateSuccess,
  onCreateFailed,
}: UseCreateAzureWorkItem = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (createTicketFeedbackPayload: {
      seedKnowledgeItemId?: Id;
      ticketCreationPayload: CreateAzureWorkItemFeedback;
    }) =>
      await createAzureWorkItem(
        createTicketFeedbackPayload.ticketCreationPayload,
        createTicketFeedbackPayload.seedKnowledgeItemId
      ),
    onMutate: () => onCreateStart?.(),
    onSuccess: () => onCreateSuccess?.(),
    onError: (error) => onCreateFailed?.(error),
  });

  return {
    createTicket: mutate,
    isCreatingTicket: isPending,
    didCreateTicketErrored: isError,
  };
};
