// App - Other
import { getCollectionHandler } from './get-collection';
import { getListCollectionsHandler } from './get-list-collections';
import { getListCollectionsStatsHandler } from './get-list-collections-stats';

export * from './get-collection';
export * from './get-list-collections';
export * from './get-list-collections-stats';

export const collectionsHandlers = [
  getCollectionHandler,
  getListCollectionsHandler,
  getListCollectionsStatsHandler,
];
