// App - Types
import type { SlackIntegrationDto } from '@/features/integrations';

export const slackIntegrationsMockData: SlackIntegrationDto[] = [
  {
    id: '599382b4-29a4-478a-b9cb-fe31c0541262-Slack-T0639D14TT5',
    integrationName: 'Illuminati (Internal)',
    integrationType: 'Slack',
  },
];
