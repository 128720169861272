// 3rd
import { useQueries } from '@tanstack/react-query';

// App - Types
import type { Id } from '@/types/knowledge-item/id';
import type { Feedback } from '../types/feedback';

// App - Other
import { FEEDBACK_QUERY_KEYS } from '../config/react-query-key-factory';
import { getFeedback } from './get-feedback';

// ####
// Hook
// ####

type UseBulkFeedback = {
  knowledgeItemsIds?: Id[];
  enabled?: boolean;
};

export const useBulkFeedback = ({ knowledgeItemsIds = [], enabled = true }: UseBulkFeedback) => {
  const { data, isLoading, isFetching, isLoadingError } = useQueries({
    queries: knowledgeItemsIds?.map((id) => ({
      queryKey: FEEDBACK_QUERY_KEYS.knowledgeItemFeedback(id),
      queryFn: async () => await getFeedback(id),
      enabled,
    })),
    combine: (results) => {
      return {
        data: results.flatMap((r) => r.data),
        isLoading: results.some((r) => r.isLoading),
        isFetching: results.some((r) => r.isFetching),
        isLoadingError: results.every((r) => r.isLoadingError),
      };
    },
  });

  return {
    feedback: data.filter(Boolean) as Feedback[],
    isFetchingFeedback: isFetching,
    isLoadingFeedback: isLoading,
    didFetchingFeedbackErrored: isLoadingError,
  };
};
